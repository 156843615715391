import React, { useEffect, useState } from "react";
import Card from "components/Card";
import Page from "components/Page";
import Container from "components/Container";
import MainNavBar from "components/layout/MainNavBar";
import {
  ChatController,
  MuiChat,
} from 'chat-ui-react';
import { getThreads, replyToThread } from "network/api";
import CookieService from "auth/CookieService";

const Inbox = () => {
  const [chatCtl] = useState(
    new ChatController({
      showDateTime: true,
    }),
  );
  const [messages, setMessages] = useState([]);
  const [messagesforInbox, setMessagesforInbox] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(0);

  const user_id = CookieService.get('user_id');

  useEffect(() => {
    getThreads().then(data => {
      if(data.threads.length === 0 ){
        setCurrentThreadId(null);
        setMessages(data.threads);
        return;
      }
      setMessages(data.threads);
      setCurrentThreadId(data.threads[0].id);
      let currentMessages = [];

      data.threads[0].messages.forEach(element => {
        let firstPerson = "";
        let secondPerson = "";
        if (data.threads[0].participants[0].user_id === user_id) {
          firstPerson = data.threads[0].participants[0]?.name ? data.threads[0].participants[0].name : "";
          secondPerson = data.threads[0].participants[1]?.name ? data.threads[0].participants[1].name : "";
        }
        else {
          firstPerson = data.threads[0].participants[1]?.name ? data.threads[0].participants[1].name : "";
          secondPerson = data.threads[0].participants[0]?.name ? data.threads[0].participants[0].name : "";
        }
        currentMessages.push(
          {
            type: 'text',
            content: element.body,
            username: element.username,
            self: element.mine,
            createdAt: new Date(element.created_at),
          }
        )
      });
      setMessagesforInbox(currentMessages)
    })
  }, [user_id])

  React.useMemo(() => {
    echo(chatCtl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCtl, messagesforInbox]);

  const switchConv = (id, index) => {
    let currentMessages = [];
    setCurrentThreadId(id);
    messages[index].messages.forEach(element => {
      let firstPerson = "";
      let secondPerson = "";
      if (messages[index].participants[0].user_id === user_id) {
        firstPerson = messages[index].participants[0]?.name ? messages[index].participants[0].name : "";
        secondPerson = messages[index].participants[1]?.name ? messages[index].participants[1].name : "";
      }
      else {
        firstPerson = messages[index].participants[1]?.name ? messages[index].participants[1].name : "";
        secondPerson = messages[index].participants[0]?.name ? messages[index].participants[0].name : "";
      }
      currentMessages.push(
        {
          type: 'text',
          content: element.body,
          username: element.username,
          self: element.mine,
          createdAt: new Date(element.created_at),
        }
      )
    })
    setMessagesforInbox(currentMessages)
  }

  async function echo(chatCtl) {

    // So basically I will have to use this in the message and display a loading untill I retrieve all the messages
    // the data should be comming from the api bug 
    function SetAllMessages() {
      for (let i = 0; i < 10; i += 1) {

        chatCtl.setMessages(messagesforInbox);
        chatCtl.setActionRequest(
          { type: 'text', always: true },
          (response) => {
            replyToThread(currentThreadId, response.value).then((res) => {
            })
          }
        );
      }
    }
    await SetAllMessages();

  }


  return (
    <Page bg="bg-login-background h-auto w-auto min-h-screen">
      <MainNavBar />
      <Container containerClasses="lg:mt-6 relative z-40">
        <Card
          classNames="grid grid-cols-12 min-w-full p-5 h-full bg-semiWhite opacity-90 justify-center flex-col rounded-3xl shadow-2xl w-full"
        >
          <Card
            classNames="col-span-3 min-w-full p-5  bg-semiWhite opacity-90 flex justify-start  rounded-3xl"
          >
            <h1 className="text-3xl font-extrabold text-gray-900">Inbox</h1>
            {messages.map((value, index) =>
            (
              <div key={index}>
                <hr className="mt-1"></hr>
                <div className="mb-2">
                  <button className="text-3xl w-100 font-extrabold text-gray-900" style={{ fontSize: '16px' }} onClick={() => switchConv(value.id, index)} >
                    {
                      value.participants[0].user_id === user_id ?
                        (value.participants[1]?.name ? value.participants[1].name : "No name") :
                        (value.participants[0]?.name ? value.participants[0].name : "No name")
                    }
                  </button>
                </div>
              </div>
            )
            )}
            <hr />
          </Card>
          <Card
            style={{ height: '80vh' }}
            classNames="col-span-9 min-w-full p-0 bg-semiWhite opacity-90"
          >
            {currentThreadId != null && 
              <MuiChat chatController={chatCtl} />
            }
            {currentThreadId == null && 
              <p className="m-auto font-medium ">No messages yet!</p>
            }
          </Card>
        </Card>
      </Container>
    </Page>
  )
}



export default Inbox;