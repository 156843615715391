import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { Tooltip, Popconfirm } from 'antd';
import Swal from "sweetalert2";
// Comp.
import ReactTooltip from 'react-tooltip';
import Spinner from "components/Spinner";
import { Card } from "components/Card/index";
import { IconButton } from "components/Button/index";
// Edit Bid Details Comp
import BidDetails from "./Biding/BidDetails";
// Assets
import { MdModeEditOutline } from 'react-icons/md'
import RoofingIcon from "assets/images/icons/ic_roofing.svg";
// Utils
import { fDate } from "utils/formatTime";
// API
import { GetBids, ConfirmBid } from "network/providerAPI";
import { getMaterialsInfo } from "network/api";

const renderPreferredDates = (start_date, end_date) => {
  return (
    <div className="absolute right-0 bottom-0 bg-offWhite rounded-tl-md rounded-br-md p-1">
      <span className="text-xs text-black font-bold">
        {start_date && end_date ? "Preferred Dates: " :
          start_date ? "Preferred Start Date: " :
            end_date ? "Preferred End Date: " : ''
        }
      </span>
      {start_date && end_date ? (
        <span className="text-xs text-black">{start_date ? fDate(start_date) : "not provided"} -&gt; {end_date ? fDate(end_date) : ""}</span>
      ) : (
        <span className="text-xs text-black">{start_date ? fDate(start_date) : end_date ? fDate(end_date) : ""}</span>
      )}
    </div>
  )
}

export default function ActiveBids() {
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const [collapssed, setCollapssed] = useState(null);
  const [selectedBid, setSelectedBid] = useState('')
  const [materials, setMaterials] = useState([])

  useEffect(() => {
    const callAPI = async () => {
      setLoading(true)
      const res = await GetBids();
      const response = await getMaterialsInfo();
      if (response.status && response?.materials.length > 0)
        setMaterials(response.materials.map((item) => {
          return {
            id: item.id,
            name: item.name,
            subCategories: response.material_subcategories.filter((subItem) => subItem.material_id === item.id)
          }
        }))
      setLoading(false)
      if (res.status) {
        setList(res.bids);
      }
    }
    callAPI();
  }, [])


  const showMore = (id) => {
    setCollapssed((oldValue) => {
      if (oldValue === id) {
        return null;
      }
      return id;
    });
  }

  const handleShowDetails = (id) => {
    setSelectedBid(id)
    showMore(id)
  }

  const handleConfirmBid = async (id) => {
    const res = await ConfirmBid(id);
    console.log('res', res)
    if (res.status) {
      const newList = list.map((item) => {
        if (item.details.id === id) {
          return {
            ...item,
            details: {
              ...item.details,
              status_id: 6
            }
          }
        }
        return item
      })
      setList(newList)
      Swal.fire({
        icon: 'success',
        title: 'Bid Confirmed!',
        text: "You can now start working on the project, please contact the client for more details.",
        background: "#fffced",
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Something went wrong, please try again later.",
        background: "#fffced",
      })
    }
  }

  //TODO sort list by desc order by status id (selected, pending, rejected, ...)

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto lg:p-5 min-h-900`}
    >
      <div className="relative max-w-7xl mx-auto">
        <section className="text-gray-600 body-font">
          <div className="container px-0 lg:px-5 py-5 mx-auto">
            <div className="flex flex-col text-center w-full">
              <div className="flex flex-wrap -mx-px overflow-hidden justify-center">
                <div
                  className={twMerge(
                    "min-w-3/4 gap-10 m-auto pb-5 grid grid-cols-1 w-full ",
                    collapssed !== null ? "grid-cols-1" : list.length > 1 ? "lg:grid-cols-2" : "lg:grid-cols-1 lg:min-w-1/4 md:w-1/2"
                  )}
                >
                  {collapssed === null && list.map((project, idx) => (
                    <Card
                      key={idx}
                      classNames={twMerge("mx-auto w-full hover:cursor-pointer relative",
                        (project.details.status_id === 6 || project.details.status_id === 9) && "bg-green-200 hover:bg-green-300",
                        (project.details.status_id === 4 || project.details.status_id === 7 || project.details.status_id === 8) && "bg-red-200 hover:bg-red-300 opacity-80",
                      )}
                    >
                      <div className="flex justify-end items-center gap-2">
                        {project.details.status_id === 3 && (
                          <>
                            <Popconfirm title="Are you sure you want to proceed with the confirmation?" okText="Yes" cancelText="No" onConfirm={() => handleConfirmBid(project.details.id)}>
                              <button className="text-sm btn-secondary w-1/5 font-bold uppercase">confirm</button>
                            </Popconfirm>
                            <Tooltip title="Client Selected Your Bid" placement="top">
                              <span className="bg-green-200 rounded-md p-2">
                                Selected
                              </span>
                            </Tooltip>
                          </>
                        )}
                        {/* bid changes accepted by SS */}
                        {project.details.status_id === 9 && (
                          <Tooltip title="Bid Changes Accepted By Seeker" placement="top">
                            <span className="bg-green-400 rounded-md p-2">
                              Accepted
                            </span>
                          </Tooltip>
                        )}
                        {(project.details.status_id === 8 || project.details.status_id === 4 || project.details.status_id === 7) && (
                          <Tooltip title="Bid Rejected" placement="top">
                            <span className="bg-red-400 rounded-md p-2 text-white">
                              Rejected
                            </span>
                          </Tooltip>
                        )}
                        {project.details.status_id === 6 && (
                          <span className="bg-offWhite rounded-md p-2 font-bold text-green-900">
                            Active
                          </span>
                        )}
                        <Tooltip title="Bid Cost" placement="top">
                          <span className="flex justify-center items-center gap-1 p-2 text-sm text-white bg-green-700 rounded-md shadow-md font-semibold">
                            {`${project.details.material_total_cost + project.details.service_fees}$`}
                          </span>
                        </Tooltip>
                        {(project.details.status_id !== 4 && project.details.status_id !== 7 && project.details.status_id !== 8) &&
                          <div className="self-end">
                            <IconButton
                              Icon={<MdModeEditOutline className="h-6 w-6" />}
                              onClick={() => {
                                handleShowDetails(project.details.id)
                              }}
                              data-tip="Edit Bid"
                            />
                            <ReactTooltip />
                          </div>
                        }
                      </div>
                      <div className="absolute left-0 top-0 bg-offWhite rounded-tl-md rounded-br-md p-1">
                        <span className="text-xs text-black font-bold">Valid Until: </span>
                        <span className="text-xs text-black">{fDate(project.details.bid_valid_until)}</span>
                      </div>
                      <div className="w-full flex justify-start items-center gap-4 mb-4 p-4">
                        <div className="rounded-full shadow-lg p-2 self-center bg-semiWhite" data-tip="Roofing">
                          <img
                            className="h-16 w-16"
                            src={RoofingIcon}
                            alt="avatar"
                          />
                        </div>
                        <ReactTooltip />
                        <div className="bg-semiWhite w-3/4 flex flex-col ml-2 text-left rounded-md shadow-lg p-4 h-28">
                          <span className="text-gray-900 font-bold text-md">
                            Location
                          </span>
                          <hr className="w-full" />
                          <span className="text-gray-600 text-sm">
                            {`${project.property.city}, ${project.property.country}`}
                          </span>
                        </div>
                      </div>
                      {renderPreferredDates(project.details.start_date, project.details.completion_date)}
                    </Card>
                  ))}
                  {collapssed === selectedBid &&
                    <div className="flex flex-col min-w-full">
                      <BidDetails
                        bid={list.filter(i => i.details.id === selectedBid)[0]}
                        materials={materials}
                        // updateBids={() => updateBids(bidInfo.details.id)}
                        backToList={() => showMore(selectedBid)}
                        edit={false}
                      />
                      <div className="w-100 mt-4 content-center text-center">
                        <button
                          className="text-sm btn-link w-1/3 text-black h-5"
                          onClick={() => showMore(selectedBid)}
                        >
                          {/* Back to Bids */}
                        </button>
                      </div>
                    </div>
                  }
                  {list.length === 0 && !isLoading && (
                    <div className="flex flex-col space-y-5 justify-center items-center">
                      <h2>No Active Bids</h2>
                    </div>
                  )}
                  {isLoading && list.length === 0 && (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
}
