import { useState, useEffect } from "react";
import { useLocation, NavLink as Link } from "react-router-dom";
import { useSnackbar } from "notistack";
// forms
import { Formik, Form, Field } from "formik";
import { emailValidationSchema } from "validations/inputs";
// components
import GoogleButton from "components/GoogleButton";
import SubmitButton from "components/SubmitButton";
// hooks
import { useAuth } from "contexts/auth";
// api
import { checkEmail } from "network/api";
import axios from "axios";

const RegisterForm = ({ onEmailSuccess, onSuccess }) => {
  const location = useLocation();

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleError, setGoogleError] = useState(false);

  const user_type = location.pathname.split("/");

  useEffect(() => {
    setProfile(user_type[user_type.length - 1]);
  }, [user_type]);

  const onContinueClicked = async (values) => {
    const { email } = values;

    setLoading(true);

    const { status, error } = await checkEmail(email, "email");

    setLoading(false);

    if (status) {
      auth.dispatch({
        type: "SET_USER",
        payload: {
          user: {
            email,
            profile,
          },
        },
      });

      onEmailSuccess();
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  const onGoogleRegisterSuccess = async (response) => {
    const data = await axios.get(('https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + response.access_token)).then((response) => {
      return response.data
    })

    const { given_name, family_name, email, picture, sub } = data;

    const { status, error } = await checkEmail(email, "gmail");

    if (status) {
      auth.dispatch({
        type: "SET_USER",
        payload: {
          user: {
            email,
            profile,
            name: given_name + family_name,
            ext_id: sub,
            first_name: given_name,
            last_name: family_name,
            profile_pic: picture,
          },
        },
      });
      onSuccess();
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };


  useEffect(() => {
    if (googleError) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
      });
      setGoogleError(false);
    }
  }, [googleError, enqueueSnackbar]);

  return (
    <Formik
      validationSchema={emailValidationSchema}
      enableReinitialize
      initialValues={{
        email: "",
      }}
      onSubmit={(values) => {
        onContinueClicked(values);
      }}
    >
      {({ errors, isValid, dirty }) => (
        <Form className="space-y-4 mt-6">
          <div className="space-y-4">
            <div className="mt-1">
              <div>
                <div>
                  <div className="mt-1 grid">
                    <GoogleButton
                      onSuccess={onGoogleRegisterSuccess}
                      onFailure={() => setGoogleError(true)}
                    />
                  </div>
                </div>

                <div className="mt-3 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>

                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-yellow-50 text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div>

              <div className="space-y-2 mt-2">
                <div className="mt-1">
                  <Field
                    className={`text-input ${errors.email
                      ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                      : ""
                      }`}
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                </div>

                <p className="text-xs pt-3 leading-5 text-center">
                  By creating an account, you agree to tryDaisy
                  <Link to="/terms">&nbsp;<span className="font-bold underline">Terms</span></Link> and &nbsp;
                  <Link to="/privacy-policy"><span className="font-bold underline">Privacy Policy</span></Link>.
                </p>
              </div>
            </div>

            <div className="pt-4">
              <SubmitButton
                disabled={!(isValid && dirty)}
                loading={loading}
                buttonText="Continue"
              />
            </div>

            <div className="flex items-center justify-center py-1 xxl:py-4">
              <h5 className="">Already have an account?</h5>
              &nbsp;
              <Link to={`/login`} className="underline">Login</Link>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
