import { motion } from "framer-motion";
import { DatePicker } from "antd";
import moment from "moment";
import { CalendarIcon } from "@heroicons/react/outline";
import dates_step from '../../../assets/images/icons/dates_step_ic.svg';
import Card from '../../Card';
import IconContainer from '../../IconContainer';
 
const MIN_DURATION = 7;

const NewProjectDatesStep = ({ data, onChange, onPrev, onNext }) => {
  const disabledDatesForStartDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  const disabledDatesForEndDate = (current) => {
    if (data.startDate) {
      return (
        current &&
        current
          .startOf('day')
          .isBefore(moment(data.startDate).add(MIN_DURATION, 'days'))
      );
    }
    return disabledDatesForStartDate(current);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: 'easeInOut',
      }}
      className={`mx-auto  sm:pt-5 lg:pt-0 md:px-8 bg-no-repeat	bg-cover	bg-center   md:pb-10
                  bg-dates_step overflow-y-auto lg:overflow-hidden
                   min-h-900 relative
                  `}
    >
      <div className="flex justify-center  h-full md:h-screen absolute bottom-0 md:relative">
        <div className="max-w-7xl	md:m-auto grid grid-cols-1 items-baseline md:items-center gap-0 md:gap-10 lg:gap-28 lg:grid-cols-2 justify-center">
          <Card>
            <h3 className="text-2xl text-center md:text-left">
              What are your preferred project start and completion dates?
            </h3>
            <div className="flex justify-center md:justify-start ">
              <hr className="w-2/4 h-px bg-black-900" />
            </div>
            <div className={`max-w-full`}>
              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-4 mt-6">
                    <div>
                      <p className="block text-sm font-medium text-black-900 mb-1">
                        Start date
                      </p>
                      <DatePicker
                        value={data.startDate ? moment(data.startDate) : ''}
                        disabledDate={disabledDatesForStartDate}
                        onChange={(date, dateString) =>
                          onChange({ startDate: dateString, endDate: '' })
                        }
                        placeholder="(Optional)"
                        suffixIcon={
                          <CalendarIcon
                            className="block h-6 w-6 text-green-800"
                            aria-hidden="true"
                          />
                        }
                      />
                    </div>

                    <div>
                      <p className="block text-sm font-medium text-black-900 mb-1">
                        Completion date
                      </p>

                      <DatePicker
                        value={data.endDate ? moment(data.endDate) : ''}
                        disabledDate={disabledDatesForEndDate}
                        onChange={(date, dateString) =>
                          onChange({ endDate: dateString })
                        }
                        placeholder="(Optional)"
                        suffixIcon={
                          <CalendarIcon
                            className="block h-6 w-6 text-green-800"
                            aria-hidden="true"
                          />
                        }
                      />
                    </div>

                    <div className="flex justify-end pt-8">
                      <button
                        className="btn-outline mr-1"
                        type="button"
                        onClick={onPrev}
                      >
                        Back
                      </button>
                      <button
                        className="btn-primary ml-1"
                        type="button"
                        onClick={onNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <IconContainer>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                default: { duration: 1.5 },
              }}
              src={dates_step}
              alt="Daisy Logo"
              className="max-w-none	md:max-w-xs w-full  lg:filter lg:drop-shadow-xl"
            />
          </IconContainer>
        </div>
      </div>
    </motion.div>
  );
};

export default NewProjectDatesStep;
