import { motion } from 'framer-motion';

function MotionInView({ children, classNames, ...props }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                default: { duration: 0.6 },
                ease: "easeInOut",
            }}
            // className={classNames}
            className={`mx-auto md:px-8 md:pt-10 md:pb-10 min-h-900 min-w-full `}
            {...props}
        >
            {children}
        </motion.div>
    );
}

export default MotionInView;