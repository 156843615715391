import { NavLink } from 'react-router-dom'

function Hero() {
    return (
        <section
            id='hero'
            className="relative bg-home-small md:bg-home-large bg-cover bg-center bg-no-repeat"
        >
            <div
                className="absolute inset-0 bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/95 sm:to-white/25"
            ></div>
            <div
                className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
            >
                <div className="max-w-xl text-center sm:text-left relative">
                    <h1 className="text-3xl font-extrabold sm:text-5xl relative z-10">
                        The Ultimate Solution for Your Roofing Journey
                    </h1>
                    <div className="rounded-b-full overflow-hidden transform rotate-90 h-20 w-40 bg-yellow-800 absolute right-0 top-0 z-0"></div>
                    <p className="mt-4 max-w-lg sm:text-xl sm:leading-relaxed z-10 relative">
                        Trusted by homeowners and business owners for quality workmanship and exceptional customer service
                    </p>
                    <div className="mt-8 flex flex-wrap gap-4 text-center w-full">
                        <NavLink to="/register/seeker" className="mx-auto sm:mx-0 text-lg bg-green-900 text-white hover:text-white hover:bg-green-800 px-4 py-2 my-4 rounded-md">
                            Let's Get You Started!
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;