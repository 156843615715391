import { useNavigate } from "react-router-dom";
import Tabs from "components/Tabs";
// Provider Tabs
import ServicesForm from "components/serviceProvider/profile/ServicesForm";
import PersonalInfoForm from "components/serviceProvider/profile/PersonalInfoForm";
import ProfileAddresses from "components/serviceProvider/profile/ProfileAddresses";
import ProviderDocuments from "components/serviceProvider/profile/ProviderDocuments";
// hooks
import { useAuth } from "contexts/auth";
// styles
import "assets/styles/phone-input.css";

export default function Profile() {
  const navigate = useNavigate();
  const { state } = useAuth();

  const categories = {
    "Personal Information": [
      {
        path: "",
        component: (
          <PersonalInfoForm
            key={"info"}
          />
        ),
      },
    ],
    Addresses: [
      {
        path: "addresses",
        component: (
          <ProfileAddresses
            key={"addresses"}
          />
        ),
      },
    ],
    "Licenses and Certificates": [
      {
        path: "documents",
        component: (
          <ProviderDocuments
            key={"certificates"}
          />
        ),
      },
    ],
    Services: [
      {
        path: "services",
        component: <ServicesForm key={"services"} />,
      },
    ],
  };

  const handleOnTabChange = (selectedIndex) => {
    const { path } = Object.values(categories)[selectedIndex][0];
    navigate(path || "/");
  };

  return (
    <div className="bg-yellow-50 w-full pt-1 pb-1">
      <div className="px-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-extrabold text-gray-900">My Account</h1>
      </div>
      <div className="px-1 sm:px-6 md:px-0 pt-4">
        {state.user?.role === "service_provider" && (
          <div className="w-full px-2 sm:px-0">
            <Tabs categories={categories} onTabChange={(selectedIndex) => handleOnTabChange(selectedIndex)} rootPath="" />
          </div>
        )}
      </div>
    </div>
  );
}
