import { useState } from "react";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
// form utils
import SubmitButton from "./SubmitButton";
import { Formik, Form } from "formik";
import { phoneValidationSchema } from "validations/inputs";
// api
import { userChangePhoneNumber } from "network/api";

export default function ChangePhoneNumberForm({
  onClose,
  onSuccess,
  openAlert,
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        phoneNumber: "",
      }}
      validationSchema={phoneValidationSchema}
      onSubmit={async (values) => {
        setLoading(true);
        const { status, error } = await userChangePhoneNumber(
          values.phoneNumber
        );
        setLoading(false);
        if (status) {
          openAlert({
            variant: "success",
            message: "OTP sent successfully check your messages",
          });
          onSuccess(values.phoneNumber);
        } else {
          openAlert({
            variant: "error",
            message: error,
          });
        }
      }}
    >
      {({ values, errors, setFieldValue, isValid, dirty }) => {
        return (
          <Form className="flex flex-col justify-between h-full">
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                Phone Number
              </label>
              <PhoneInput
                name="phoneNumber"
                inputProps={{
                  autoFocus: true,
                }}
                inputClass={classNames("text-input pl-12 ", {
                  "border-red-600 focus:ring-red-600 focus:border-red-600":
                    errors.phoneNumber,
                })}
                country={"us"}
                enableSearch={true}
                disableSearchIcon={true}
                value={values?.phoneNumber}
                onChange={(value) => setFieldValue("phoneNumber", "+" + value)}
              />
              {errors.phoneNumber && (
                <div className="mt-2 text-sm text-red-600 text-left">
                  {errors.phoneNumber}
                </div>
              )}
            </div>
            <div className="py-4 sm:py-5">
              <div className="flex justify-between items-center">
                <div className="flex-auto w-1/2 mr-2">
                  <SubmitButton
                    disabled={!(isValid && dirty)}
                    loading={loading}
                    buttonText="Send OTP"
                  />
                </div>
                <div className="flex-auto w-1/2 mr-2">
                  <button
                    type="reset"
                    className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 
                    font-medium rounded-md text-sm mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-full shadow-lg justify-center py-2 px-4 border"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
