/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Form, Input, Popconfirm, Table, InputNumber } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useBidData } from "contexts/bid";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    inputType,
    total,
    ...restProps
}) => {
    const {
        bidData
    } = useBidData();
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        // if (record.title !== 'Upon Completion' || dataIndex === 'percentage') {
        if (record.title === 'Upon Completion' && dataIndex !== 'description' && dataIndex !== 'percentage') return;
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
        // }
    };

    const save = async () => {
        try {
            let values = await form.validateFields();
            if (values.percentage)
                values.amount = Math.round((parseInt(values.percentage) / 100) * (bidData.material_total_cost + bidData.service_fees));
            else if (values.amount)
                values.percentage = (parseInt(values.amount) * 100) / (bidData.material_total_cost + bidData.service_fees);
            if (values.percentage) values.percentage = Math.round(parseInt(values.percentage));
            if (values.amount) values.amount = Math.round(parseInt(values.amount));
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
        }
    };

    let childNode = children;

    const inputNode = inputType === 'number'
        ? <InputNumber ref={inputRef} onPressEnter={save} onBlur={save}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
        />
        : <Input ref={inputRef} onPressEnter={save} onBlur={save} />

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {inputNode}
                {/* <Input ref={inputRef} onPressEnter={save} onBlur={save} /> */}
            </Form.Item>
        ) : (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const MilestonesTable = ({ service_id, milestones, amount, onSave }) => {
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(2);

    useEffect(() => {
        setDataSource(milestones);
        setCount(milestones.length + 1);
    }, [milestones, amount]);

    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
        onSave(newData);
    };

    const defaultColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            width: '25%',
            editable: true,
        },
        {
            title: 'Percentage',
            dataIndex: 'percentage',
            width: '5%',
            editable: true,
            render: (_, record) =>
            (<span>
                {record.percentage}%
            </span>)
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '50%',
            editable: true,
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            render: (_, record) =>
                dataSource.length >= 1 && record.title !== 'Upon Completion' ? (
                    <Popconfirm title="are you sure?" onConfirm={() => handleDelete(record.key)}>
                        <Button type='danger'>Delete</Button>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleAdd = () => {
        const newData = {
            key: count,
            order: count,
            title: 'title',
            percentage: 0,
            // total_amount: 1000,
            description: 'description'
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        onSave(newData);
    };

    // const apiCall = async () => {
    //     const milestones = dataSource.map((item) => ({ ...item, order: item.key + 1 }));
    //     const res = await CreateMilestonesTemplate({ service_id, milestones });
    //     if (res?.status) {
    //         alert("Your Template Created.")
    //         // openAlert({
    //         //   variant: "success",
    //         //   message: "OTP send successfully check your email",
    //         // });
    //         // onSuccess(values.email);
    //     } else {
    //         // openAlert({
    //         //   variant: "error",
    //         //   message: error,
    //         // });
    //     }
    // }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'percentage' ? 'number' : 'textarea',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <div className='w-full'>
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
            />
            <Button
                onClick={handleAdd}
                type="primary"
                disabled={dataSource.reduce(function (prev, cur) {
                    return prev + cur.percentage;
                }, 0) >= 100}
                style={{
                    margin: 16,
                }}
            >
                Add a row
            </Button>
        </div>
    );
};

export default MilestonesTable;