import { useGoogleLogin } from '@react-oauth/google';
import Icon from '../assets/images/icons/ic_google.svg'
const GoogleButton = ({ onSuccess, onFailure }) => {

  

  const login = useGoogleLogin({
    onSuccess: codeResponse => onSuccess(codeResponse),
    onError: codeResponse => onFailure(codeResponse),
  });

  return (
    <div>
      <button
        type="button"
        onClick={login}
        className="btn-social"
      >
        <img src={Icon} alt="google" width={32} height={18} />
        &nbsp;
        <span className="text-center ml-2">Sign in with Google</span>
      </button>
    </div>
  );
};

export default GoogleButton;
