import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../contexts/auth";
import { useProjectData } from "../contexts/project";
import MainNavBar from "../components/layout/MainNavBar";
import HomeNavBar from "../components/layout/HomeNavbar";
import {
  NewProjectLocationStep,
  NewProjectDatesStep,
  NewProjectServicesStep,
  NewProjectServiceQuestionsStep,
  NewProjectDetailsStep,
  NewProjectContactStep,
  NewProjectReviewStep,
  NewProjectBidingStep,
  CustomeRoofService
} from '../components/projects/newProject/index';
import {
  getServicesWithSurvey,
  createProperty,
  createProject,
  getBiddingDays
} from "../network/api";
import {
  buildRequestForNewProject,
  buildRequestForNewProperty,
} from "../utils/RequestBuilder";
//FIXME: remove logo in next step
import Logo from "../assets/images/logos/logo-projects.svg";
import location_step from "../assets/images/icons/location_step_ic.svg";
import dates_step from "../assets/images/icons/dates_step_ic.svg";
import services_step_roofing from "../assets/images/icons/services_step_roofing.svg";
import services_step_siding from "../assets/images/icons/services_step_siding.svg";
import services_step_window from "../assets/images/icons/services_step_window.svg";
import Swal from "sweetalert2";

//FIXME: remove me
const stepsTitles = [
  "Where is your project located?",
  "What are your preferred project start and completion dates?",
  "Which service are you looking for?",
  "",
  "Tell us a bit more about your project",
  "What is your availability to meet the consultant?",
  "Who will be the main contact person?",
  "Review",
];

export default function NewProjectPage() {
  // window.scrollTo(0, document.body.scrollHeight);
  const [serviceName, setServiceName] = useState('Roofing');
  const [background, setBackground] = useState('bg-Roofing');
  const history = useNavigate();
  const { state } = useAuth();
  const {
    propertyData,
    projectData,
    currentStep,
    setPropertyData,
    setProjectData,
    setCurrentStep,
    resetProjectValues,
    shouldReset
  } = useProjectData();
  const [services, setServices] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [selected, setSelected] = useState({});
  useEffect(() => {
    if (state.user) {
      //FIXME:
      setProjectData((data) => ({
        ...data,
        first_name: state.user.first_name,
        last_name: state.user.last_name,
        phone: state.user.phoneNumber?.replace("+", ""),
        email: state.user.email,
      }));
    }
  }, [state.user, setProjectData]);

  const changeTitle = async () => {
    const services = await getServicesWithSurvey();
    const propertyLabel =
      propertyData.label || propertyData.city || propertyData.country;
    if(services && services[0]){
      const title = `${services[0]?.name
      } Project at ${propertyLabel}, ${moment().format("DDMMMYY")}`;
      setProjectData((data) => ({
        ...data,
        title,
      }));
    }
  };
  useEffect(() => {
    setServiceName('Roofing');
    setBackground('bg-Roofing');
    (async () => {
      const bid_days = await getBiddingDays();
      const services = await getServicesWithSurvey();
      setServices(services);
      if(services && services[0]){
        setSelected(services[0]);
      }
      const propertyLabel =
        propertyData.label || propertyData.city || propertyData.country;
      let title = ""
      let service = {};
      if(services && services[0]){
        title = `${services[0]?.name
        } Project at ${propertyLabel}, ${moment().format('DDMMMYY')}`;
        service = {
          ...services[0],
        };
      }
      
      setProjectData((data) => ({
        ...data,
        service,
        title,
        bid_days: bid_days,
        answers: {},
      }));
      setCurrentQuestion(0);
    })();
  }, [propertyData, setProjectData,shouldReset]);

  useEffect(() => {
    //FIXME: remove stepsTitles from this component
    if (!state?.token && currentStep === stepsTitles.length - 2) {
      history("/register/seeker");
    }
  }, [state?.token, currentStep, history]);

  const icons = {
    Roofing: services_step_roofing,
    Siding: services_step_siding,
    Window: services_step_window,
  };

  const stepsIcons = [
    location_step,
    dates_step,
    icons[serviceName],
    icons[serviceName],
    icons[serviceName],
    Logo,
    Logo,
    Logo,
  ];

  const updatePropertyData = (newData) => {
    setPropertyData((data) => ({ ...data, ...newData }));
  }


  const updateProjectData = (newData) => {
    setProjectData((data) => ({ ...data, ...newData }));
  }


  const onServiceChange = (newData) => {
    return;
    // const propertyLabel =
    //   propertyData.label || propertyData.city || propertyData.country;
    // const title = `${newData.service.name
    //   } Project at ${propertyLabel}, ${moment().format('DDMMMYY')}`;
    // setProjectData((data) => ({
    //   ...data,
    //   ...newData,
    //   title,
    //   answers: {},
    // }));
    // setServiceName(newData.service.name);
    // stepsIcons[2] = icons['Roofing'];
    // //setBackground(`bg-${newData.service.name}`);
    // setSelected(newData.service);
  };

  const onNext = () => setCurrentStep((prev) => prev + 1);

  const onPrev = () => setCurrentStep((prev) => prev - 1);

  const onServiceNext = () => {
    setCurrentQuestion(0);
    setCurrentStep((prev) => prev + 1);
  };

  const onPrevServiceQuestion = () => {
    if (currentQuestion - 1 >= 0) {
      setCurrentQuestion((current) => current - 1);
    } else {
      setCurrentQuestion(undefined);
      setCurrentStep((prev) => prev - 1);
    }
  };

  const onNextServiceQuestion = () => {
    if (currentQuestion === projectData.service.questions.length - 1) {
      setCurrentQuestion(projectData.service.questions.length -1);
      setCurrentStep((prev) => prev + 1);
    } else {
      setCurrentQuestion((current) => current + 1);
    }
  };

  const onDetailsPrev = () => {
    setCurrentStep((prev) => prev - 1);
    setCurrentQuestion(projectData.service.questions.length - 1);
  };

  const onCreateProject = async () => {
    Swal.showLoading();
    const propertyResponse = await createProperty(
      buildRequestForNewProperty(propertyData)
    );
    if (propertyResponse) {
      const projectResponse = await createProject(
        buildRequestForNewProject(propertyResponse.property.id, projectData)
      );
      if (projectResponse) {
        Swal.close();
        history("/dashboard");
        resetProjectValues();
      }
    }
    Swal.close();
  };

  const steps = [
    <NewProjectLocationStep
      key="location_step"
      data={propertyData}
      onChange={updatePropertyData}
      onNext={() => {
        onNext();
        changeTitle();
      }}
    />,
    <NewProjectDatesStep
      key="dates_step"
      data={projectData}
      onChange={updateProjectData}
      onNext={onNext}
      onPrev={onPrev}
    />,
    <NewProjectBidingStep
      key="dates_step"
      data={projectData}
      onChange={updateProjectData}
      onNext={onNext}
      onPrev={onPrev}
    />,
    <NewProjectServicesStep
      key="services_step"
      bg={background}
      icon={stepsIcons[2]}
      data={projectData}
      services={services}
      selected={selected}
      onChange={onServiceChange}
      onNext={onServiceNext}
      onPrev={onPrev}
    />,
    <NewProjectServiceQuestionsStep
      key="service_questions_step"
      bg={background}
      icon={stepsIcons[2]}
      currentQuestion={currentQuestion + 1}
      data={projectData}
      service={projectData.service}
      onChange={updateProjectData}
      onNext={onNextServiceQuestion}
      onPrev={onPrevServiceQuestion}
    />,
    <CustomeRoofService
      key="custome_roof_service"
      data={projectData}
      onChange={updateProjectData}
      buildingData={propertyData}
      onNext={onNext}
      onPrev={onPrev}
    />,
    <NewProjectDetailsStep
      key="details_step"
      data={projectData}
      bg={background}
      icon={stepsIcons[2]}
      onChange={updateProjectData}
      onNext={onNext}
      onPrev={onDetailsPrev}
    />,

    <NewProjectContactStep
      key="contact_step"
      data={projectData}
      onChange={updateProjectData}
      onNext={onNext}
      onPrev={onPrev}
    />,
    <NewProjectReviewStep
      key="review_step"
      data={projectData}
      propertyData={propertyData}
      onNext={onCreateProject}
      onPrev={onPrev}
    />,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.1 },
        ease: 'easeInOut',
      }}
    >
      {state.user ? <MainNavBar isNewProject /> : <HomeNavBar />}
      {steps[currentStep]}
    </motion.div>
  );
}
