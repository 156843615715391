import Hero from './Hero';
import About from './About';
import Features from './Features';
import Provider from './Provider';
import Page from 'components/Page';
import Footer from 'components/layout/Footer';
import HomeNavbar from 'components/layout/HomeNavbar';

export default function Home() {
    return (
        <Page classNames="h-auto w-auto min-h-screen">
            <HomeNavbar />
            <Hero />
            <About />
            <Features />
            <Provider />
            <Footer />
        </Page>
    )
}