import { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
// components
import { DatePicker } from "antd";
import DocumentsTable from "./DocumentsTable";
import SubmitButton from "components/SubmitButton";
import RadioInput from "components/forms/RadioInput";
import ComboBoxInput from "components/forms/ComboBoxInput";
// hooks
import { useAuth } from 'contexts/auth'
// api
import {
  getServiceProviderDocsTypes,
  getCountries,
  getStatesOfCountry,
} from "network/api";
import axios from "axios";
import CookieService from "auth/CookieService";
// popup
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// forms
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
// assets
import { CalendarIcon, XIcon } from "@heroicons/react/outline";

const MySwal = withReactContent(Swal);

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(64, "title must be more than 64 characters")
    .min(10, "title must be at least 10 characters")
    .required("title is required"),
  uploadedFile: Yup.string().when("docNumber", {
    is: (docNumber) => !docNumber,
    then: Yup.string().required("Upload Document or Enter Document Number above"),
  }),

  docNumber: Yup.string().when("uploadedFile", {
    is: (uploadedFile) => !uploadedFile,
    then: Yup.string().required("Enter Document Number or Upload Document below"),
  }),
}, ["docNumber", "uploadedFile"]);

const DocumentForm = ({ types, countries, countryStates, addDocument, addFile }) => {
  const [states, setCountryStates] = useState(countryStates);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const onFileUpload = (file) => {
    setFiles(file);
    // addFile(file[0]);
  };

  return (
    <Formik
      initialValues={{
        title: "",
        type: 1,
        typeName: { title: "Individual License" },
        country: 1,
        country_name: "",
        state_name: "",
        state: states[0],
        docNumber: "",
        issue_date: new Date(),
        uploadedFile: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);
        const details = {
          title: values.title,
          type_id: values.type,
          number: values.docNumber,
          type: values.typeName.title,
          issue_date: values.issue_date,
          country_id: values.country,
          country_name: values.country_name || "USA",
          state_name: values.state_name,
          issue_state_id: values.state.id,
          key: values.title + "_" + values.state.id,
        };

        let formData = new FormData();
        formData.append("details", JSON.stringify([details]));
        formData.append("documents[]", files[0]);
        // uploadDocuments(files, [details]);
        axios
          .post("user/provider/upload-documents", formData, {
            headers: {
              Authorization: "Bearer " + CookieService.get("token"),
              "Content-Type": "multipart/form-data",
            },
          })
          .then((data) => {
            addDocument(details);
          })
          .catch((err) => {
            console.log(err)
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          })
          .finally(() => {
            setLoading(false);
          });
        MySwal.close();
      }}
    >
      {({ values, errors, setFieldValue, isValid, dirty }) => {
        return (
          <Form>
            <div className="py-4 pt-2">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-center"
                htmlFor="label"
              >
                Licenses and Certificates
              </label>
            </div>
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                Title
              </label>
              <Field
                type="text"
                className={classNames("text-input", {
                  "border-red-600 focus:ring-red-600 focus:border-red-600":
                    errors.title,
                })}
                name="title"
                onKeyPress={(event) =>
                  event.key === "Enter" && MySwal.clickConfirm()
                }
                placeholder="Enter title of the License | Certificate"
              />
              <div className="flex justify-between items-center">
                <div className="flex-auto w-2/5 mr-2">
                  {errors.title && (
                    <div className="mt-2 text-sm text-red-600 text-left">
                      {errors.title}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                Document Type
              </label>
              <RadioInput
                fordocs={true}
                value={values.type}
                options={types}
                onChange={(value) => {
                  setFieldValue("type", value);
                  const name = types.filter((type) => type.id === value)[0];
                  setFieldValue("typeName", name);
                }}
              />
            </div>
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                Specify Country and State
              </label>
              <RadioInput
                value={values.country}
                options={countries}
                onChange={(value) => {
                  setFieldValue("country", value);
                  getStatesOfCountry(1).then((data) => {
                    if (data.status) {
                      setCountryStates(data.states);
                      setFieldValue("state", data.states[0]);
                      setFieldValue("state_name", data.states[0].name);
                    }
                  });
                }}
              />
              {values.country !== 0 && values.state.id && (
                <ComboBoxInput
                  value={values.state}
                  options={states}
                  onChange={(value) => {
                    setFieldValue("state", value);
                    setFieldValue("state_name", value.name);
                  }}
                />
              )}
            </div>
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                Issue Date
              </label>
              <DatePicker
                value={values.issue_date ? moment(values.issue_date) : ""}
                onChange={(date, dateString) => {
                  setFieldValue("issue_date", dateString);
                }}
                placeholder="(Optional)"
                className="w-full"
                clearIcon={
                  <XIcon
                    className="block h-6 w-6 text-green-800"
                    aria-hidden="true"
                  />
                }
                suffixIcon={
                  <CalendarIcon
                    className="block h-6 w-6 text-green-800 mr-8"
                    aria-hidden="true"
                  />
                }
                dropdownClassName="test"
                // disable future dates
                disabledDate={(current) => {
                  return current && current > moment().endOf("day");
                }}
              />
            </div>
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                Upload Documents
              </label>
              <Field
                type="text"
                className={classNames("text-input", {
                  "border-red-600 focus:ring-red-600 focus:border-red-600":
                    errors.docNumber,
                })}
                name="docNumber"
                onKeyPress={(event) =>
                  event.key === "Enter" && MySwal.clickConfirm()
                }
                placeholder="Enter Document Number if any"
              />
              <div className="flex justify-between items-center">
                <div className="flex-auto w-2/5 mr-2">
                  {errors.docNumber && (
                    <div className="mt-2 text-sm text-red-600 text-left">
                      {errors.docNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center gap-x-4 w-full mt-4">
                <div className="border-t border-gray-200 w-full"></div>
                <span className="text-gray-400 whitespace-nowrap">or</span>
                <div className="border-t border-gray-200 w-full"></div>
              </div>
              <input
                accept=".pdf,image/*"
                className="border w-full cursor-pointer text-gray-800 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900 mt-6"
                type="file"
                onChange={(e) => {
                  onFileUpload(e.target.files)
                  // set file name to the input field
                  setFieldValue("uploadedFile", e.currentTarget.files[0].name);
                }}
              />
            </div>
            <div className="py-4">
              <SubmitButton
                disabled={!(isValid && dirty)}
                loading={loading}
                buttonText="Save"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const ProviderDocuments = () => {
  const [docTypes, setDocTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryStates, setCountryStates] = useState([]);

  const { dispatch, state } = useAuth();

  useEffect(() => {
    setLoading(true);
    getServiceProviderDocsTypes().then((data) => {
      setDocTypes(data.document_types);
      getCountries().then((data) => {
        setCountries(data.countries);
        setLoading(false);
      });
    });
    setDocuments(state.user?.documents);
    getStatesOfCountry(1).then((data) => setCountryStates(data.states));
  }, [state.user]);

  const openSwalForm = () => {
    MySwal.fire({
      customClass: "swal-custom",
      showConfirmButton: false,
      html: (
        <DocumentForm
          types={docTypes}
          countries={countries}
          countryStates={countryStates}
          addDocument={(details) => {
            setDocuments([...documents, { ...details, id: state.user.lastDocId + 1 }])
            dispatch({
              type: "SET_USER",
              payload: {
                user: {
                  ...state.user,
                  documents: [...documents, { ...details, id: state.user.lastDocId + 1 }],
                },
              },
            });
          }}
        />
      ),
    });
  };

  return (
    loading ? <div className="flex items-center justify-center py-10">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-900"></div>
    </div> :
      <>
        <div className="py-4 sm:py-5 ">
          {countryStates.length > 0 && (
            <DocumentsTable
              deleteDoc={(docs) => {
                setDocuments(docs);
                dispatch({
                  type: "SET_USER",
                  payload: {
                    user: {
                      ...state.user,
                      documents: docs
                    },
                  },
                });
              }}
              documents={documents}
              countries={countryStates}
              types={docTypes}
            // types={docTypes.map((type) => type.title)}
            />
          )}
        </div>
        <div className="flex items-center justify-center">
          <button
            className="btn-primary"
            style={{ width: "50%", alignItems: "center" }}
            onClick={() => openSwalForm()}
          >
            Add Licenses
          </button>
        </div>
      </>
  );
};

export default ProviderDocuments;
