import { twMerge } from "tailwind-merge";

export default function Card({ children, classNames,style }) {
  return (
    <div
      style={style}
      className={twMerge(
        "bg-yellow-50 border shadow-xl lg:max-w-lg rounded-t-lg lg:rounded-3xl flex justify-center flex-col mt-5 lg:mt-0 p-10",
        classNames
      )}
    >
      {children}
    </div>
  );
}
