import { useState } from "react";
import classNames from "classnames";
// form utils
import * as Yup from "yup";
import SubmitButton from "../../SubmitButton";
import { Formik, Form, Field } from "formik";

const urlRegex = new RegExp('^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?$');
const instagramRegex = new RegExp('^(https?://)?(www.)?instagram.com/([a-zA-Z0-9_]+)/?$');
const facebookRegex = new RegExp('^(https?://)?(www.)?facebook.com/([a-zA-Z0-9_]+)/?$');

const validationSchema = Yup.object({
  landline_number: Yup.string().matches(/^[+][0-9]*$/, "Only Numbers allowed and it must starts with +."),
  website_url: Yup.string().matches(urlRegex, "Must be a valid url."),
  instagram_url: Yup.string().matches(instagramRegex, "Must be a valid instagram url."),
  facebook_url: Yup.string().matches(facebookRegex, "Must be a valid facebook url."),
})

export default function EditForm({
  onClose,
  onSuccess,
  Text,
  FeildType,
}) {
  const [loading] = useState(false);

  return (
    <Formik
      initialValues={{
        [FeildType]: FeildType === "landline_number" ? "+1" : "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        onSuccess(values[FeildType], FeildType);
      }}
    >
      {({ errors, isValid, dirty }) => {
        return (
          <Form>
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                {Text}
              </label>
              <Field
                type="text"
                className={classNames("text-input", {
                  "border-red-600 focus:ring-red-600 focus:border-red-600":
                    errors[FeildType],
                })}
                name={FeildType}
              />
              <div className="flex justify-between items-center">
                <div className="flex-auto w-2/5 mr-2 mt-2 text-sm text-red-600 text-left">
                  {errors[FeildType]}
                </div>
              </div>
            </div>
            <div className="py-4 sm:py-5">
              <div className="flex justify-between items-center">
                <div className="flex-auto w-1/2 mr-2">
                  <SubmitButton
                    disabled={!(isValid && dirty)}
                    loading={loading}
                    buttonText="Save"
                  />
                </div>
                <div className="flex-auto w-1/2 mr-2">
                  <button
                    type="reset"
                    className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-full shadow-lg justify-center py-2 px-4 border
                  "
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
