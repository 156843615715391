import { useEffect, useState } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import areaCalculator from '@turf/area';
import Geocoder from 'react-mapbox-gl-geocoder';
import './style.css';
import house from '../../assets/images/icons/houses.svg';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "mapbox-gl/dist/mapbox-gl.css";
import clination1 from '../../assets/images/icons/clination1.png';
import clination2 from '../../assets/images/icons/clination2.png';
import clination3 from '../../assets/images/icons/clination3.png';
import clination4 from '../../assets/images/icons/clination4.png';
import clination5 from '../../assets/images/icons/clination5.png';
import clination6 from '../../assets/images/icons/clination6.png';
import mapboxgl from "mapbox-gl";

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoidHJ5ZGFpc3kiLCJhIjoiY2xnMTN6aGZlMDNjazNpbDJtMWpseGF3byJ9.YC6HbbzOpEWhW88S4siLtw',
});

const mapAccess = {
  mapboxApiAccessToken:
    'pk.eyJ1IjoidHJ5ZGFpc3kiLCJhIjoiY2xnMTN6aGZlMDNjazNpbDJtMWpseGF3byJ9.YC6HbbzOpEWhW88S4siLtw',
};
mapboxgl.accessToken = "pk.eyJ1IjoidHJ5ZGFpc3kiLCJhIjoiY2xnMTN6aGZlMDNjazNpbDJtMWpseGF3byJ9.YC6HbbzOpEWhW88S4siLtw'";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const RoofEstimator = ({ setRoofsInfo, data, buildingData, hideSearch = false }) => {
  const [center, setCenter] = useState([buildingData.lng, buildingData.lat]);
  const [displayMap, setDisplayMap] = useState(true);
  const [roofs, setRoofs] = useState([]);

  const onSelected = (_, item) => {
    setCenter(item.center);
    setDisplayMap(true);
  };

  useEffect(() => {
    if (roofs?.length > 0) {
      setRoofsInfo(roofs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roofs]);

  useEffect(() => {
    if (!true) {
      let roofInput = document.getElementsByClassName("react-geocoder");
      roofInput[0].children[0].placeholder = "put your address here";
      setRoofsInfo(data.custom_data);
    }
    else {
      setRoofsInfo(data.custom_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // method to calculate the polygon area
  const roofAreaCalculator = (polygon) => {
    return Math.round((areaCalculator(polygon) * 100) / 0.09290304 / 100);
  };

  // method handle the creation of the polygon triggered by mapbox
  const onDrawCreate = ({ features }) => {
    const area = roofAreaCalculator(features[0].geometry);
    features[0]["properties"]["roofArea"] = Math.round(area * 1.031);
    features[0]["properties"]["initRoofArea"] = area;
    features[0]["properties"]["selected"] = 0;
    setRoofs((prevState) => {
      const newState = [...prevState, features[0]];
      return newState;
    });
  };

  // method handle the update of the polygon then getting the polygon by id and edit the roofs state in real time
  const onDrawUpdate = ({ features }) => {
    setRoofs((prevState) => {
      const area = roofAreaCalculator(features[0].geometry);
      const index = prevState.findIndex(
        (roofObject) => roofObject["id"] === features[0].id
      );
      let newRoofs = prevState;
      newRoofs[index]["properties"]["roofArea"] = Math.round(area * 1.031);
      newRoofs[index]["properties"]["initRoofArea"] = area;
      return [...newRoofs];
    });
  };

  // method handle the update of the roof angle it is getting the angle object from the clination button
  const onDrawDelete = ({ features }) => {
    setRoofs((prevState) => {
      let newRoofs = prevState;
      newRoofs = newRoofs.filter(
        (roofObject) => roofObject["id"] !== features[0].id
      );
      return [...newRoofs];
    });
  };

  // method handle the update of the roof angle it is getting the angle object from the clination button
  const updateRoofAngle = (roof, angle, selected) => {
    const index = roofs.findIndex((roofObject) => roofObject["id"] === roof.id);
    let newRoofs = roofs;
    newRoofs[index]["properties"]["roofArea"] = Math.round(
      newRoofs[index]["properties"]["initRoofArea"] * angle
    );
    newRoofs[index]["properties"]["selected"] = selected;
    setRoofs([...newRoofs]);
  };

  const onStyleLoad = (map) => {
    if (data?.custom_data?.length > 0) {
      var feature = {
        type: "FeatureCollection",
        features: data.custom_data,
      };

      map._controls[2].add(feature);
      setRoofs(map._controls[2].getAll().features);
    }
    if (data?.custom_data?.data?.length > 0) {
      let newData = JSON.parse(data.custom_data.data);
      let newfeature = {
        type: "FeatureCollection",
        features: newData
      };

      map._controls[2].add(newfeature);
      setRoofs(map._controls[2].getAll().features);
    }
  };

  return (
    <div className={`${!hideSearch ? "md:m-0 m-4 pb-10" : "md:m-0 w-100 grid grid-cols-2"}`}>
      {!true &&
        <div className="mx-auto text-center md:w-2/5 mt-10">
          <h2 className="mb-10">Estimate Your Roof Area</h2>
          <Geocoder
            trackUserLocation={true}
            {...mapAccess}
            onSelected={onSelected}
            viewport={{}}
            initialInputValue={""}
            className='mapBoxAdressInput'
          />
        </div>
      }

      {displayMap ? (
        <div className={`${!hideSearch ? "md:w-2/5 mx-auto grid justify-items-center" : ""}`}>
          <Map
            className="mt-10"
            // eslint-disable-next-line react/style-prop-object
            style="mapbox://styles/mapbox/satellite-v9"
            containerStyle={{
              width: "100%",
              height: "400px",
            }}
            interactive={!hideSearch}
            center={center}
            zoom={[18]}
            preserveDrawingBuffer={false}
            onStyleLoad={onStyleLoad}
          >
            <DrawControl
              controls={{
                polygon: !hideSearch,
                point: false,
                line_string: false,
                trash: !hideSearch,
                combine_features: false,
                uncombine_features: false,
              }}
              position="bottom-left"
              onDrawCreate={onDrawCreate}
              onDrawUpdate={onDrawUpdate}
              onDrawDelete={onDrawDelete}
            />
          </Map>
        </div>
      ) : null}
      <div className="flex justify-center mt-10">
        <div className={`grid justify-items-center  gap-4 ${!hideSearch ? "md:w-2/5 md:grid-cols-3" : "w-100 md:grid-rows-1"}`}>
          {roofs.map((roof, key) => {
            return (
              <div
                key={key}
                id={roof.id}
                className="border-solid border rounded border-inherit"
              >
                <div className="grid justify-items-center mb-5">
                  <img className="mt-5" src={house} alt="" width="30%" />
                  <h6 className="mt-5 font-bold">Roof</h6>
                  <h6 className="mt-5 mb-3 font-bold">
                    {roof.properties.roofArea} sqft
                  </h6>
                  {!hideSearch &&
                    <div className="grid grid-cols-3 justify-items-center justify-center gap-4">
                      <div
                        className={
                          `h-12 w-12 bg-green-900 rounded-full grid justify-items-center content-center ` +
                          `${roof.properties.selected === 0
                            ? "border-4 border-green-500"
                            : ""
                          }`
                        }
                        aria-hidden="true"
                        role="button"
                        onClick={() => updateRoofAngle(roof,  1.031, 0)}
                      >
                        <img width="60%" alt="clination" src={clination1} />
                      </div>
                      <div
                        className={
                          `h-12 w-12 bg-green-900 rounded-full grid justify-items-center content-center ` +
                          `${roof.properties.selected === 1
                            ? "border-4 border-green-500"
                            : ""
                          }`
                        }
                        
                        aria-hidden="true"
                        role="button"
                        onClick={() => updateRoofAngle(roof,  1.118 , 1)}
                      >
                        <img width="60%" alt="clination" src={clination2} />
                      </div>
                      <div
                        className={
                          `h-12 w-12 bg-green-900 rounded-full grid justify-items-center content-center ` +
                          `${roof.properties.selected === 2
                            ? "border-4 border-green-500"
                            : ""
                          }`
                        }
                        aria-hidden="true"
                        role="button"
                        onClick={() => updateRoofAngle(roof,1.25 , 2)}
                      >
                        <img width="40%" alt="clination" src={clination3} />
                      </div>
                      <div
                        className={
                          `h-12 w-12 bg-green-900 rounded-full grid justify-items-center content-center ` +
                          `${roof.properties.selected === 3
                            ? "border-4 border-green-500"
                            : ""
                          }`
                        }
                        aria-hidden="true"
                        role="button"
                        onClick={() => updateRoofAngle(roof,1.414  , 3)}
                      >
                        <img width="40%" alt="clination" src={clination4} />
                      </div>
                      <div
                        className={
                          `h-12 w-12 bg-green-900 rounded-full grid justify-items-center content-center ` +
                          `${roof.properties.selected === 4
                            ? "border-4 border-green-500"
                            : ""
                          }`
                        }
                        aria-hidden="true"
                        role="button"
                        onClick={() => updateRoofAngle(roof,1.601  , 4)}
                      >
                        <img width="40%" alt="clination" src={clination5} />
                      </div>
                      <div
                        className={
                          `h-12 w-12 bg-green-900 rounded-full grid justify-items-center content-center ` +
                          `${roof.properties.selected === 5
                            ? "border-4 border-green-500"
                            : ""
                          }`
                        }
                        aria-hidden="true"
                        role="button"
                        onClick={() => updateRoofAngle(roof, 1.803 , 5)}
                      >
                        <img width="40%" alt="clination" src={clination6} />
                      </div>
                    </div>
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default RoofEstimator;