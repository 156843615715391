/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import { useSnackbar } from "notistack";
// hooks
import { useAuth } from "contexts/auth";
// popup
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// comps
import ProfileImage from "components/ProfilePicture";
import ChangeEmailForm from "components/ChangeEmailForm";
import EmailVerificationForm from "components/EmailVerificationForm";
import ChangePhoneNumberForm from "components/ChangePhoneNumberForm";
import PhoneVerificationForm from "components/PhoneVerificationForm";
import EditForm from "./EditForm";
// api
import { editProviderInfo, editCommunicationMethod } from 'network/api'
// asssets
import { PencilIcon, SaveIcon } from "@heroicons/react/solid";
import { FiGlobe, FiInstagram, FiFacebook } from "react-icons/fi";

const MySwal = withReactContent(Swal);

const PersonalInfoForm = () => {
  const { state, dispatch } = useAuth();
  const { user } = state;

  const { enqueueSnackbar } = useSnackbar();

  const openSnackbar = (data) => {
    enqueueSnackbar(data.message, {
      variant: data.variant,
    });
  };

  const [sms, setSms] = useState(user.smsCommunicationMethod);
  const [smsChange, setSmsChange] = useState(false);

  const setCommunicationSms = () => {
    setSmsChange((oldSms) => !oldSms);
    setSms((sms) => !sms);
  };

  const updateComminicationMethod = () => {
    if (smsChange) {
      editCommunicationMethod(sms)
        .then(() => openSnackbar({
          message: "Communication method updated successfully", variant: "success"
        }))
        .catch((error) => openSnackbar({ message: error, variant: "error" }));
    }
    setSmsChange(false);
  };

  const handleCloseSwal = (e) => {
    MySwal.close();
  }

  const openSwal = async (feild, text) => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <EditForm
            onSuccess={(value, key) => updateProfile(value, key)}
            FeildType={feild}
            Text={text}
            onClose={handleCloseSwal}
          />
        </div>
      ),
    });
  };

  const updateProfile = (value, key) => {
    if (value)
      editProviderInfo({
        [key]: value,
      })
        .then((data) => {
          if (data.status) {
            updateUserState(key, value);
            openSnackbar({ message: data.message, variant: "success" });
          }
        });
    handleCloseSwal();
  };

  const updateEmail = async () => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <ChangeEmailForm
            onSuccess={(email) => openVerifySwal(email)}
            onClose={handleCloseSwal}
            openAlert={(data) => openSnackbar(data)}
          />
        </div>
      ),
    });
  };

  const openVerifySwal = async (email) => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <EmailVerificationForm
            Email={email}
            onSuccess={(value) => updateUserState('email', value)}
            onClose={handleCloseSwal}
            openAlert={(data) => openSnackbar(data)}
          />
        </div>
      ),
    });
  };

  const updatePhoneNumber = async () => {
    await MySwal.fire({
      customClass: "mySwal3",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        // <div className="h-full">
          <ChangePhoneNumberForm
            onSuccess={(phone) => openPhoneVerifySwal(phone)}
            onClose={handleCloseSwal}
            openAlert={(data) => openSnackbar(data)}
          />
        // </div>
      ),
    });
  };

  const openPhoneVerifySwal = async (phone) => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <PhoneVerificationForm
            Phone={phone}
            onSuccess={(value) => updateUserState('phoneNumber', value)}
            onClose={handleCloseSwal}
            openAlert={(data) => openSnackbar(data)}
          />
        </div>
      ),
    });
  };

  const updateUserState = (key, value) => {
    dispatch({
      type: "SET_USER",
      payload: {
        user: {
          ...user,
          [key]: value,
        },
      },
    });
    handleCloseSwal();
  };

  return (
    <div className="grid grid-cols-6 gap-6 justify-center items-center gap-x-16 max-h-400 overflow-y-scroll relative">
      <div className="col-span-6 sm:col-span-3">
        <div className="space-y-2 mt-1 divide-y divide-gray-200">

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 cursor-pointer pr-10 companyNamePenSection"
            onClick={() =>
              openSwal("company_name", "Change your Company Name: ")
            }
          >
            <dt className="text-sm text-gray-500 font-bold">
              Company Name
            </dt>

            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex">
                <div className="flex-grow">{user.company_name}</div>
                <button name="edit-name-button" className="companyNamePen">
                  <PencilIcon className="h-5 w-5" color="#638B8D" />
                </button>
              </div>
            </dd>
          </div>

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 cursor-pointer pr-10 
            firstNamePenSection"
            onClick={() =>
              openSwal("personal_name", "Change your Personal Name: ")
            }
          >
            <dt className="text-sm text-gray-500 font-bold">
              Personal Name
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex">
                <div className="flex-grow">{user?.personal_name}</div>
                <button name="edit-name-button" className="firstNamePen">
                  <PencilIcon className="h-5 w-5" color="#638B8D" />
                </button>
              </div>
            </dd>
          </div>

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 cursor-pointer pr-10 EmailPenSection"
            onClick={() => updateEmail()}
          >
            <dt className="text-sm text-gray-500 font-bold">
              Email Address
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex">
                <div className="flex-grow">{user.email}</div>
                <button name="edit-name-button" className="emailPen">
                  <PencilIcon className="h-5 w-5" color="#638B8D" />
                </button>
              </div>
            </dd>
          </div>

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 cursor-pointer pr-10 numPenSection"
            onClick={() => updatePhoneNumber()}
          >
            <dt className="text-sm text-gray-500 font-bold">
              Mobile
            </dt>

            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{user?.phoneNumber}</span>
              <button name="edit-name-button" className="numPen">
                <PencilIcon
                  color="#638B8D"
                  className="h-5 w-5 cursor-pointer"
                />
              </button>
            </dd>
          </div>

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 cursor-pointer pr-10 
            landlinePenSection"
            onClick={() =>
              openSwal("landline_number", "Change your Landline: ")
            }
          >
            <dt className="text-sm text-gray-500 font-bold">
              Landline
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="flex">
                <div className="flex-grow">{user?.landline_number}</div>
                <button name="edit-name-button" className="landlinePen">
                  <PencilIcon
                    color="#638B8D"
                    className="h-5 w-5 cursor-pointer"
                  />
                </button>
              </div>
            </dd>
          </div>

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5
          cursor-pointer pr-10 websitePenSection"
            onClick={() => openSwal("website_url", "Enter Your Website Url:")}
          >
            <dt className="text-sm font-medium text-gray-500 flex">
              <FiGlobe className="h-5 w-5" color="#638B8D" />
            </dt>

            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user?.website_url ? (
                <a
                  className="flex-grow"
                  href={user?.website_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {user?.website_url}
                </a>
              ) : (
                <div className="flex-grow">-</div>
              )}
              <button name="edit-name-button" className="websitePen">
                <PencilIcon
                  color="#638B8D"
                  className="h-5 w-5 cursor-pointer"
                />
              </button>
            </dd>
          </div>

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5
          cursor-pointer pr-10 instaPenSection"
            onClick={() => openSwal("instagram_url", "Enter Your Instagram Profile Link:")}
          >
            <dt className="text-sm font-medium text-gray-500 flex">
              <FiInstagram className="h-5 w-5" color="#638B8D" />
            </dt>

            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user?.instagram_url ? (
                <a className="flex-grow" href={user?.instagram_url || "#"} target="_blank" rel="noreferrer" >
                  {user?.instagram_url} </a>
              ) : (
                <div className="flex-grow">-</div>
              )}
              <button name="edit-name-button" className="instaPen">
                <PencilIcon
                  color="#638B8D"
                  className="h-5 w-5 cursor-pointer"
                />
              </button>
            </dd>
          </div>

          <div
            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5
          cursor-pointer pr-10 facePenSection"
            onClick={() => openSwal("facebook_url", "Enter Your facebook Page Link:")}
          >
            <dt className="text-sm font-medium text-gray-500 flex">
              <FiFacebook className="h-5 w-5" color="#638B8D" />
            </dt>

            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user?.facebook_url ? (
                <a className="flex-grow" href={user?.facebook_url || "#"} target="_blank" rel="noreferrer" >
                  {user?.facebook_url} </a>
              ) : (
                <div className="flex-grow">-</div>
              )}
              <button name="edit-name-button" className="facePen">
                <PencilIcon
                  color="#638B8D"
                  className="h-5 w-5 cursor-pointer"
                />
              </button>
            </dd>
          </div>

          <dt className="text-sm text-gray-500 sm:pt-6 font-bold">
            Communication Method
          </dt>

          <div className="mt-1">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Email</dt>

              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  id="email-checkbox"
                  name="email-checkbox"
                  type="checkbox"
                  className="checkbox-input"
                  checked
                  onChange={() => null}
                />
              </dd>
            </div>
          </div>

          {user.phoneNumber && (
            <div className="mt-6">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">SMS</dt>

                <dd className="mt-1 flex justify-between items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id="sms-checkbox"
                    name="sms-checkbox"
                    type="checkbox"
                    className="checkbox-input cursor-pointer"
                    checked={sms}
                    onChange={(e) => {
                      setCommunicationSms();
                    }}
                  />
                  <SaveIcon
                    disabled={true}
                    color={smsChange ? "#336666" : "#638B8D"}
                    className="h-5 w-5 cursor-pointer"
                    onClick={updateComminicationMethod}
                  />
                </dd>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-6 sm:col-span-3 ml-10 flex justify-center self-baseline sticky top-1">
        <div className="relative w-3/4">
          <ProfileImage role="service_provider" />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoForm;
