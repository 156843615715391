import { filter } from 'lodash'
import moment from "moment";
import Swal from 'sweetalert2'
import { useState, useEffect } from 'react';
import { useSnackbar } from "notistack";
// Comp.
import ReactTooltip from 'react-tooltip';
import { DatePicker, Tooltip, Popconfirm } from "antd";
import MaterialsTable from "./MaterialsTable";
import { Field } from "components/Field/index";
import { Card, CardHeader } from "components/Card/index";
// Assets
import { BsSave2Fill, BsPencil } from "react-icons/bs";
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
import roofingIcon from 'assets/images/icons/roofing.svg';
import projectIcon from 'assets/images/icons/project.svg';
// Utils
import { getNoDays } from "utils/calculate-days";
import { fDate } from "utils/formatTime";
// API
import { AdjustBid, CancelBid, ConfirmBid } from "network/providerAPI";
import { providerUpdateBidValidityDays } from 'network/api';
// hooks
import { useBidData } from "contexts/bid";

const MIN_DURATION = 0;

export default function BidDetails({ bid, materials, backToList, edit }) {
    const { bidData, setBidData } = useBidData();

    const { enqueueSnackbar } = useSnackbar();

    const [editMode, setEditMode] = useState(edit);
    const [data, setData] = useState(bid.details)
    const [enableEditExtend, setEnableEditExtend] = useState(false);
    const [bidDate, setBidDate] = useState(bid.details.bid_valid_until)

    useEffect(() => {
        setBidData({
            "bid_id": bid.details.id,
            "material_total_cost": bid.details.material_total_cost,
            "service_fees": bid.details.service_fees,
            "service_fees_description": bid.details.service_fees_description,
            "start_date": bid.details.start_date,
            "completion_date": bid.details.completion_date,
            "dates_description": bid.details.dates_description,
            "bid_valid_days": getNoDays(new Date(bid.details.bid_valid_until)),
            "material_break_down": bid.material,
            "bid_valid_until": bid.details.bid_valid_until,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const apiCall = async (dateString) => {
        await AdjustBid(bidData)
    };

    const extendBidValidDays = async (bid_id, bid_valid_days) => {
        const added_days = getNoDays(new Date(bid_valid_days)) - bidData.bid_valid_days
        const response = await providerUpdateBidValidityDays(bid_id, added_days)
        if (response.status) {
            setBidData((prev) => ({ ...prev, bid_valid_days: getNoDays(new Date(bid_valid_days)), bid_valid_until: bid_valid_days }))
            enqueueSnackbar('Bid Date Extended', { variant: 'success' })
        }
        else
            enqueueSnackbar('Extending Bid date failed!', { variant: 'error' })
        setEnableEditExtend(false)
    };

    const disabledDatesForStartDate = (current) => {
        // Can not select days before today
        return current && current < moment().endOf('day');
    };

    const disabledDatesForEndDate = (current) => {
        if (data.bid_valid_until) {
            return (
                current &&
                current
                    .startOf('day')
                    .isBefore(moment(data.bid_valid_until).add(MIN_DURATION, 'days'))
            );
        }
        return disabledDatesForStartDate(current);
    };

    const handleCancelBid = async (id) => {
        const res = await CancelBid(id)
        if (res?.status) {
            Swal.fire({
                title: 'Bid Cancelled!',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#2563EB',
            }).then(() => {
                window.location.reload();
            });
        } else {
            Swal.fire({
                title: 'Failed to reject bid',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#2563EB',
            });
        }
    }

    const handleConfirmBid = async (id) => {
        const res = await ConfirmBid(id);
        console.log('res', res)
        if (res.status) {
            window.location.reload();
            // const newList = list.map((item) => {
            //     if (item.details.id === id) {
            //         return {
            //             ...item,
            //             details: {
            //                 ...item.details,
            //                 status_id: 6
            //             }
            //         }
            //     }
            //     return item
            // })
            // setList(newList)
            Swal.fire({
                icon: 'success',
                title: 'Bid Confirmed!',
                text: "You can now start working on the project, please contact the client for more details.",
                background: "#fffced",
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Something went wrong, please try again later.",
                background: "#fffced",
            })
        }
    }

    return (
        <div className="flex flex-column justify-center lg:space-x-6 mb-4 lg:max-h-80 flex-col lg:flex-col space-y-6 lg:space-y-6 overflow-y-auto min-w-full" >
            <Card classNames={" overflow-y-auto min-w-full px-10 max-h-screen shadow-none border-t-2 border-b-2 border-2 rounded border-green-900"}>
                <div className='flex flex-col'>
                    <button className='w-auto self-start' onClick={() => backToList()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-green-900 hover:cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                        </svg>
                    </button>
                    <h2 className='text-3xl text-center'>Bid Details</h2>
                </div>
                {/* state if ss accepted the and project now in progress */}
                {bid.details.status_id === 9 && (
                    <div className='my-4 flex gap-2 items-center font-semibold bg-green-200 self-center rounded-md p-2 mx-auto' style={{ width: "fit-content" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                        <p>
                            This project is now in progress
                        </p>
                    </div>
                )}
                <CardHeader
                    icon={<img src={projectIcon} height="50" alt="bla" />}
                    label=" Materials"
                />
                {bidData.material_break_down &&
                    <MaterialsTable
                        dataSource={bidData.material_break_down.map((m) => {
                            const material_info = materials.find((material) => material.subCategories.find((sub) => sub.id === m.material_subcategory_id))
                            return {
                                ...m,
                                key: m.id,
                                name: material_info?.name,
                                subcategory: material_info?.subCategories.find((sub) => sub.id === m.material_subcategory_id)?.name
                            }
                        })}
                        rowEdit={editMode}
                        editMode={editMode}
                        onEdit={() => { }}
                        onDelete={(record) => {
                            setBidData((prev) => ({ ...prev, material_break_down: filter(bidData.material_break_down, (m) => m.id !== record.id) }));
                        }}
                        onSave={(newData) => {
                            setBidData((prev) => ({ ...prev, material_break_down: newData }));
                        }}
                    />
                }
                <div className="mt-10 text-left">
                    <Field
                        inline
                        label="Material Cost:"
                        value={`${data.material_total_cost}$`}
                    />
                </div>
                <hr className="mt-5 mb-5" />
                <CardHeader
                    icon={<img src={roofingIcon} width="50" height="50" alt="bla" />}
                    label=" Service"
                />
                {editMode ? (
                    <div className="flex flex-col gap-6">
                        <div className='flex flex-row items-center gap-2 justify-start w-full lg:w-1/2'>
                            <span className='text-left w-1/4 text-sm font-bold text-black-900'>Service Fees:</span>
                            <input
                                className='w-full border-2 border-green-900 rounded-md p-2'
                                type="number"
                                value={data.service_fees}
                                onChange={(e) => {
                                    setData({ ...data, service_fees: e.target.value })
                                    setBidData((prev) => ({ ...prev, service_fees: e.target.value }))
                                    // updateBids({ ...bid, service_total_cost: e.target.value })
                                }} />
                        </div>
                        <div className="flex flex-row items-center justify-between w-full lg:w-1/2">
                            <span className="text-left w-1/4 text-sm font-bold text-black-900">Descripton</span>
                            <textarea
                                className="w-full h-20 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                value={data.service_fees_description}
                                onChange={(e) => {
                                    setData({ ...data, service_fees_description: e.target.value })
                                    setBidData((prev) => ({ ...prev, service_fees_description: e.target.value }))
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <dl className="mb-8 text-left">
                        <Field inline label="Service Fees:" value={`${data.service_fees}$`} />
                        <Field
                            inline
                            value={data.service_fees_description}
                            label={"Description:"}
                        />
                    </dl>
                )}
                <hr className="mt-5 mb-5" />
                <CardHeader
                    icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#497578" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>}
                    label=" Project Time Frame"
                />
                {editMode ? (
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-row items-center justify-between w-full lg:w-1/2">
                            <span className="text-left w-1/4 text-sm font-bold text-black-900">Start Date</span>
                            <DatePicker
                                value={data.start_date ? moment(data.start_date) : moment()}
                                disabledDate={disabledDatesForEndDate}
                                onChange={(date, dateString) => {
                                    setData({ ...data, start_date: dateString })
                                    setBidData((prev) => ({ ...prev, start_date: dateString }))
                                }}
                                className="w-full"
                                clearIcon={
                                    <XIcon
                                        className="block h-6 w-6 text-green-800"
                                        aria-hidden="true"
                                    />
                                }
                                suffixIcon={
                                    <CalendarIcon
                                        className="block h-6 w-6 text-green-800 mr-8"
                                        aria-hidden="true"
                                    />
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-between w-full lg:w-1/2">
                            <span className="text-left w-1/4 text-sm font-bold text-black-900">Completion Date</span>
                            <DatePicker
                                value={data.completion_date ? moment(data.completion_date) : moment()}
                                disabledDate={disabledDatesForEndDate}
                                onChange={(date, dateString) => {
                                    setData((prev) => ({ ...prev, completion_date: dateString }));
                                    setBidData((prev) => ({ ...prev, completion_date: dateString }));
                                }}
                                className="w-full"
                                clearIcon={
                                    <XIcon
                                        className="block h-6 w-6 text-green-800"
                                        aria-hidden="true"
                                    />
                                }
                                suffixIcon={
                                    <CalendarIcon
                                        className="block h-6 w-6 text-green-800 mr-8"
                                        aria-hidden="true"
                                    />
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-between w-full lg:w-1/2">
                            <span className="text-left w-1/4 text-sm font-bold text-black-900">Descripton</span>
                            <textarea
                                className="w-full h-20 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                value={data.dates_description}
                                onChange={(e) => {
                                    setData({ ...data, dates_description: e.target.value });
                                    setBidData((prev) => ({ ...prev, dates_description: e.target.value }));
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <dl className="mb-8 text-left">
                        {data.start_date &&
                            <Field inline label="Start Date:" value={fDate(data.start_date)} />
                        }
                        {data.completion_date &&
                            <Field
                                inline
                                value={fDate(data.completion_date)}
                                label={"Completion Date"}
                            />
                        }
                        {data.dates_description &&
                            <Field
                                inline
                                value={data.dates_description}
                                label={"Description:"}
                            />
                        }
                        {!data.dates_description && !data.completion_date && !data.start_date && <span className="font-bold text-md text-red-500">No provided data</span>}
                    </dl>
                )
                }
                <hr className='my-5' />
                <div className='text-left'>
                    <CardHeader
                        icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#497578" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>}
                        label=" Bid Validity"
                    />
                    {enableEditExtend ? (
                        <div className='flex flex-row-reverse items-center gap-2 w-1/2'>
                            <button onClick={() => setEnableEditExtend(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <button onClick={() => extendBidValidDays(data.id, bidDate)} data-tip="Extend Bid Date">
                                <BsSave2Fill className="h-full w-8 text-green-900 hover:cursor-pointer" />
                            </button>
                            <ReactTooltip />
                            <DatePicker
                                value={moment(bidDate)}
                                // disable dates before bid date
                                disabledDate={(current) => {
                                    return (
                                        current &&
                                        current
                                            .startOf('day')
                                            .isBefore(moment(data.bid_valid_until).add(MIN_DURATION, 'days'))
                                    );

                                }}
                                onChange={(date, dateString) => {
                                    setBidDate(dateString)
                                    // apiCall(dateString)
                                }}
                                className="w-full"
                                clearIcon={null}
                                suffixIcon={
                                    <CalendarIcon
                                        className="block h-6 w-6 text-green-800"
                                        aria-hidden="true"
                                    />
                                }
                            />
                        </div>
                    ) : (
                        <div className='flex gap-4'>
                            <Field
                                inline
                                value={fDate(bidDate)}
                                label={"Date:"}
                            />
                            {bid.details.status_id !== 9 &&
                                <Tooltip title="Extend Bid Validity">
                                    <button onClick={() => setEnableEditExtend(true)}>
                                        <BsPencil className="h-full w-6 text-green-900 hover:cursor-pointer" />
                                    </button>
                                </Tooltip>
                            }
                        </div>
                    )}
                </div>
                <hr className='my-5' />
                {
                    !editMode ? (
                        bid.details.status_id !== 9 &&
                        <div className='my-8 flex justify-end items-center gap-4 w-full'>
                            {bid.details.status_id === 3 && (
                                <>
                                    <Popconfirm title="Are you sure you want to proceed with the confirmation?" okText="Yes" cancelText="No" onConfirm={() => handleConfirmBid(bid.details.id)}>
                                        <button className="text-sm btn-secondary w-1/6 font-bold">Confirm</button>
                                    </Popconfirm>
                                </>
                            )}
                            {(bid.details.status_id === 3) && (
                                <button
                                    className='btn-primary w-auto bg-green-900 flex justify-center items-center gap-2'
                                    onClick={() => {
                                        setEditMode(true)
                                    }}
                                >
                                    Adjust Bid
                                </button>
                            )}
                            <Popconfirm
                                title="Are you sure you want to proceed with the rejection?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleCancelBid(bid.details.id)}
                            >
                                <button
                                    className='btn-primary w-auto bg-red-600 hover:bg-red-900 border-none'
                                >
                                    Cancel Bid
                                </button>
                            </Popconfirm>
                        </div>
                    ) : (
                        <div className='my-8 flex justify-end items-center gap-4 w-full'>
                            <button
                                className='btn-outline w-auto flex justify-center items-center gap-2'
                                onClick={() => {
                                    setData(bid.details)
                                    setBidData({
                                        "bid_id": bid.details.id,
                                        "material_total_cost": bid.details.material_total_cost,
                                        "service_fees": bid.details.service_fees,
                                        "service_fees_description": bid.details.service_fees_description,
                                        "start_date": bid.details.start_date,
                                        "completion_date": bid.details.completion_date,
                                        "dates_description": bid.details.dates_description,
                                        "bid_valid_days": getNoDays(new Date(bid.details.bid_valid_until)),
                                        "material_break_down": bid.material
                                    })
                                    setEditMode(false)
                                }}
                            >
                                Cancel
                            </button>
                            <button className='btn-primary w-auto bg-green-900 flex justify-center items-center gap-2' onClick={() => {
                                apiCall(data.id, data)
                                setEditMode(false)
                            }}>
                                Save
                            </button>
                        </div>
                    )}
            </Card>
        </div>
    )
};
