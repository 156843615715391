import { useState } from "react";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { addInterestToProject } from "network/api";

import LoadingDots from "components/animations/LoadingDots";
import roofingIcon from "assets/images/icons/roofing.svg";
import projectIcon from "assets/images/icons/project.svg";

const Field = ({ label, value }) => (
  <div className="mt-2">
    <dt className="inline text-sm font-bold text-black-900">{label}</dt>
    <dd className="inline lg:block ml-2 text-sm text-gray-900">{value}</dd>
  </div>
);

const Card = ({ children, classNames }) => (
  <div
    className={
      "border-4 border-green-900 opacity-100 p-4 rounded-md shadow-2xl overflow-y-auto w-full lg:w-1/3 bg-semiWhite " +
      classNames
    }
  >
    {children}
  </div>
);

const CardHeader = ({ icon, label }) => (
  <div className="flex items-center justify-center lg:justify-start">
    <dt className="inline text-sm ">{icon}</dt>
    <dd className="inline ml-2 text-lg font-bold text-black-900 font-serif">
      {label}
    </dd>
  </div>
);

const ProjectDetailsComponent = ({ data, propertyData }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const getAnswer = (index) => {
    const answer = data.qna[index].answer;
    return answer;
  };

  const handleAddInterestToProject = async (project_id) => {
    setLoading(true);
    const response = await addInterestToProject(project_id);
    setLoading(false);
    if (response.status) {
      enqueueSnackbar("Project added to Biding list!", {
        variant: "success",
      });
      navigate("/dashboard");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto md:px-8 md:pb-10 h-auto min-h-screen w-full`}
    >
      <div className="flex justify-center max-w-7xl m-auto">
        <div className=" lg:m-auto justify-center w-full	">
          <div
            className="h-full bg-semiWhite opacity-90 p-10 md:p-7    
            flex justify-center flex-col md:rounded-t-lg 
            md:rounded-card shadow-2xl w-full"
          >
            <div className={`max-w-full`}>
              <h2 className="text-center pb-2">Project Details</h2>
              <div className="flex justify-center lg:space-x-6  mb-4 lg:max-h-80 flex-col lg:flex-row space-y-6	lg:space-y-0 ">
                <Card>
                  <CardHeader
                    icon={<img src={projectIcon} alt="bla" />}
                    label=" Project"
                  />
                  <dl>
                    <Field label="Title:" value={data.details.title} />
                    <Field label="Label:" value={propertyData.label} />
                    <Field
                      label="Location:"
                      value={propertyData.address_title}
                    />
                    {data.description && (
                      <Field
                        label="Description:"
                        value={data.details.description}
                      />
                    )}
                    {data.startDate && (
                      <Field
                        label="Start date:"
                        value={data.details.pref_start_date}
                      />
                    )}
                    {data.endDate && (
                      <Field
                        label="Completion date:"
                        value={data.details.pref_end_date}
                      />
                    )}
                  </dl>
                </Card>
                <Card>
                  <CardHeader
                    icon={<img src={roofingIcon} alt="bla" />}
                    label=" Service"
                  />
                  <dl className="mb-8">
                    <Field label="Name:" value={"Roofing"} />
                    {data.qna.map((question, index) => (
                      <Field
                        key={index}
                        label={question.question}
                        value={getAnswer(index)}
                      />
                    ))}
                  </dl>
                </Card>
              </div>
              <div className="w-full flex items-center justify-center mt-10">
                <button
                  type="button"
                  className="btn btn-primary w-1/2"
                  onClick={() => handleAddInterestToProject(data.details.id)}
                >
                  {loading ? (
                    <LoadingDots />
                  ) : (
                    `Add
                  Biding Project`
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectDetailsComponent;
