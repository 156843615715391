import Page from "../../components/Page";
import HomeNavbar from "../../components/layout/HomeNavbar";
import { Disclosure, Transition } from '@headlessui/react';
import Footer from "components/layout/Footer";

const CONTEXT = [
  {
    heading: "Information Collection", text: `We may collect personal information from visitors to our website through forms that you fill out
voluntarily, such as contact or feedback forms. This information may include your name, email
address, phone number, and any other information you provide to us. We may also collect nonpersonal
information, such as your browser type, IP address, and access times.`},
  {
    heading: "Information Use", text: `We may use the personal information we collect to communicate with you about your inquiries,
requests, or questions. We may also use this information to improve our website and our services, to
send you updates and newsletters, or to personalize your experience on our website.` },
  {
    heading: "Information Sharing", text: `We do not sell or distribute personal information collected through our website to any third party.
However, we may share your information with our service providers, such as web hosting providers,
email marketing providers, and payment processors, to facilitate our services. We require these
service providers to protect your personal information and use it only for the purposes for which we
disclose it to them.` },
  {
    heading: "Data Security", text: `We take reasonable precautions to protect personal information from unauthorized access, use, or
disclosure. We use industry-standard security measures, such as encryption and firewalls, to protect
your information from unauthorized access.` },
  {
    heading: "Your Rights", text: `You have the right to access, correct, or delete your personal information that we have collected
through our website. If you wish to exercise these rights, please contact us at the email address
listed below.` },
  {
    heading: "Updates to Privacy Policy", text: `We may update this privacy policy from time to time. We encourage you to review this policy
periodically to stay informed about how we collect, use, and protect personal information.` },
  { heading: "Contact Us", text: `If you have any questions about this privacy policy or our privacy practices, please contact us at <a href="mailto:info@trydaisy.com">info@trydaisy.com</a>` },
]

export const PrivacyPolicy = () => {
  return (
    <Page classNames={'h-auto'}>
      <HomeNavbar />
      <div className="mx-auto max-w-7xl p-10 ">
        <h1 className="text-5xl font-bold text-green-900 text-center mb-8">Privacy Policy</h1>

        <p className="mb-4">
          This privacy policy explains how we collect, use, and protect personal information that we receive from visitors to our website. We are committed to protecting the privacy of our users, and we do not sell or distribute any personal information collected through our website.
        </p>

        {CONTEXT.map((item) => (
          <Disclosure key={item.heading}>
            {({ open, close }) => (
              <>
                <Disclosure.Button
                  className={`flex w-full justify-between border-b-2 border-green-900 px-4 py-4 text-left font-medium hover:text-white hover:bg-green-900 focus:outline-none focus-visible:ring focus-visible:ring-green-900 focus-visible:ring-opacity-75 text-md ${open
                    ? ' bg-green-900 text-yellow-900 '
                    : ' bg-background text-green-900 '
                    }`}
                >
                  <span className="w-3/4 md:w-full">{item.heading}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className={`${open
                      ? 'duration-200'
                      : 'rotate-180 duration-200 transform'
                      } h-5 w-5`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-500 ease-out"
                  enterFrom="transform scale-75 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition-opacity duration-0 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel
                    static
                    id={item.heading}
                    className="px-4 py-4 text-md font-semibold text-black-900 text-left"
                  >
                    {/* display as html */}
                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                    {/* {item.text} */}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        ))}
      </div>
      <Footer />
    </Page>
  );
};
