import Router from "./routes/index";
import { BrowserRouter } from "react-router-dom";
import AppProviders from "components/AppProviders";
import { ProjectProvider } from "./contexts/project";
import { BidProvider } from "./contexts/bid";
import { GoogleOAuthProvider } from '@react-oauth/google';
import CookieService from "auth/CookieService";
import DevSignInPage from "DevSignInPage";
import "./App.less"

function App() {
  const serverEnv = process.env.REACT_APP_SERVER_ENV;
  const isEnvSigned = CookieService.get("serverEnv");

  if (serverEnv === "prod" || isEnvSigned) {
    return (
      <AppProviders>
        <BidProvider>
          <ProjectProvider>
            <BrowserRouter>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <Router />
              </GoogleOAuthProvider>
            </BrowserRouter>
          </ProjectProvider>
        </BidProvider>
      </AppProviders>
    );
  }
  else {
    return (
      <DevSignInPage />
    );
  }
}

export default App;
