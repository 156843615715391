import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";

// components
import Page from "components/Page";
import Card from "components/Card";
import Container from "components/Container";
import HomeNavbar from "components/layout/HomeNavbar";
import RegisterForm from "components/auth/RegisterForm";
import EmailVerificationForm from "components/auth/RegisterEmailVerificationForm";
import { SeekerRegistrationForm } from "components/auth/SeekerRegistrationForm";
import { ProviderRegistrationForm } from "components/auth/ProviderRegisrationForm";
import { ProviderServicesRegistrationForm } from "components/auth/ProviderServicesRegistrationForm";

// hooks
import { useAuth } from "contexts/auth";

import { providerRegister } from "network/api";

// assets
import Icon from "assets/images/icons/ic_register.svg";

export const RegisterPage = () => {
  const auth = useAuth();

  const navigate = useNavigate();

  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showEmailForm, setEmailForm] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const [profile, setProfile] = useState("");
  const location = useLocation();

  const user_type = location.pathname.split("/");

  useEffect(() => {
    setProfile(user_type[user_type.length - 1]);
  }, [user_type]);

  const handleRegister = async () => {
    const { status, error, token, user } = await providerRegister(auth.user);

    if (status) {
      auth.dispatch({
        type: "LOGIN",
        payload: {
          token,
          user: { ...user },
        },
      });
      navigate("/");
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  return (
    <Page bg="bg-login-background">
      <HomeNavbar />

      <Container>
        <div className="w-full lg:w-5/12 lg:px-4 mx-auto">
          <Card>
            <div className="w-full">
              <h3 className="text-2xl text-center">
                Create your Daisy Account
              </h3>
            </div>
            <div className="max-w-md w-full space-y-8">
              <div className="mt-8 lg:mt-0">
                <div className="mt-6">
                  {showServices && !showVerificationForm && !showForm && (
                    <ProviderServicesRegistrationForm
                      onSubmit={() => handleRegister()}
                    />
                  )}

                  {!showVerificationForm &&
                    !showForm &&
                    !showServices &&
                    showEmailForm && (
                      <RegisterForm
                        onSuccess={() => {
                          setShowForm(true);
                          setEmailForm(false);
                        }}
                        onEmailSuccess={() => {
                          setShowVerificationForm(true);
                          setShowForm(false);
                          setEmailForm(false);
                        }}
                      />
                    )}

                  {showVerificationForm && !showForm && (
                    <EmailVerificationForm
                      Email={auth.user?.email}
                      onSuccess={() => setShowForm(true)}
                      changeEmail={() => setShowVerificationForm(false)}
                    />
                  )}

                  {profile === "seeker" && showForm && (
                    <SeekerRegistrationForm />
                  )}

                  {profile === "provider" && showForm && (
                    <ProviderRegistrationForm
                      onSuccess={() => {
                        setShowForm(false);
                        setShowVerificationForm(false);
                        setShowServices(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="w-6/12 lg:w-4/12 px-4 mx-auto ">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              default: { duration: 1.5 },
            }}
            src={Icon}
            alt="Register"
            className="max-w-xs filter drop-shadow-xl"
          />
        </div>
      </Container>
    </Page>
  );
};
