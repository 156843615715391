import moment from "moment";
import classNames from "classnames";
import { motion } from "framer-motion";
// components
import { DatePicker } from "antd";
// forms
import * as Yup from "yup";
import { Formik, Form, useField } from "formik";
// icons
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
// hooks
import { useBidData } from "contexts/bid";

const Card = ({ children, classNames }) => (
  <div
    className={
      "border-t-2 border-green-900 opacity-100 py-6 pt-12 overflow-y-auto w-full bg-semiWhite " +
      classNames
    }
  >
    {children}
  </div>
);

const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <textarea
        // className="text-area"
        className={classNames("text-input", {
          "border-red-600 focus:ring-red-600 focus:border-red-600": "",
        })}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const ProjectTimeFrame = ({ goPrevPage, goNextPage, onSubmit }) => {
  const { bidData } = useBidData();

  function disabledDate(current) {
    let customDate = new Date();
    let withoutTime = customDate.toISOString().split('T')
    return current && current < moment(withoutTime[0], "YYYY-MM-DD");
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto w-3/4`}
    >
      <div className="flex justify-center max-w-7xl m-auto lg:m-auto w-full">
        <div className="w-full">
          <h2 className="text-center pb-2 mt-0">Bid Information</h2>
          <div className="flex justify-center lg:space-x-6 mb-4 lg:max-h-80 flex-col lg:flex-row space-y-6 lg:space-y-0">
            <Formik
              initialValues={{
                start_date: 0 || bidData.start_date,
                comp_date: 0 || bidData.completion_date,
                description: "" || bidData.dates_description,
                valid_days: bidData.bid_valid_days ? moment(Date.now()).add(bidData.bid_valid_days, 'days') : ""
              }}
              validationSchema={
                Yup.object({
                  valid_days: Yup.date().nullable().required('Required'),
                })}
              onSubmit={(values) => {
                const bid_valid_days = moment(values.valid_days).diff(moment(Date.now()), 'days') + 1
                onSubmit({
                  "start_date": values.start_date,
                  "completion_date": values.comp_date,
                  "dates_description": values.description,
                  "bid_valid_days": bid_valid_days
                })
                goNextPage();
              }}
            >
              {({ values, errors, setFieldValue, isValid, dirty }) => {
                return (
                  <Form className="flex flex-col justify-center items-center content-center w-full">
                    <div className="py-4 pt-2">
                      <label
                        className="block text-md font-medium text-black-900 mb-1 text-center"
                        htmlFor="label"
                      >
                        Project Time Frame
                      </label>
                    </div>
                    <Card>
                      <div className="flex flex-row gap-2 items-start justify-center">
                        <div className="py-4 sm:py-5 w-full">
                          <label
                            className="block text-md font-medium text-black-900 mb-1 text-left"
                            htmlFor="label"
                          >
                            Bid Valid Until
                          </label>
                          <DatePicker
                            value={values.valid_days ? moment(values.valid_days) : ""}
                            onChange={(date, dateString) => {
                              setFieldValue("valid_days", dateString);
                            }}
                            // placeholder="(Optional)"
                            clearIcon={
                              <XIcon
                                className="block h-6 w-6 text-green-800"
                                aria-hidden="true"
                              />
                            }
                            suffixIcon={
                              <CalendarIcon
                                className="block h-6 w-6 text-green-800 mr-8"
                                aria-hidden="true"
                              />
                            }
                            dropdownClassName="test"
                            disabledDate={disabledDate}
                          />
                          {errors.valid_days && (
                            <div className="mt-2 text-sm text-red-600 text-left">
                              {errors.valid_days}
                            </div>
                          )}
                        </div>
                        <div className="py-4 sm:py-5 w-full">
                          <label
                            className="block text-md font-medium text-black-900 mb-1 text-left"
                            htmlFor="label"
                          >
                            Expected start date
                          </label>
                          <DatePicker
                            value={
                              values.start_date ? moment(values.start_date) : ""
                            }
                            onChange={(date, dateString) => {
                              setFieldValue("start_date", dateString);
                            }}
                            placeholder="(Optional)"
                            clearIcon={
                              <XIcon
                                className="block h-6 w-6 text-green-800"
                                aria-hidden="true"
                              />
                            }
                            suffixIcon={
                              <CalendarIcon
                                className="block h-6 w-6 text-green-800 mr-8"
                                aria-hidden="true"
                              />
                            }
                            dropdownClassName="test"
                            disabledDate={disabledDate}
                          />
                        </div>
                        <div className="py-4 sm:py-5 w-full">
                          <label
                            className="block text-md font-medium text-black-900 mb-1 text-left"
                            htmlFor="label"
                          >
                            Expected completion date
                          </label>
                          <DatePicker
                            value={
                              values.comp_date ? moment(values.comp_date) : ""
                            }
                            onChange={(date, dateString) => {
                              setFieldValue("comp_date", dateString);
                            }}
                            placeholder="(Optional)"
                            clearIcon={
                              <XIcon
                                className="block h-6 w-6 text-green-800"
                                aria-hidden="true"
                              />
                            }
                            suffixIcon={
                              <CalendarIcon
                                className="block h-6 w-6 text-green-800 mr-8"
                                aria-hidden="true"
                              />
                            }
                            dropdownClassName="test"
                            disabledDate={(current) => {
                              return (current && current < moment(values.start_date)) || disabledDate(current);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-2 sm:py-3 w-full">
                        <label
                          className="block text-md font-medium text-black-900 mb-1 text-left"
                          htmlFor="label"
                        >
                          Description
                        </label>
                        <MyTextArea
                          label="Description"
                          name="description"
                          rows="6"
                          placeholder="Add Description here..."
                        />
                      </div>
                    </Card>
                    <div className="flex flex-row w-1/2 justify-center self-center mt-10">
                      <button
                        type="button"
                        onClick={goPrevPage}
                        className="btn-outline mr-1"
                      >
                        Back
                      </button>
                      <button type="submit" disabled={!(isValid)} className="btn-primary ml-1 self-center">
                        Next
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectTimeFrame;
