import { twMerge } from "tailwind-merge";

const CardHeader = ({ icon, label, classNames, title, labelClasses, iconClasses }) => (
    <div className={twMerge("flex items-center justify-center lg:justify-start mb-5 ", classNames)}>
        <dt className={twMerge("inline text-md", iconClasses)}>{icon}</dt>
        <dd className={twMerge("inline ml-2 text-lg font-bold text-black-900 font-serif", labelClasses)}>
            {label}
            <h6 className="text-sm	">
                {title}
            </h6>
        </dd>
    </div>
);

export default CardHeader;