import { useState, useEffect } from "react";

import MotionInView from "components/animations/MotionInView";
// mapbox
import RoofEstimatorNotInteractive from 'components/mapbox/RoofEstimatorNotInteractive';
// comp.
import { Field } from "components/Field/index";
import { Card, CardHeader } from "components/Card/index";
// assets
import projectIcon from 'assets/images/icons/project.svg';
import contactIcon from 'assets/images/icons/contact.svg';
import LocationIcon from 'assets/images/icons/home/ic_choose_location.svg'
import { getSeekerProjectDetails } from "network/api";


const ProjectDetailsComponent = () => {
  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    let id = window.location.href.split("?")[1].split("=")[1];
    getSeekerProjectDetails(id).then((data) => {
      if (data.status) {
        setProjectDetails(data.project);
      }
    });

  }, []);


  const getAnswer = (index) => {
    const answer = projectDetails.qna[index].answer;
    return answer;
  };

  return (
    projectDetails &&
    <MotionInView
      className={`mx-auto md:px-2 md:pb-5 max-w-full	w-full `}
    >
      <div className="flex justify-center m-auto lg:m-auto w-full h-full bg-semiWhite opacity-90 p-5 md:p-1 flex-col min-w-fit">
        <div className="flex flex-col lg:flex-row justify-center mb-4 lg:max-h-80 space-y-6 lg:space-y-0 lg:space-x-6 w-full">
          <Card>
            <CardHeader
              icon={<img src={projectIcon} alt="bla" />}
              label=" Survey"
            />
            {projectDetails.qna.map((question, index) => (
              <Field
                key={index}
                label={question.question}
                value={getAnswer(index)}
              />
            ))}
          </Card>
          <Card>
            <CardHeader
              icon={<img src={contactIcon} alt="bla" />}
              label=" Contact"
            />
            <Field
              label="Name:"
              value={`${projectDetails.contact_details.first_name} ${projectDetails.contact_details.last_name}`}
            />
            <Field label="Email:" value={projectDetails.contact_details.email} />
            <Field label="Phone:" value={projectDetails.contact_details.phone} />
          </Card>
        </div>
      </div>
      <Card classNames={" text-center "}>
        <CardHeader
          classNames={" lg:justify-center mb-0 noMargin "}
          icon={<img src={LocationIcon} alt="bla" height='90' width='90' />}
          label="Location"
          labelClasses={' text-2xl '}
        />
        <h3 className="text-grey">&nbsp;&nbsp;{projectDetails.property.address_title}</h3>
        <div className={`max-w-full`}>
          <h2 className="text-center pb-2">Property</h2>
          <RoofEstimatorNotInteractive
            buildingData={projectDetails.property}
            data={projectDetails}
            setRoofsInfo={() => { }}
            hideSearch={true}
          />
        </div>
      </Card>
    </MotionInView>
  );
};

export default ProjectDetailsComponent;
