import classNames from "classnames";
import { useSnackbar } from "notistack";

// popup
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// forms
import SubmitButton from "components/SubmitButton";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "assets/styles/phone-input.css";
import ProfileImage from "components/ProfilePicture";
import PersonalInfoForm from "components/serviceSeeker/PersonalInfoForm";

import { useAuth } from "contexts/auth";

import * as api from "network/api";

const MySwal = withReactContent(Swal);

export const SeekerProfile = () => {
  const { dispatch, state } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const editSeekerInfo = (first_name, last_name, landline_number) => {
    api
      .editSeekerInfo(first_name, last_name, landline_number)
      .then(({ message }) => {
        enqueueSnackbar(message, {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const editSeekerName = async (fname, lname) => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <Formik
            initialValues={{
              first_name: fname,
              last_name: lname,
            }}
            onSubmit={async (values) => {
              const { first_name, last_name } = values;
              api
                .editSeekerInfo(first_name, last_name, "+1234567890")
                .then(({ message }) => {
                  enqueueSnackbar(message, {
                    variant: "success",
                  });
                  MySwal.close();
                  dispatch({
                    type: "SET_USER",
                    payload: {
                      user: {
                        ...state.user,
                        first_name,
                        last_name,
                      },
                    },
                  });
                })
                .catch((error) => {
                  enqueueSnackbar(error, {
                    variant: "error",
                  });
                });
            }}
          >
            {({ errors, isValid, dirty }) => {
              return (
                <Form>
                  <div className="py-4 sm:py-5">
                    <label
                      className="block text-md font-medium text-black-900 mb-1 text-left"
                      htmlFor="label"
                    >
                      First Name
                    </label>
                    <Field
                      type="text"
                      className={classNames("text-input", {
                        "border-red-600 focus:ring-red-600 focus:border-red-600":
                          errors.first_name,
                      })}
                      placeholder="First Name"
                      name="first_name"
                    // onKeyPress={(event) =>
                    //   event.key === "Enter" && MySwal.clickConfirm()
                    // }
                    />
                    <div className="flex justify-between items-center">
                      <div className="flex-auto w-2/5 mr-2">
                        {errors.first_name && (
                          <div className="mt-2 text-sm text-red-600 text-left">
                            <ErrorMessage name="first_name" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="py-4 sm:py-5">
                    <label
                      className="block text-md font-medium text-black-900 mb-1 text-left"
                      htmlFor="label"
                    >
                      Last Name
                    </label>
                    <Field
                      type="text"
                      className={classNames("text-input", {
                        "border-red-600 focus:ring-red-600 focus:border-red-600":
                          errors.last_name,
                      })}
                      name="last_name"
                      placeholder="Last Name"
                    />
                    <div className="flex justify-between items-center">
                      <div className="flex-auto w-2/5 mr-2">
                        {errors.last_name && (
                          <div className="mt-2 text-sm text-red-600 text-left">
                            <ErrorMessage name="last_name" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="py-4 sm:py-5">
                    <div className="flex justify-between items-center">
                      <div className="flex-auto w-1/2 mr-2">
                        <SubmitButton
                          disabled={!(isValid && dirty)}
                          // loading={loading}
                          buttonText="Save"
                        />
                      </div>
                      <div className="flex-auto w-1/2 mr-2">
                        <button
                          className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-full shadow-lg justify-center py-2 px-4 border"
                          onClick={(e) => {
                            e.preventDefault();
                            MySwal.close();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ),
    });
  };

  const handleEditCommunicationMethod = (smsCommunicationMethod) => {
    api
      .editCommunicationMethod(smsCommunicationMethod)
      .then(({ message }) => {
        enqueueSnackbar("Communication updated successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  return (
    <div className="relative bg-yellow-50 flex flex-1 flex-col w-full">
      <main className="flex-1 focus:outline-none">
        <div className="mx-auto pt-1 pb-1">
          <div className="px-4 sm:px-6 md:px-0">
            <h1 className="text-3xl font-extrabold text-gray-900">
              My Account
            </h1>
          </div>

          <div className="px-4 sm:px-6 md:px-0 py-6">
            {state.user?.role === "service_seeker" && (
              <div className="grid grid-cols-6 gap-2 ">
                <div className="col-span-6 sm:col-span-3 mr-4">
                  <PersonalInfoForm
                    initialValues={{
                      ...state.user,
                      nameEditable: false,
                      emailEditable: false,
                      landlineEditable: false,
                      phoneNumberEditable: false,
                      notSavedSmsCommunicationMethod:
                        state?.user?.smsCommunicationMethod,
                    }}
                    onEditInfo={editSeekerInfo}
                    onEditName={(fname, lname) => {
                      editSeekerName(fname, lname);
                    }}
                    onEditCommunicationMethod={handleEditCommunicationMethod}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 ml-10 flex justify-center self-baseline">
                  <div className="relative w-3/4">
                    <ProfileImage role="service_seeker" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
