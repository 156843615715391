import { useState } from 'react';
import { motion } from 'framer-motion';
import RoofEstimator from '../../mapbox/RoofEstimator';
const CustomeRoofService = ({
  onNext,
  onPrev,
  data,
  onChange,
  buildingData,
}) => {
  const [roofsData, setRoofsData] = useState();

  const setRoofsInfo = (roofsData) => {
    onChange({ custom_data: roofsData });
    setRoofsData(roofsData);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto md:px-8  md:pb-10`}
    >
      <RoofEstimator
        buildingData={buildingData}
        data={data}
        setRoofsInfo={setRoofsInfo}
      />

      <div className='text-center text-lg text-gray-500'>
        <a
          href='/assets/mapbox.mp4'
          target='_blank'
          rel='noreferrer'
          className='underline'
        >
          Click here to see a video on the drawing tool!
        </a>
      </div>

      <div className="mt-6">
        <div className="flex justify-center items-center pt-8 max-w-lg  m-auto">
          <button className="btn-outline mr-1" type="button" onClick={onPrev}>
            Back
          </button>
          <button
            disabled={!data.custom_data}
            className="btn-primary ml-1"
            type="button"
            onClick={() => {
              onNext();
              onChange(roofsData);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default CustomeRoofService;
