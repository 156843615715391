import { createContext, useContext, useState, useMemo, useEffect } from "react";
import Spinner from "components/Spinner";
import { getMaterialsInfo } from "network/api";

const BidContext = createContext();

export const useBidData = () => useContext(BidContext);

export const BidProvider = ({ children }) => {
  const [bidData, setBidData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [material_info, setMaterial_info] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const get = async () => {
      const response = await getMaterialsInfo();
      if (response.status && response?.materials.length > 0)
        setMaterial_info(
          response.materials.map((item) => {
            return {
              id: item.id,
              name: item.name,
              subCategories: response.material_subcategories.filter(
                (subItem) => subItem.material_id === item.id
              ),
            };
          })
        );
      setLoading(false);
    };
    get();
  }, []);

  const value = useMemo(
    () => ({
      bidData,
      setBidData,
      currentStep,
      setCurrentStep,
      material_info,
      setMaterial_info,
    }),
    [bidData, currentStep, material_info]
  );

  if (loading) return <Spinner />;

  return (
    <BidContext.Provider value={value}>{children}</BidContext.Provider>
  );
};
