import { motion } from "framer-motion";
import dates_step from '../../../assets/images/icons/dates_step_ic.svg';
import Card from '../../Card';
import IconContainer from '../../IconContainer';


const NewProjectBidingStep = ({ data, onChange, onPrev, onNext }) => {

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: 'easeInOut',
      }}
      className={`mx-auto sm:pt-5 lg:pt-0 md:px-8 bg-no-repeat	bg-cover bg-center md:pb-10
                  bg-dates_step overflow-y-auto lg:overflow-hidden min-h-900 relative`}
    >
      <div className="flex justify-center  h-full md:h-screen absolute bottom-0 md:relative">
        <div className="max-w-7xl	md:m-auto grid grid-cols-1 items-baseline md:items-center gap-0 md:gap-10 lg:gap-28 lg:grid-cols-2 justify-center">
          <Card>
            <h3 className="text-2xl text-center md:text-left">
              For how many days can providers bid on this project?
            </h3>
            <div className="flex justify-center md:justify-start ">
              <hr className="w-2/4 h-px bg-black-900" />
            </div>
            <div className={`max-w-full`}>
              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-4 mt-6">
                    <div>
                      <p className="block text-sm font-medium text-black-900 mb-1">
                        Number of days
                      </p>
                      <input
                        className="text-input"
                        id="title"
                        name="title"
                        min={0}
                        pattern="\d+"
                        type={'number'}
                        onInput={(event) => {
                          const value = event.target.value.replace(/[^0-9]/g, '');  // Remove all non-numeric characters
                          event.target.value = value;  // Update the input's value
                        }}
                        value={data.bid_days || ''}
                        onChange={(event) => {
                          const value = event.target.value;
                        
                          if (isNaN(value) || parseInt(value, 10) < 0) {
                            // Handle invalid input, perhaps set it to a default value
                            return;
                          }
                        
                          onChange({ bid_days: parseInt(value, 10) });
                        }}
                      />
                      <p className="block text-sm font-small text-black-400 mb-1 mt-2">
                        service providers must submit bids within this time frame
                      </p>
                    </div>
                    <div className="flex justify-end pt-8">
                      <button
                        className="btn-outline mr-1"
                        type="button"
                        onClick={onPrev}
                      >
                        Back
                      </button>
                      <button
                        className="btn-primary ml-1"
                        type="button"
                        onClick={onNext}
                        disabled={!data.bid_days || data.bid_days == null || data.bid_days < 0}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <IconContainer>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                default: { duration: 1.5 },
              }}
              src={dates_step}
              alt="Daisy Logo"
              className="max-w-none	md:max-w-xs w-full  lg:filter lg:drop-shadow-xl"
            />
          </IconContainer>
        </div>
      </div>
    </motion.div>
  );
};

export default NewProjectBidingStep;
