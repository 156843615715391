import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { FcServices, FcContacts, FcDocument } from 'react-icons/fc'
import { FiGlobe, FiInstagram, FiFacebook } from "react-icons/fi";
import roofingIcon from '../../assets/images/icons/ic_roofing.svg';
import sidingIcon from '../../assets/images/icons/ic_siding.svg';
import windowIcon from '../../assets/images/icons/houses.svg';

import DocumentsTable from 'components/serviceProvider/profile/DocumentsTable'

import { data } from './mock'

import { getProviderProfile } from 'network/api'

const ICONS = {
    roofing: roofingIcon,
    siding: sidingIcon,
    window: windowIcon
}

const Card = ({ children, classNames }) => (
    <div
        className={
            "border-t-2 border-green-900 opacity-100 p-4  overflow-y-auto w-full lg:w-full bg-semiWhite " +
            classNames
        }
    >
        {children}
    </div>
);

const CardHeader = ({ icon, label, labelClasses }) => (
    <div className="flex items-center justify-center lg:justify-start mb-5">
        <dt className="inline text-sm ">{icon}</dt>
        <dd className={"inline ml-2 text-lg font-bold text-black-900 font-serif " + labelClasses}>
            {label}
        </dd>
    </div>
);

const Field = ({ label, value }) => (
    <div className="mt-2">
        <dt className="inline text-sm font-bold text-black-900">{label}</dt>
        <dd className="inline  ml-2 text-sm text-gray-900">{value}</dd>
    </div>
);



const Review = () => {
    const [userData, setUserData] = useState({})

    useEffect(() => {
        let id = window.location.href.split("?")[1].split("=")[1];
        getProviderProfile(id).then(data => {
        })
        setUserData(data)
    }, [userData])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                default: { duration: 0.6 },
                ease: "easeInOut",
            }}
            className={`mx-auto w-full`}
        >
            {userData &&
                <div className="flex justify-center max-w-7xl m-auto">
                    <div className=" lg:m-auto justify-center w-full">
                        <div className={`max-w-full flex flex-col justify-center`}>
                            <h2 className="text-center pb-2 flex items-center justify-center">
                                <CardHeader
                                    icon={
                                        <div className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <img
                                                className="object-none h-20 w-20 rounded-full bg-white flex items-center justify-center"
                                                src={data.user.logo_url}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    label={data.user.company_name}
                                    labelClasses=" text-2xl "
                                />
                            </h2>
                            <div className="flex flex-column justify-center lg:space-x-6  mb-4 lg:max-h-80 flex-col lg:flex-col space-y-6	lg:space-y-6 ">
                                <Card classNames={" overflow-y-auto  w-full px-10"}>
                                    <div style={{ maxHeight: '350px' }}>
                                        <CardHeader
                                            icon={<FcContacts className="h-16 w-16" />}
                                            label=" Contact Information "
                                        />
                                        <div className="flex flex-col items-center gap-10">

                                            <div className=" opacity-100 
                                            overflow-y-auto w-full bg-semiWhite -backdrop-hue-rotate-15 flex flex-row justify-evenly">
                                                <div className="w-1/3">
                                                    <Field label="Company Name:" value={data.user.personal_name} />
                                                    <Field label="Email Address:" value={data.user.email} />
                                                    {data.user.phone && <Field label="Mobile Phone:" value={data.user.phone} />}
                                                </div>
                                                <div className="min-h-full border-l-2 border-green-900"></div>
                                                <div className="w-1/3">
                                                    <dt className="inline text-lg font-bold text-black-900">Addresses: </dt>
                                                    <ul className="list-disc text-green-900 font-bold mt-2">
                                                        {data.addresses.map((address, index) => (
                                                            <li key={index} className="leading-6">
                                                                {address.address_title}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-4 mt-2">
                                                <button clasclassNames="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2  inline-flex items-center rounded-full">
                                                    <FiGlobe className="h-6 w-6" color="#638B8D" />
                                                </button>
                                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2  inline-flex items-center rounded-full">
                                                    <FiInstagram className="h-6 w-6" color="#638B8D" />
                                                </button>
                                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2  inline-flex items-center rounded-full">
                                                    <FiFacebook className="h-6 w-6" color="#638B8D" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mt-5 mb-5" />
                                    <CardHeader
                                        icon={<FcServices className="h-16 w-16" />}
                                        label=" Services"
                                    />
                                    <dl className="mb-8 flex flex-row justify-center">
                                        {data.services.map((s) => (
                                            <span
                                                key={s}
                                                className="text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5"
                                            >
                                                <div className="w-full sm:w-auto bg-green-100 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-xl inline-flex items-center justify-center px-4 py-2.5 text-green-700 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 hover:text-white">
                                                    <img src={ICONS[s.title.toLowerCase()]} className="mr-3 w-10 h-10" alt="bla" />
                                                    <div className="text-left">
                                                        <div className="mb-1 text-md  font-bold hover:text-white">{s.title}</div>
                                                    </div>
                                                </div>
                                            </span>
                                        ))}
                                    </dl>
                                    <hr className="mt-5 mb-5" />
                                    <CardHeader
                                        // icon={<img src={projectIcon} height="75" width="50" alt="bla" />}
                                        icon={<FcDocument className="h-16 w-16" />}
                                        label=" Documents"
                                    />
                                    <dl className="mb-8">
                                        <DocumentsTable
                                            documents={data.documents}
                                            countries={data.states}
                                        // countries={countryStates}
                                        // types={docTypes.map((type) => type.title)}
                                        />
                                    </dl>
                                </Card >
                            </div >
                        </div >
                    </div >
                </div >
            }
        </motion.div >
    );
};

const ProviderProfile = () => {
    return (
        <Review />
    );
};

export default ProviderProfile;
