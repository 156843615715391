import Card from "components/Card";
import Page from "components/Page";
import Container from "components/Container";
import MainNavBar from "components/layout/MainNavBar";
import ProviderProfile from "./provider/profile";
import { SeekerProfile } from "./seeker/profile";

import { useAuth } from "contexts/auth";

export const ProfilePage = () => {
  const auth = useAuth();

  return (
    <Page bg="bg-login-background h-auto w-auto min-h-screen">
      <MainNavBar />
      <Container containerClasses="lg:mt-6 relative z-40">
        <Card
          classNames="min-w-full p-5 h-full bg-semiWhite opacity-90    
            flex justify-center flex-col rounded-3xl shadow-2xl w-full"
        >
          {auth.user?.role === "service_provider" ? (
            <ProviderProfile />
          ) : (
            <SeekerProfile />
          )}
        </Card>
      </Container>
    </Page>
  );
};
