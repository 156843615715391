import { useState, useEffect } from "react";
import { motion } from "framer-motion";
// layout Components
import Page from "components/Page";
import Spinner from "components/Spinner";
import Container from "components/Container";
import MainNavBar from "components/layout/MainNavBar";
// Steps Components
import ProjectDetailsStep from "components/serviceProvider/dashboard/Biding/ProjectDetailsStep";
import MaterialInfoStep from "components/serviceProvider/dashboard/Biding/MaterialInfoStep";
import ServiceFees from "components/serviceProvider/dashboard/Biding/ServiceFeesStep";
import ProjectTimeFrame from "components/serviceProvider/dashboard/Biding/ProjectTimeFrame";
import PaymentMilestones from "components/serviceProvider/dashboard/Biding/PaymentMilestone";
import Review from "components/serviceProvider/dashboard/Biding/Review";
// Context
import { useBidData } from "contexts/bid";
// APIs
import { getSeekerProjectDetails } from "network/api";

const BidProject = () => {
  const {
    bidData, setBidData, currentStep, setCurrentStep
  } = useBidData();
  const [projectDetails, setProjectDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // RESET BID CONTEXT
    setBidData({});
    // Back to first step of bidding
    setCurrentStep(0);
    let id = window.location.href.split("?")[1].split("=")[1];
    getSeekerProjectDetails(id).then((data) => {
      if (data.status) {
        setProjectDetails(data.project);
      }
    }).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNext = () => setCurrentStep((prev) => prev + 1);

  const onPrev = () => setCurrentStep((prev) => prev - 1);

  const updateBidData = (newData) => {
    setBidData((data) => ({ ...data, ...newData }));
  }

  const Steps = [
    <ProjectDetailsStep
      goNextPage={onNext}
      data={projectDetails}
      propertyData={projectDetails?.property}
      onSubmit={updateBidData}
    />,
    <MaterialInfoStep
      goNextPage={onNext}
      goPrevPage={onPrev}
      data={projectDetails}
      propertyData={projectDetails?.property}
      onSubmit={updateBidData}
    />,
    <ServiceFees
      goNextPage={onNext}
      goPrevPage={onPrev}
      onSubmit={updateBidData}
    />,
    <PaymentMilestones
      goNextPage={onNext}
      goPrevPage={onPrev}
      onSubmit={updateBidData}
    />,
    <ProjectTimeFrame
      goNextPage={onNext}
      goPrevPage={onPrev}
      onSubmit={updateBidData}
    />,
    <Review
      goPrevPage={onPrev}
      data={bidData}
    />
  ]


  return (
    <Page bg="bg-provider-dashboard-background h-auto w-auto min-h-screen">
      <MainNavBar />
      <Container containerClasses={"relative"}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            default: { duration: 0.6 },
            ease: "easeInOut",
          }}
          className={`mx-auto md:px-8 md:pb-10 h-auto w-full`}
        >
          <div className="flex justify-center max-w-7xl m-auto">
            <div className=" lg:m-auto justify-center w-full	">
              <div
                className="h-full bg-semiWhite opacity-90 p-10 md:p-7    
            flex justify-center flex-col md:rounded-t-lg 
            md:rounded-card shadow-2xl w-full"
              >
                {loading ? <Spinner /> : projectDetails && Steps[currentStep]}
              </div>
            </div>
          </div>
        </motion.div>
      </Container>
    </Page>
  );
};

export default BidProject;
