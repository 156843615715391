import { useState, useEffect } from "react";

import Page from "components/Page";
import Card from "components/Card";
import Spinner from "components/Spinner";
import Container from "components/Container";
import MainNavBar from "components/layout/MainNavBar";

import Projects from "components/serviceSeeker/dashboard/Projects";

import { getSeekerProjects } from "network/api";

export const SeekerDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSeekerProjects().then((data) => {
      if (data.status) {
        setProjects(data.projects);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Page bg="bg-provider-dashboard-background h-auto w-auto min-h-screen	">
      <MainNavBar />
      <Container containerClasses={"relative"}>
        <Card classNames="min-w-full p-5 bg-opacity-75 rounded-3xl mb-6">
          <div className="w-full px-2 sm:px-0">
            <h2 className="text-4xl text-center underline">Projects</h2>
            {loading ? <Spinner /> :
              <Projects projects={projects} />
            }
          </div>
        </Card>
      </Container>
    </Page>
  );
};
