import axios from "axios";
import URLService from "../auth/URLService";
import CookieService from "../auth/CookieService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//-------------------------------------------------------------------

// Create Bid
export const CreateBid = async (data) => {
  try {
    const response = await axios.post(
      URLService.submitBidURL(),
      data,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    console.log('CreateBid response', response)
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

// Create Milestones for a Bid
export const CreateMilestonesForBid = async (data) => {
  try {
    const response = await axios.post(
      URLService.createMilestonesForBidURL(),
      data,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

// Create Milestones Template
export const CreateMilestonesTemplate = async (data) => {
  try {
    const response = await axios.post(
      URLService.submitMilestonesTemplateURL(),
      data,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}

// Update Milestones Template
export const UpdateMilestonesTemplate = async (data) => {
  try {
    const response = await axios.post(
      URLService.updateMilestonesTemplateURL(),
      data,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}

// Delete Milestones Template
export const DeleteMilestonesTemplate = async (data) => {
  try {
    const response = await axios.post(
      URLService.deleteMilestonesTemplateURL(),
      data,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}

// Get Milestones Template
export const GetMilestonesTemplate = async () => {
  try {
    const response = await axios.get(
      URLService.getMilestonesTemplatesURL(),
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}

// GET Bids
export const GetBids = async () => {
  try {
    const response = await axios.get(
      URLService.getBidsURL(),
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}

// adjust bid
export const AdjustBid = async (data) => {
  try {
    const response = await axios.post(
      URLService.adjustBidURL(),
      data,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}

// confirm bid
export const ConfirmBid = async (bid_id) => {
  try {
    const response = await axios.post(
      URLService.confirmBidURL(),
      { bid_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}

// cancel bid
export const CancelBid = async (bid_id) => {
  try {
    const response = await axios.post(
      URLService.cancelBidURL(),
      { bid_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}


// delete document
export const DeleteDocument = async (data) => {
  try {
    const response = await axios.post(
      URLService.deleteDocumentURL(),
      data,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}