// export const days = (date_1, date_2) => {
//     date_2 = date_2 || new Date();
//     let difference = date_1.getTime() - date_2.getTime();
//     let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
//     if (TotalDays <= 0) return 'Bid Expired'
//     else return `${TotalDays} Days`
// }

export const days = (date_1, date_2) => {
    date_2 = date_2 || new Date();

    // Parse the date string into a Date object
    if (typeof date_1 === 'string') {
        date_1 = new Date(date_1.replace(/-/g, '/'));
    }

    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    if (TotalDays <= 0) return 'Bid Expired';
    else return `${TotalDays} Days`;
}

export const getNoDays = (date_1, date_2) => {
    date_2 = date_2 || new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
}