import { useEffect, useState } from "react";
import { motion } from "framer-motion";
// comps
import Spinner from "components/Spinner";
import MilestonesTable from "./MilestonesTable";
// hooks
import { useBidData } from "contexts/bid";
// API
import { GetMilestonesTemplate } from 'network/providerAPI'

const groupBy = (arr, groupFn) =>
    arr.reduce(
        (grouped, obj) => ({
            ...grouped,
            [groupFn(obj)]: { ...(grouped[groupFn(obj)]), obj },
        }),
        {}
    );

const INITIAL_MILESTONES = [{
    key: 1,
    title: 'Upon Completion',
    percentage: 100,
    description: `Please provide a description in this section. 
    Note that this milestone is set as default and cannot be removed. 
    However, you are able to modify the percentage and description as needed.`,
    order: 1
}]

const PaymentMilestones = ({ goNextPage, goPrevPage, onSubmit }) => {
    const { bidData, setBidData } = useBidData();

    const [milestones, setMilestones] = useState(INITIAL_MILESTONES)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getMilestones = async () => {
            setLoading(true)
            const res = await GetMilestonesTemplate();
            setLoading(false)
            if (res.status && res.milestones_templates.length > 0) {
                setBidData(prev => ({ ...prev, milestones: res.milestones_templates }))
                setMilestones(prev => Object.values(groupBy([...prev, ...res.milestones_templates], item => item.title)).map((item) => item.obj));
            } else {
                setBidData(prev => ({ ...prev, milestones: INITIAL_MILESTONES }))
                setMilestones(INITIAL_MILESTONES)
            }
        }
        if (bidData?.milestones)
            setMilestones(prev => Object.values(groupBy([...prev, ...bidData?.milestones], item => item.title)).map((item) => item.obj));
        else
            getMilestones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bidData?.milestones]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                default: { duration: 0.6 },
                ease: "easeInOut",
            }}
        >
            <div className="flex flex-col justify-center m-auto">
                <div className="lg:m-auto justify-center w-full max-w-full">
                    <h2 className="text-center pb-2 mt-0 mb-10">Bid Information</h2>
                    <h4 className="text-lg">Define your payment milestones here please: </h4>
                    <div className="flex justify-center min-w-full my-4 lg:max-h-80 flex-col space-y-6 lg:space-y-0 ">
                        <span
                            className="bg-green-100 self-end text-green-800 text-lg font-bold px-2.5 py-0.5 my-3 rounded dark:bg-green-200 dark:text-green-900"
                        >
                            Bid Amount: {bidData.material_total_cost + bidData.service_fees}$
                        </span>
                        {loading ? <Spinner /> :
                            <MilestonesTable
                                milestones={milestones.map((item, idx) => ({ ...item, key: idx + 1, amount: (item.percentage / 100) * (bidData.material_total_cost + bidData.service_fees) }))}
                                amount={bidData.material_total_cost + bidData.service_fees}
                                onSave={(data) => {
                                    setMilestones(data);
                                    onSubmit({ milestones: data });
                                }}
                            />
                        }
                    </div>
                </div>
                <div className="flex flex-row w-1/2 justify-center self-center mt-10 mx-auto">
                    <button
                        type="button"
                        onClick={goPrevPage}
                        className="btn-outline mr-1"
                    >
                        Back
                    </button>
                    <button
                        disabled={
                            milestones.reduce(function (prev, cur) {
                                return prev + cur.percentage;
                            }, 0) < 100 || milestones.reduce(function (prev, cur) {
                                return prev + cur.percentage;
                            }, 0) > 100
                        }
                        className="btn-primary ml-1 self-center" onClick={goNextPage}>
                        Next
                    </button>
                </div>
            </div>
        </motion.div>
    );
};

export default PaymentMilestones;
