// Provider Tabs
import ServicesForm from "./ServicesForm";
import PersonalInfoForm from "./PersonalInfoForm";
import ProfileAddresses from "./ProfileAddresses";
import ProviderDocuments from "./ProviderDocuments";

export const ProviderProfileTabs = {
    "Personal Information": [
        {
            path: "",
            component: (
                <PersonalInfoForm
                    key={"info"}
                />
            ),
        },
    ],
    Addresses: [
        {
            path: "addresses",
            component: (
                <ProfileAddresses
                    key={"addresses"}
                />
            ),
        },
    ],
    "Licenses and Certificates": [
        {
            path: "documents",
            component: (
                <ProviderDocuments
                    key={"certificates"}
                />
            ),
        },
    ],
    Services: [
        {
            path: "services",
            component: <ServicesForm key={"services"} />,
        },
    ],
};