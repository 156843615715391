import { Disclosure, Transition } from '@headlessui/react';
import { useCallback, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

function FAQ() {
  const closeRecent = useRef(undefined);
  const data = [
    {
      title: "General",
      questions: [
        {
          question: "What is TryDaisy?",
          answer: `TryDaisy is a web platform designed to connect homeowners with service providers.`
        },
        {
          question: "How does your website work?",
          answer: `Homeowners can use TryDaisy to estimate the size of their project and submit it for
bids. These bid requests(but not customer information) are then sent to providers in
their area for bids. Homeowners will receive these bids along with provider information
so they can make an informed decision. Once a homeowner finds a bid they like they
can reach out to the provider to get started.`,
        },
        {
          question: "Does your site sell my information?",
          answer: `Never! You will not be contacted by a provider unless you specifically reach out to them
yourself.`,
        },
        {
          question: "Is your site free to join as a homeowner?",
          answer: `The site is free to use for both homeowners and service providers.`,
        },
        {
          question: "Will I be bombarded with calls or emails once I submit a project?",
          answer: `No. Any initial contact with the service provider is initiated by you.`,
        },
        {
          question: "What are the types of roofing options for my home?",
          //TODO: add link to video once it's ready
          answer: `When creating a bid, you can choose from Asphalt, wood or metal roof options.`
        },
        {
          question: "How does TryDaisy choose their roofing providers? Is there a background check?",
          answer: `TryDaisy does not research or vet the providers. While they are typically BBB accredited,
providers are not screened. TryDaisy will provide information where available but homeowners
should do their due diligence when choosing a provider.`
        },
        {
          question: "Who do I contact if I have questions or concerns about a provider?",
          answer: `Any questions or concerns about a provider should be directed towards the provider
themselves. TryDaisy does not settle disputes between homeowners and providers.`
        }
      ]
    },
    {
      title: "Submitting a Bid", questions: [
        {
          question: "How do I submit a request for a roofing bid?",
          answer: `Simply type in your address and use our easy to use mapping tool to draw the area of
your roof in need of replacement. Use the pitch(inclination) estimations to further refine
your roof area. Choose your materials and add any specific details and your project is
ready to send for bids!`
        },
        {
          question: "Can I request bids from specific roofing contractors?",
          answer: `No. Your project is sent through our platform to providers in your area. They will then
bid your project and you can choose from several qualified contractors. This helps keep
your personal information private and the providers will be unable to contact you unless
you reach out to them.`
        },
        {
          question: "How many bids can I expect to receive for my roofing project?",
          answer: `The number of bids will vary depending on how many contractors service your area and
how many decide to bid your project.`
        },
        {
          question: "How do I choose the best bid for my project?",
          answer: `Try Daisy simply connects homeowners with providers. Homeowners will still need to
evaluate each company and bid and decide which is the best for their project.`
        },
        {
          question: "What is the payment process for the roofing project?",
          answer: `Payment will be made directly to the contractor. A payoff schedule will be included with
the bid, with percentages due at each milestone clearly stated.`
        },
        {
          question: "What happens after I accept a bid?",
          answer: `After you decide on a provider you will be able to communicate with them through our
platform to finalize the details of your project.`
        }
      ]
    },
    {
      title: "Service Provider", questions: [
        {
          question: "Is your site free to join as a provider?",
          answer: `Yes. TryDaisy is currently free to use for both providers and homeowners.`
        },
        {
          question: "How will I receive projects to bid on?",
          answer: `You can receive bid requests via text message and/or email.`
        },
        {
          question: "How do I contact a homeowner?",
          answer: `If the homeowner chooses your bid they will reach out to you.`
        },
        {
          question: "How quickly must I bid on a project?",
          answer: `Homeowners can specify the amount of time allotted to bid on their project.`
        },
        {
          question: "How does the customer estimate the size of their project?",
          answer: `TryDaisy allows homeowners to draw the roof area to be repaired/replaced on a satellite image
of their home. They can also estimate the pitch to increase the accuracy of this estimation.`
        },
        {
          question: "Do you offer any warranties or guarantees for roofing projects?",
          answer: `Any warranties/guarantees need to be negotiated with the service provider. Try Daisy is
simply the platform that connects homeowners and providers.`
        }
      ]
    }
  ]

  // this is stored in useCallback to impede unnecessary rerenders.
  // Calling this function with the close function available from disclosure will return a proper callback
  const closeCurrent = useCallback(
    (close) => () => {
      if (closeRecent.current && closeRecent.current !== close) {
        closeRecent.current();
      }
      closeRecent.current = close;
    },
    []
  );

  return (
    <section id="faqs" className="bg-yellow-100 py-10 mx-auto">
      <h2 className="text-4xl mb-10 text-green-900 font-bold text-center">
        Frequently Asked Questions
      </h2>
      <div className="max-w-7xl mx-auto px-4 flex flex-col-reverse lg:flex-row gap-4 justify-between items-center">
        <div className="w-full p-2 m-auto">
          <div className='flex flex-wrap justify-between items-start gap-y-4'>
            {data.map((cat, idx) => (
              <div key={idx} className={twMerge("mx-auto px-4", idx === 0 ? "w-full md:w-3/4" : "w-full md:w-1/2")}>
                <h2>{cat.title}</h2>
                {cat.questions.map((item, index) => (
                  <Disclosure key={item.question}>
                    {({ open, close }) => (
                      <>
                        <Disclosure.Button
                          className={`flex w-full justify-between border-b-2 border-green-900 px-4 py-4 text-left font-medium hover:text-white hover:bg-green-900 focus:outline-none focus-visible:ring focus-visible:ring-green-900 focus-visible:ring-opacity-75 text-md ${open
                            ? ' bg-green-900 text-yellow-900 '
                            : ' bg-background text-green-900 '
                            } ${index === 0 && ' rounded-t-lg '}`}
                          onClick={closeCurrent(close)}
                        >
                          <span className="w-3/4 md:w-full">{item.question}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className={`${open
                              ? 'duration-200'
                              : 'rotate-180 duration-200 transform'
                              } h-5 w-5`}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 15.75l7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        </Disclosure.Button>
                        <Transition
                          show={open}
                          enter="transition duration-500 ease-out"
                          enterFrom="transform scale-75 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition-opacity duration-0 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel
                            static
                            id={item.name}
                            className="px-4 py-4 text-md font-semibold text-black-900 text-left"
                          >
                            {item.answer}
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;