import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// components
import OtpInput from "react-otp-input";
import { useSnackbar } from "notistack";

// components
import SubmitButton from "../SubmitButton";

import { useAuth } from "../../contexts/auth";

// api
import { loginVerifyOtp, emailLogin } from "../../network/api";

const LoginEmailVerificationForm = ({ Email, changeEmail }) => {
  const location = useLocation();
  const history = useNavigate();
  // const { user } = state;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [otp, setOTP] = useState("");
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter]);

  const onVerifyClicked = async () => {
    setLoading(true);

    const { status, user, error, token } = await loginVerifyOtp(
      auth.user?.email,
      otp
    );

    setLoading(false);

    if (status) {
      auth.dispatch({
        type: "LOGIN",
        payload: {
          token,
          user,
          rememberToken: location.state?.rememberToken,
        },
      });
      history("/");
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  const resendOTP = async () => {
    setCounter(59);

    setLoading(true);

    const { status, message, error } = await emailLogin(auth.user?.email);

    setLoading(false);

    if (status) {
      history("/");
      // history.push({ pathname: location.state?.redirectTo || "/profile" });
      enqueueSnackbar(message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  return (
    <div className="mt-6">
      <p className="block text-sm font-medium text-black-900 mb-1 text-center mt-4">
        Enter the code we sent to {Email}
      </p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onVerifyClicked();
          setLoading(true);
        }}
        className="space-y-2 mt-6"
      >
        <div className="space-y-1">
          <div className="mt-1 flex flex-col">
            <div className="mt-1 relative rounded-md m-auto">
              <OtpInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                separator={<span className="px-1"></span>}
                inputStyle={"text-input text-black-900 min-w-40 py-4 text-xl"}
                shouldAutoFocus
                isInputNum
                autoComplete="one-time-code"
              />
            </div>
          </div>
        </div>

        <div className="pt-4">
          <SubmitButton
            disabled={otp.length < 6}
            loading={loading}
            buttonText="Verify"
          />
        </div>
      </form>

      <div className="pt-4 text-center">
        <button
          onClick={() => {
            changeEmail();
          }}
          className="text-sm btn-link"
        >
          Use a different email{" "}
        </button>
        •
        <button
          className="text-sm btn-link"
          onClick={() => resendOTP()}
          disabled={counter > 1}
        >
          {" "}
          Resend code {counter > 1 && `in 00:${counter}`}
        </button>
      </div>
    </div>
  );
};

export default LoginEmailVerificationForm;
