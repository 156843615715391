import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
// animation
import MotionInView from "components/animations/MotionInView";
// comp.
import { Card } from "components/Card/index";
import ReactTooltip from "react-tooltip";
// utils
import { fDate } from "utils/formatTime";
// assets
import RoofingIcon from "assets/images/icons/ic_roofing.svg";
import { shortenString } from "helpers/shortenString";
//
// import { useBidDate } from "contexts/bid_date";
// import { useEffect } from "react";

const renderPreferredDates = (start_date, end_date) => {
  return (
    <div className="absolute right-0 bottom-0 bg-offWhite rounded-tl-md rounded-br-md p-1">
      <span className="text-xs text-black font-bold">
        {start_date && end_date ? "Preferred Dates: " :
          start_date ? "Preferred Start Date: " :
            end_date ? "Preferred End Date: " : ''
        }
      </span>
      {start_date && end_date ? (
        <span className="text-xs text-black">{start_date ? fDate(start_date) : "not provided"} -&gt; {end_date ? fDate(end_date) : ""}</span>
      ) : (
        <span className="text-xs text-black">{start_date ? fDate(start_date) : end_date ? fDate(end_date) : ""}</span>
      )}
    </div>
  )
}


export default function Projects({ projects }) {
  // const { setBidDate } = useBidDate();

  // useEffect(() => {
  //   projects.forEach(project => {
  //     setBidDate(project.details.id, project.details.bid_end_date);
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [projects]);

  return (
    <MotionInView
      className={`mx-auto lg:p-5 min-h-900`}
    >
      <div className="relative max-w-7xl mx-auto text-gray-600 body-font container px-0 lg:px-5 py-5 lg:py-12 flex flex-col text-center w-full flex-wrap overflow-hidden">
        <div
          className={twMerge(
            "min-w-3/4 gap-10 m-auto pb-5 grid grid-cols-1",
            projects.length > 1 ? "lg:grid-cols-2" : "lg:grid-cols-1  lg:min-w-1/4 md:w-1/2"
          )}
        >
          {projects.length > 0 ? projects.map((project) => (
            <div key={project.details.id} className="w-full">
              <Link
                to={`/dashboard/projects/details?project=${project.details.id}`}
              >
                <Card classNames="mx-auto max-w-content">

                  <div className="flex justify-between gap-2 self-end">
                    <span className="flex justify-center items-center gap-1 px-2 py-1 text-xs text-white bg-green-700 rounded-md shadow-md" data-tip="Reference No'">
                      {project.reference}
                    </span>
                    <span className="flex justify-center items-center gap-1 px-2 py-2 text-sm text-black-900  font-bold bg-offWhite rounded-md shadow-md" data-tip={project.details.title}>
                      {project.property.label}
                    </span>
                  </div>

                  <div className="w-full flex justify-start items-center gap-4 mb-4 p-4">
                    <div className="rounded-full shadow-lg p-2 self-center" data-tip="Roofing">
                      <img
                        className="h-16 w-16"
                        src={RoofingIcon}
                        alt="avatar"
                      />
                    </div>
                    <div className="w-3/4 flex flex-col ml-2 text-left rounded-md shadow-lg p-4 h-28 ">
                      <span className="text-gray-900 font-bold text-md">
                        Location
                      </span>
                      <hr className="w-full" />
                      <span className="text-gray-600 text-sm pt-2">
                        {shortenString(project.property.address_title, 99)}
                      </span>
                    </div>
                  </div>

                  {renderPreferredDates(project.details.pref_start_date, project.details.pref_end_date)}
                  <ReactTooltip />

                </Card>
              </Link>
            </div>
          )) : (
            <div className="flex flex-col justify-center items-center w-full gap-y-10">
              <p className="text-center text-gray-600 text-2xl font-bold">
                No projects found
              </p>
              <Link to="/projects/new">
                <button
                  className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 
                    font-medium rounded-md text-sm mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 w-full shadow-lg justify-center py-2 px-4 border"
                >
                  Create Your First Project
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </MotionInView >
  );
}
