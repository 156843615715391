import Projects from "components/serviceProvider/dashboard/Projects";
import ActiveBids from "components/serviceProvider/dashboard/ActiveBids";

const SERVICES = [
    {
        "id": 1,
        "title": "Roofing",
        "acronym": "RO",
        "is_active": 1,
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 2,
        "title": "Siding",
        "acronym": "SI",
        "is_active": 1,
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 3,
        "title": "Window",
        "acronym": "WI",
        "is_active": 1,
        "created_at": null,
        "updated_at": null
    }
]

export const ProviderDashboardTabs = {
    "All Projects": [
        {
            path: "",
            component:
                <Projects
                    services={SERVICES}
                    onlyActives={true}
                />
        },
    ],
    "Bids": [
        {
            path: "bids",
            component:
                <ActiveBids
                    services={SERVICES}
                    onlyActives={true}
                />
        },
    ]
};