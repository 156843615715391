import { useState } from "react";
import { motion } from "framer-motion";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
// comps
import SubmitButton from "components/SubmitButton";
// icons
import roofingIcon from 'assets/images/icons/roofing.svg';
import projectIcon from 'assets/images/icons/project.svg';
// API
import { CreateBid } from 'network/providerAPI'
// Context
import { useBidData } from "contexts/bid";

const Card = ({ children, classNames }) => (
  <div
    className={
      "border-t-2 border-green-900 opacity-100 p-4  overflow-y-auto w-full lg:w-full bg-semiWhite " +
      classNames
    }
  >
    {children}
  </div>
);

const CardHeader = ({ icon, label }) => (
  <div className="flex items-center justify-center lg:justify-start mb-5">
    <dt className="inline text-sm ">{icon}</dt>
    <dd className="inline ml-2 text-lg font-bold text-black-900 font-serif">
      {label}
    </dd>
  </div>
);

const Field = ({ label, value }) => (
  <div className="mt-2">
    <dt className="inline text-sm font-bold text-black-900">{label}</dt>
    <dd className="inline  ml-2 text-sm text-gray-900">{value}</dd>
  </div>
);

const Review = ({ goPrevPage }) => {
  const { bidData } = useBidData();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true);

  const apiCall = () => {
    setLoading(true)
    CreateBid(bidData).then(res => {
      console.log('CreateBid res', res)
      if (res.status) {
        enqueueSnackbar("Your Submission waiting for Review.", { variant: 'info' });
        navigate('/provider/dashboard/bids')
      } else {
        console.log('CreateBid Error: ', res)
        enqueueSnackbar(res?.msg ? res.msg : "Bid Submission Failed.", { variant: 'error' });
      }
    }).catch(err => {
      console.log('CreateBid err', err)
      enqueueSnackbar(err?.msg ? err.msg : "Bid Submission Failed.", { variant: 'error' });

    }).finally(() => {
      setLoading(false)
    })
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto w-full`}
    >
      <div className="flex justify-center max-w-7xl m-auto">
        <div className=" lg:m-auto justify-center w-full">
          <div className={`max-w-full flex flex-col justify-center`}>
            <h2 className="text-center pb-2">Review Your Bid</h2>
            <div className="flex flex-column justify-center lg:space-x-6  mb-4 lg:max-h-80 flex-col lg:flex-col space-y-6	lg:space-y-6 ">
              <Card classNames={" overflow-y-auto  w-full px-10"}>
                <div style={{ maxHeight: '350px' }}>
                  <CardHeader
                    icon={<img src={projectIcon} height="50" alt="bla" />}
                    label=" Materials"
                  />
                  <div className="flex items-start gap-10">
                    {bidData?.material_break_down.length > 0 && bidData?.material_break_down.map((item, idx) => (
                      <dl key={idx} className="pr-8 pt-4 border-r-2 border-green-900
                      lastChild">
                        <Field label="Name:" value={`${item.name}, ${item.subcategory}`} />
                        <Field label="Unit Price:" value={`${item.unit_price}$`} />
                        <Field label="Quantity:" value={item.quantity} />
                        {
                          item.description && (
                            <Field label="Description:" value={item.description} />
                          )
                        }
                        {
                          item.reason && (
                            <Field label="Reason:" value={item.reason} />
                          )
                        }
                      </dl>
                    ))}
                  </div>
                </div>
                <div className="mt-14 text-left">
                  <Field label="Material Cost:" value={`${bidData.material_total_cost}$`} />
                </div>
                <hr className="mt-5 mb-5" />
                <CardHeader
                  icon={<img src={roofingIcon} width="50" height="50" alt="bla" />}
                  label=" Service"
                />
                <dl className="mb-8">
                  <Field label="Service Fees:" value={bidData.service_fees} />
                  {bidData.service_fees_description &&
                    <Field
                      value={bidData.service_fees_description}
                      label={"Description:"}
                    />
                  }
                </dl>
                <hr className="mt-5 mb-5" />
                <CardHeader
                  icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#497578" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>}
                  label=" Project Time Frame"
                />
                <dl className="mb-8">
                  <Field label="Bid Valid For:" value={bidData.bid_valid_days + " Days"} />
                  {bidData.start_date && <Field label="Start Date:" value={bidData.start_date} />}
                  {bidData.completion_date &&
                    <Field
                      value={bidData.completion_date}
                      label={"Completion Date"}
                    />
                  }
                  {bidData.dates_description &&
                    <Field
                      value={bidData.dates_description}
                      label={"Description:"}
                    />
                  }
                </dl>
              </Card>
            </div>
            <div className="flex justify-center space-x-6  mb-4">
              <ReCAPTCHA
                sitekey="6Ld3ir8cAAAAAHRbEDfJmP99t1McCqoY7SZfC-gV"
                onChange={(value) => setIsDisabled(!value)}
              />
            </div>
            <div className="flex flex-row w-1/2 justify-center self-center mt-10">
              <button
                type="button"
                onClick={goPrevPage}
                className="btn-outline mr-1"
              >
                Back
              </button>
              <SubmitButton
                disabled={isDisabled}
                loading={loading}
                buttonText="Submit"
                data-testid="submit-btn"
                onClick={() => apiCall()}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const BidReviewStep = ({ goPrevPage }) => {
  return (
    <Review
      goPrevPage={goPrevPage}
    />
  );
};

export default BidReviewStep;
