import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Page from "components/Page";
import Tabs from "components/Tabs";
import Card from "components/Card";
import Container from "components/Container";
import MainNavBar from "components/layout/MainNavBar";
import Projects from "components/serviceProvider/dashboard/Projects";
import ActiveBids from "components/serviceProvider/dashboard/ActiveBids";

import {
  getServices,
} from "network/api";

export const Dashboard = () => {
  const navigate = useNavigate();

  const [services, setServices] = useState([]);

  useEffect(() => {
    // navigate("/dashboard/projects")
    getServices().then((data) => {
      setServices(data);
    });
  }, []);

  const categories = {
    "All Projects": [
      {
        path: "",
        component:
          <Projects
            services={services}
            onlyActives={true}
          />
      },
    ],
    "Bids": [
      {
        path: "bids",
        component:
          <ActiveBids
            services={services}
            onlyActives={true}
          />
      },
    ]
  };

  const handleOnTabChange = (selectedIndex) => {
    const { path } = Object.values(categories)[selectedIndex][0];
    navigate(path || "/provider/dashboard");
  };

  return (
    <Page bg="bg-provider-dashboard-background h-auto w-auto min-h-screen	">
      <MainNavBar />
      <Container containerClasses={"relative"}>
        <Card classNames="min-w-full p-5 bg-opacity-75 rounded-3xl mb-6">
          <div className="w-full px-2 sm:px-0">
            <Tabs categories={categories} onTabChange={(selectedIndex) => handleOnTabChange(selectedIndex)} rootPath="provider/dashboard" />
          </div>
        </Card>
      </Container>
    </Page>
  );
};
