import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ArrayCount = (array) => {
  let counter = 0;
  array.map((option) => {
    return option ? counter++ : counter;
  });
  return counter;
};
export default function RadioInput({ options, value, onChange, previewsValue, setMaterialId, fordocs }) {
  let oldOptions = options;
  if (typeof options[0] === 'string')
    if (options && options[0] && !options[0].name && options[0].split('@')[1]) {
      let result = [];
      options.forEach(element => {
        let parts = element.split('@')[0].split('_').slice(1);

        // Capitalize just the first letter of the first word
        parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);

        let name = parts.join(' ');  
              
        if (element.split('@')[0].startsWith(previewsValue.toLowerCase())) {
          result.push(name)
        }
      });
      if (result.length !== 0) {
        options = result;
      }
      else {
        let result =[];
        options.forEach(element => {
          let parts = element.split('@')[0].split('_').slice(1);
  
          // Capitalize just the first letter of the first word
          parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
  
          let name = parts.join(' ');  
                
          result.push(name)

        })
        options = result;
      }
    }
  const onValueChange = (event) => {
    if (oldOptions && oldOptions[0] && (!fordocs && !oldOptions[0].name && oldOptions[0].split('@')[1])) {
      let change = oldOptions.filter((element) => element.includes(event.toLowerCase().split(' ').join('_')))
      let id = change[0].split('@')[1];
      setMaterialId(id)
      onChange(event)
    }
    else {
      onChange(event)
    }
  }
  return (
    <RadioGroup value={value} onChange={(event) => onValueChange(event)}>
      <RadioGroup.Label className="sr-only">Options</RadioGroup.Label>
      <div className="relative bg-white rounded-md -space-y-px">
        {options.map((option, optionIdx) => (
          option && (
            <RadioGroup.Option
              key={option?.name || optionIdx}
              value={option?.id || option}
              className={({ checked }) =>
                classNames(
                  optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  optionIdx === options.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  ArrayCount(options) === 1 || checked
                    ? "bg-green-50 border-green-900 z-10"
                    : "border-green-500",
                  "relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center text-sm">
                    <span
                      className={classNames(
                        ArrayCount(options) === 1 || checked
                          ? "bg-green-900 border-transparent"
                          : "bg-white border-gray-300",
                        active ? "ring-2 ring-offset-2 ring-green-500" : "",
                        "h-4 w-4 rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        ArrayCount(options) === 1 || checked
                          ? "text-green-900"
                          : "text-gray-900",
                        "ml-3 font-medium"
                      )}
                    >
                      {option?.name || option?.title || option}
                    </RadioGroup.Label>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))
        )}
      </div>
    </RadioGroup>
  );
}
