import LoadingDots from "./animations/LoadingDots";

import { twMerge } from "tailwind-merge";

const SubmitButton = ({
  buttonText,
  disabled,
  loading,
  classNames,
  ...props
}) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className={twMerge("btn-primary", classNames)}
      {...props}
    >
      {loading ? <LoadingDots></LoadingDots> : `${buttonText}`}
    </button>
  );
};

export default SubmitButton;
