import { twMerge } from "tailwind-merge";

export default function Container({ children, containerClasses }) {
  return (
    <div
      className={twMerge(
        "container max-w-7xl mx-auto lg:px-4 h-auto absolute bottom-0 lg:relative flex flex-wrap-reverse lg:flex-wrap items-center mt-0 content-between lg:mt-10",
        "lg:content-center min-h-800",
        containerClasses
      )}
    >
      {children}
    </div>
  );
}
