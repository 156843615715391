import SubmitButton from 'components/SubmitButton';
import { Field, Form, Formik } from 'formik';
import CookieService from "auth/CookieService";

const DevSignInPage = () => {
    const serverPass = process.env.REACT_APP_SERVER_PASS;
    const local = process.env.REACT_APP_SERVER_LOCAL;

    const onDevSignIn = (values) => {
        if (values.password.trim() === serverPass) {
            CookieService.set('serverEnv', 'access');
            window.location.reload();
        }
    }
    if (local) {
        CookieService.set('serverEnv', 'access');
        window.location.reload();
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                password: ""
            }}
            onSubmit={(values) => {
                onDevSignIn(values);
            }}
        >
            {({ errors, isValid, dirty }) => (
                <Form className="space-y-4 content-center flex justify-center flex-col w-1/2 h-full mx-auto">
                    <div className="space-y-1">
                        <div className="mt-1">
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <Field
                                    className={`text-input ${errors.password
                                        ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                                        : ""
                                        }`}
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pt-1 xxl:pt-4">
                        <SubmitButton
                            buttonText="Access"
                            disabled={!(isValid && dirty)}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )

}

export default DevSignInPage;