import { orderBy } from 'lodash'
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useState, useEffect } from "react";
// Comps.
import ReactTooltip from 'react-tooltip';
import Spinner from "components/Spinner";
import { Card } from "components/Card/index";
import { IconButton } from "components/Button/index";
// Utils
import { days } from "utils/calculate-days";
import { fDate } from "utils/formatTime";
// Assets
import { FiStar } from 'react-icons/fi'
import { MdUpdate } from 'react-icons/md'
import RoofingIcon from "assets/images/icons/ic_roofing.svg";
// API
import { getProviderAvaliableProjects, getProviderInterests, addInterestToProject, deleteInterestToProject } from "network/api";

export default function Projects() {
  const { enqueueSnackbar } = useSnackbar();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProviderAvaliableProjects().then((data) => {
      if (data.status) {
        setList(data.projects);
        getProviderInterests().then((res) => {
          setLoading(false);
          if (res.status) {
            const interests = res.interests.map((elem) => elem.project_id);
            if (interests.length > 0) {
              setList((prev) => prev.map((p) => {
                return {
                  ...p,
                  details: {
                    ...p.details,
                    is_interested: interests.includes(p.details.id),
                  },
                };
              }));
            }
            else {
              setList((prev) => prev.map((p) => {
                return {
                  ...p,
                  details: {
                    ...p.details,
                    is_interested: false,
                  },
                };
              }));
            }
          }
        });
      }
    });
  }, []);

  const handleAddInterestToProject = async (e, project_id) => {
    e.preventDefault(); // prevent page navigation
    const response = await addInterestToProject(project_id);
    if (response.status) {
      setList((prev) => {
        return prev.map((project) => {
          if (project.details.id === project_id) {
            return {
              ...project,
              details: {
                ...project.details,
                is_interested: true,
              },
            };
          } else {
            return project;
          }
        });
      });
      enqueueSnackbar("Interest added successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
  };

  const handleDeleteInterest = (e, project_id) => {
    e.preventDefault(); // prevent page navigation
    deleteInterestToProject(project_id).then((res) => {
      if (res.status) {
        setList((prev) => {
          return prev.map((project) => {
            if (project.details.id === project_id) {
              return {
                ...project,
                details: {
                  ...project.details,
                  is_interested: false,
                },
              };
            } else {
              return project;
            }
          });
        });
        enqueueSnackbar("Interest deleted successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    });
  };

  const renderPreferredDates = (start_date, end_date) => {
    return (
      <div className="absolute right-0 bottom-0 bg-offWhite rounded-tl-md rounded-br-md p-1">
        <span className="text-xs text-black font-bold">
          {start_date && end_date ? "Preferred Dates: " :
            start_date ? "Preferred Start Date: " :
              end_date ? "Preferred End Date: " : ''
          }
        </span>
        {start_date && end_date ? (
          <span className="text-xs text-black">{start_date ? fDate(start_date) : "not provided"} -&gt; {end_date ? fDate(end_date) : ""}</span>
        ) : (
          <span className="text-xs text-black">{start_date ? fDate(start_date) : end_date ? fDate(end_date) : ""}</span>
        )}
      </div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto lg:p-5 min-h-900`}
    >
      <div className="relative max-w-7xl mx-auto">
        <section className="text-gray-600 body-font ">
          <div className="container px-0 lg:px-5 py-5 mx-auto">
            <div className="flex flex-col text-center w-full">
              <div className="flex flex-wrap overflow-hidden justify-center">
                {!loading &&
                  <div
                    className={twMerge(
                      "min-w-3/4 gap-10 m-auto pb-5 grid grid-cols-1 w-full ",
                      list.length > 1 ? "lg:grid-cols-2" : "lg:grid-cols-1 lg:min-w-1/4 md:w-1/2"
                    )}
                  >
                    {orderBy(list, ['details.is_interested', 'details.bid_end_date'], 'desc').map((project, idx) => {
                      const { custom_data, details, property } = project;
                      let jsonData = {};
                      if (custom_data?.data)
                        jsonData = JSON.parse(custom_data?.data)[0];
                      const bid_valid_until = days(new Date(details.bid_end_date));
                      return (
                        <div key={idx} className="w-full">
                          <Link
                            to={`/dashboard/projects/bid?project=${details.id}`}
                            className={bid_valid_until === 'Bid Expired' ? "pointer-events-none opacity-70" : ""}
                          >
                            <Card classNames="mx-auto">
                              <div className="flex flex-row-reverse justify-between items-center pb-2">
                                <div className="self-end">
                                  {details.is_interested ? (
                                    <IconButton
                                      Icon={<FiStar className="h-6 w-6" />}
                                      classNames="bg-green-900 text-white hover:bg-red-600 hover:border-red-600"
                                      data-tip="Remove your Interest on this project"
                                      onClick={(e) => handleDeleteInterest(e, details.id)}
                                    />) :
                                    <IconButton
                                      Icon={<FiStar className="h-6 w-6" />}
                                      onClick={(e) => handleAddInterestToProject(e, details.id)}
                                      data-tip="Add to Interest"
                                    />
                                  }
                                  <ReactTooltip />
                                </div>
                                <div className="flex justify-between gap-2">
                                  <span className="flex justify-center items-center gap-1 px-2 py-1 text-xs text-white bg-green-700 rounded-md shadow-md">
                                    <MdUpdate className="h-6 w-6" />
                                    {bid_valid_until === 'Bid Expired' ? bid_valid_until : `${bid_valid_until} Remaining`}
                                  </span>
                                  {jsonData?.properties &&
                                    <span className="flex justify-center items-center gap-1 px-2 py-1 text-xs text-black bg-yellow-500 rounded-md shadow-md">
                                      {jsonData?.properties?.roofArea} sqft
                                    </span>
                                  }
                                </div>
                              </div>
                              <div className="w-full flex justify-start items-center gap-4 mb-4 p-4">
                                <div className="rounded-full shadow-lg p-2 self-center">
                                  <img
                                    className="h-16 w-16"
                                    src={RoofingIcon}
                                    alt="avatar"
                                  />
                                </div>
                                <div className="w-3/4 flex flex-col ml-2 text-left rounded-md shadow-lg p-4 h-28">
                                  <span className="text-gray-900 font-bold text-md">
                                    Location
                                  </span>
                                  <hr className="w-full" />
                                  <span className="text-gray-600 text-sm pt-2">
                                    {`${property.city}, ${property.country}`}
                                  </span>
                                </div>
                              </div>
                              {renderPreferredDates(details.pref_start_date, details.pref_end_date)}
                            </Card>
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                }
                {!loading && list.length === 0 && (
                  <div className="flex flex-col space-y-5 justify-center items-center w-full">
                    <h2>No Available Projects</h2>
                  </div>
                )}
                {loading && <Spinner />}
              </div>
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
}
