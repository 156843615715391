import axios from "axios";

import CookieService from "../auth/CookieService";

const http = axios.create();

http.interceptors.request.use((request) => {
  const token = CookieService.get("token");
    if (token) {
      request.headers["Authorization"] = "Bearer " + token;
    }
  if (!request.params) {
    request.params = {};
  }

  return request;
});

http.interceptors.response.use(
  ({ data }) => {
    return data;
  },
  (error) => {
    return Promise.reject(error.response.data.msg || `${error}`);
  }
);

http.defaults.headers["Authorization"] = "Bearer " + CookieService.get("token");

export default http;
