import { Navigate, useRoutes } from "react-router-dom";
// context
import { useAuth } from "../contexts/auth";
// guards
import RoleBasedGuard from "guards/RoleBasedGuard";
// pages------------------------------------------
// auth pages
import { LoginPage } from "../pages/auth/LoginPage";
import { RegisterPage } from "../pages/auth/RegisterPage";
// general pages
import Home from '../pages/general/Home/index';
import AboutUs from "pages/general/AboutUs";
// import { HomePage } from "../pages/general/HomePage";
import { NotFound } from "../pages/general/NotFound";
import { CookiePolicy } from "../pages/general/CookiePolicy";
import { PrivacyPolicy } from "../pages/general/PrivacyPolicy";
import { TermsConditions } from "../pages/general/TermsConditions";
// user pages
import { ProfilePage } from "../pages/profile";
// seeker
import { SeekerDashboardTabs } from "components/serviceSeeker/dashboard/Tabs";
import NewProjectPage from "pages/NewProjectPage";
import { SeekerDashboard } from 'pages/seeker/dashboard';
import { ProjectDetails } from 'pages/seeker/projectDetails';
import ProviderProfileViewer from 'pages/seeker/providerProfile'
// provider
import { ProviderProfileTabs } from "components/serviceProvider/profile/Tabs";
import { ProviderDashboardTabs } from "components/serviceProvider/dashboard/Tabs";
import SubmitBid from "pages/provider/bidProject";
import { Dashboard } from 'pages/provider/dashboard';
import ProviderProjectDetails from "pages/provider/projectDetails";
//
import PaymentDemo from "pages/Payment";
import Inbox from "pages/Inbox";

// pages------------------------------------------

export default function Router() {
  const { state } = useAuth();

  return useRoutes([
    {
      path: "/",
      element: state?.token ? <ProfilePage /> : <Home />,
      children: [...Object.values(ProviderProfileTabs).map((tab) => {
        const { path, component } = tab[0];
        return { path, element: component }
      })]
    },
    {
      path: "faqs",
      element: <AboutUs />
    },
    {
      path: "login",
      element: state?.token ? <ProfilePage /> : <LoginPage />,
      children: [...Object.values(ProviderProfileTabs).map((tab) => {
        const { path, component } = tab[0];
        return { path, element: component }
      })]
    },
    {
      path: "/home",
      element: state?.token ? <Home /> : <Home />,
    },
    {
      path: "register/seeker",
      element: state?.token ? <ProfilePage /> : <RegisterPage />,
    },
    {
      path: "register/provider",
      element: state?.token ? <ProfilePage /> : <RegisterPage />,
    },
    {
      path: "/inbox",
      element: state?.token ? <Inbox /> : <LoginPage />,
    },
    {
      path: "/stripe",
      element: <PaymentDemo />,
    },
    {
      path: "/dashboard/projects/details",
      element: state?.token ? (
        state?.user?.role === "service_provider" ? (
          <ProviderProjectDetails />
        ) : (
          <ProjectDetails />
        )
      ) : (
        <Home />
      ),
      children: [...Object.values(SeekerDashboardTabs).map((tab) => {
        const { path, component } = tab[0];
        return { path, element: component }
      })]
    },
    {
      path: "/dashboard/projects/details/provider",
      element: state?.token ? (
        <ProviderProfileViewer />
      ) : (
        <Home />
      ),
    },
    {
      path: "dashboard",
      element: state?.token ? (
        <RoleBasedGuard accessibleRoles="service_seeker">
          <SeekerDashboard />
        </RoleBasedGuard>
      ) : (
        <Home />
      ),
    },
    {
      path: "/provider/dashboard",
      element: state?.token ? (
        <RoleBasedGuard accessibleRoles="service_provider">
          <Dashboard />
        </RoleBasedGuard>
      ) : (
        <Home />
      ),
      children: [...Object.values(ProviderDashboardTabs).map((tab) => {
        const { path, component } = tab[0];
        return { path, element: component }
      })]
    },
    {
      path: "/dashboard/projects/bid",
      element: (
        <RoleBasedGuard accessibleRoles="service_provider">
          <SubmitBid />
        </RoleBasedGuard>
      ),
    },
    { path: "privacy-policy", element: <PrivacyPolicy /> },
    { path: "projects/new", element: <NewProjectPage /> },
    { path: "cookie-policy", element: <CookiePolicy /> },
    { path: "terms", element: <TermsConditions /> },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
