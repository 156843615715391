import { useState } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { useMediaQuery } from 'react-responsive';
// components
import Card from "../../components/Card";
import Page from "../../components/Page";
import Container from "../../components/Container";
import HomeNavbar from "../../components/layout/HomeNavbar";
// hooks
import { useAuth } from "../../contexts/auth";
// forms
import LoginForm from "../../components/auth/LoginForm";
import EmailVerificationForm from "../../components/auth/LoginEmailVerificationForm";
// assets
import Icon from "../../assets/images/icons/ic_login.svg";

export const LoginPage = () => {
  const { user } = useAuth();
  const isMiniMobile = useMediaQuery({ query: '(max-height: 800px) and (min-width: 320px) and (max-width: 375px)' });

  const [showVerificationForm, setShowVerificationForm] = useState(false);

  return (
    <Page bg="bg-login-background">
      <HomeNavbar />
      <Container>
        <div className={twMerge("w-full lg:w-5/12 lg:px-4 mx-auto", isMiniMobile && "max-h-500")}>
          <Card>
            <h3 className="text-2xl text-center">
              Login to your Daisy Account
            </h3>
            <div className="w-full space-y-8">
              <div className="mt-8">
                <div className="mt-3 xxl:mt-6">
                  {showVerificationForm && (
                    <EmailVerificationForm
                      Email={user?.email}
                      changeEmail={() => setShowVerificationForm(false)}
                    />
                  )}
                  {!showVerificationForm && (
                    <LoginForm
                      onEmailSuccess={() => {
                        setShowVerificationForm(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="w-6/12 lg:w-4/12 px-4 mx-auto mb-10 sm:mb-20 xxl:mb-0">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              default: { duration: 1.5 },
            }}
            src={Icon}
            alt="Register"
            className="max-w-xs filter drop-shadow-xl"
          />
        </div>
      </Container>
    </Page>
  );
};
