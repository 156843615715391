import { AuthProvider } from "contexts/auth";
import ToastProvider from "contexts/toast";

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>
  );
}

export default AppProviders;
