import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { AiOutlineMenu } from 'react-icons/ai';

import { useAuth } from "contexts/auth";

import Logo from "assets/images/logos/logo.svg";

const MainNavBar = () => {
  const history = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useAuth();

  const logout = async () => {
    await dispatch({ type: "LOGOUT" });
    history("/login");
  };

  const user =
    state.user?.role === "service_provider"
      ? {
        imageUrl: state.user?.logo_url
          ? state.user.logo_url
          : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
      }
      : {
        imageUrl: state.user?.profile_pic
          ? state.user.profile_pic
          : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
      };

  const mappingRoutes = {
    "/dashboard": "Dashboard",
    "/projects/new": "New Project",
    "/projects": "Projects",
    "/help": "Help",
    "/consultant/register": "Apply for Project Consultant",
    "/service-provider/register": "Apply for Service Provider",
  };

  const navigation = [
    {
      exact: true,
      name: "Dashboard",
      activeClassName: "nav-link-active",
      className: "nav-link",
      to: "/provider/dashboard",
      role: "service_provider",
    },
    {
      exact: true,
      name: "Dashboard",
      activeClassName: "nav-link-active",
      className: "nav-link",
      to: "/dashboard",
      role: "service_seeker",
    },
    {
      exact: true,
      name: "New Project",
      activeClassName: "nav-link-active",
      className: "nav-link",
      to: "/projects/new",
      role: "service_seeker",
    },
  ];

  const userNavigation = [
    { name: "My Account", onClick: () => history("/") },
    { name: "Inbox", onClick: () => history("/inbox") },
    { name: "Logout", onClick: () => logout() },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Disclosure as="nav" className="border-b bg-semiWhite sticky w-full top-0 left-0 z-1000">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <AiOutlineMenu className="h-6 w-6" />
                    )}
                  </Disclosure.Button>
                </div>

                <div className="flex-shrink-0 flex items-center">
                  <NavLink to="/home">
                    <img className=" h-8 w-auto" src={Logo} alt="Workflow" />
                  </NavLink>
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                  {navigation.map(
                    (item) =>
                      item.role === state.user?.role && (
                        <NavLink
                          key={item.name}
                          className={({ isActive }) =>
                            isActive
                              ? item.activeClassName +
                              " font-bold"
                              : item.className
                          }
                          to={item.to}
                        >
                          {item.name}
                        </NavLink>
                      )
                  )}
                </div>
              </div>
              <div className="lg:hidden flex items-center">
                <div className="font-bold">
                  {mappingRoutes[location.pathname]}
                </div>
              </div>
              <div className="flex items-center">
                <div className=" ml-4 flex-shrink-0 flex items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full bg-white flex items-center justify-center object-cover"
                          src={user.imageUrl}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white  focus:outline-none z-50">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <button
                                onClick={item.onClick}
                                className={classNames(
                                  active ? "bg-green-100" : "",
                                  "block w-full py-2 btn-user-nav text-sm pl-4 text-left"
                                )}
                              >
                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel
            className="lg:hidden rounded-b-lg shadow-custom absolute right-0 w-56 origin-top-right bg-white text-center z-1000"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col items-center">
              {navigation.map(
                (item) =>
                  item.role === state.user?.role && (
                    <NavLink
                      key={item.name}
                      className={({ isActive }) =>
                        isActive
                          ? "hidden"
                          : classNames(
                            item.current
                              ? "bg-green-900 text-white"
                              : "text-black-600 hover:bg-green-500 hover:text-white",
                            "block px-3 py-2 text-base font-medium",
                            item.className
                          )
                      }
                      exact={item.exact}
                      to={item.to}
                    >
                      {item.name}
                    </NavLink>
                  )
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default MainNavBar;
