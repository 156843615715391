const apiDomain = process.env.REACT_APP_API_URL;

class URLService {
  static checkEmail() {
    return apiDomain + "user/register/check-email";
  }

  static verifyEmail() {
    return apiDomain + "user/register/verify-email";
  }

  static seekerRegister() {
    return apiDomain + "user/register/seeker";
  }

  static providerRegister() {
    return apiDomain + "user/register/provider";
  }

  static userGoogleLogin() {
    return apiDomain + "user/login/gmail";
  }

  static userEmailLogin() {
    return apiDomain + "user/login/check-email";
  }

  static loginVerifyOtp() {
    return apiDomain + "user/login/verify-email";
  }

  static seekerUploadProfilePicture() {
    return apiDomain + "user/seeker/upload-picture";
  }

  static providerUploadProfilePicture() {
    return apiDomain + "user/provider/upload-logo";
  }

  static userRegister() {
    return apiDomain + "user/register";
  }

  static userGetDetails() {
    return apiDomain + "user/get";
  }

  static verifyOtp() {
    return apiDomain + "user/verify-otp";
  }

  static editSeekerProfile() {
    return apiDomain + "user/seeker/edit-profile";
  }

  static editSeekerEmail() {
    return apiDomain + "user/seeker/change-email";
  }

  static editSeekerPhoneNumber() {
    return apiDomain + "user/seeker/change-mobile";
  }

  static editCommunicationMethod() {
    return apiDomain + "user/communication-method";
  }

  static editProviderProfile() {
    return apiDomain + "user/provider/edit-profile";
  }

  // Provider Upload Documents Endpoints ----------
  static getServiceProviderDocsTypes() {
    return apiDomain + "general/sp-document-types";
  }

  static getCountries() {
    return apiDomain + "general/countries";
  }

  static getStatesOfCountry() {
    return apiDomain + "general/states-of-country";
  }

  static uploadDocuments() {
    return apiDomain + "user/provider/upload-documents";
  }

  static editDocuments() {
    return apiDomain + "user/provider/edit-documents";
  }

  static deleteDocumentURL() {
    return apiDomain + "user/provider/delete-document";
  }
  // Provider Upload Documents Endpoints ----------

  // User Change His Email Endpoints ----------
  static userChangeEmail() {
    return apiDomain + "user/change-email";
  }

  static userVerifyChangeEmail() {
    return apiDomain + "user/verify-change-email";
  }
  // User Change His Email Endpoints ----------

  // Seeker Change His Phone Number Endpoints ----------
  static userChangePhoneNumber() {
    return apiDomain + "user/change-mobile";
  }

  static userVerifyChangePhoneNumber() {
    return apiDomain + "user/verify-change-mobile";
  }
  // Seeker Change His Phone Number Endpoints ----------

  // GET Services from DB
  static getServices() {
    return apiDomain + "general/services";
  }

  // General: subscribions of interesting users
  static interestRequest() {
    return apiDomain + "interest/register";
  }

  // GET Provider interests
  static getProviderInterests() {
    return apiDomain + "user/provider/project/get-interests";
  }

  // GET Provider projects
  static getProviderAvaliableProjects() {
    return apiDomain + "user/provider/get-available-projects";
  }

  // GET Seeker projects
  static getSeekerAvaliableProjects() {
    return apiDomain + "user/seeker/get-projects";
  }

  // GET Seeker projects
  static getSeekerProjectDetails() {
    return apiDomain + "project/id";
  }

  // Provider add interest to project
  static addInterestToProjectURL() {
    return apiDomain + "user/provider/project/add-interest";
  }

  // Provider delete interest to project
  static deleteInterestFromProjectURL() {
    return apiDomain + "user/provider/project/remove-interest";
  }

  // Provider Submit Bid for a Project
  static submitBidURL() {
    return apiDomain + "user/provider/project/bid";
  }

  static CancelBidURL() {
    return apiDomain + "user/provider/project/cancel-bid"
  }

  // GET Provider Profile
  static GetProviderProfile() {
    return apiDomain + "user/provider"
  }

  // providerUpdateBidValidityDays
  static providerUpdateBidValidityDays() {
    return apiDomain + "user/provider/project/update-bid-validity";
  }

  // seekerUpdateBidValidityDays
  static seekerUpdateBidValidityDays() {
    return apiDomain + "user/seeker/project/update-bid-end-date";
  }

  // GET Seeker Bids
  static getSeekerProjectBids() {
    return apiDomain + "user/seeker/project/get-bids";
  }
  // shortList a Bid
  static shortListBid() {
    return apiDomain + "user/seeker/project/shortlist-bid";
  }
  // aprove a Bid
  static acceptBid() {
    return apiDomain + "user/seeker/project/accept-bid";
  }
  // decline a Bid
  static declineBid() {
    return apiDomain + "user/seeker/project/decline-bid";
  }

  // seeker select bid
  static selectBid() {
    return apiDomain + "user/seeker/project/select-bid";
  }

  // Provider
  static submitMilestonesTemplateURL() {
    return apiDomain + "user/provider/milestones/create-milestones-template";
  }

  static updateMilestonesTemplateURL() {
    return apiDomain + "user/provider/milestones/update-milestones-template";
  }

  static deleteMilestonesTemplateURL() {
    return apiDomain + "user/provider/milestones/delete-milestones-template";
  }

  static getMilestonesTemplatesURL() {
    return apiDomain + "user/provider/milestones/get-milestones-templates";
  }

  static createMilestonesForBidURL() {
    return apiDomain + "user/provider/milestones/create-bid-milestones"
  }

  static getBidsURL() {
    return apiDomain + "user/provider/get-bids";
  }

  static adjustBidURL() {
    return apiDomain + "user/provider/project/adjust-bid";
  }

  static confirmBidURL() {
    return apiDomain + "user/provider/project/confirm-bid";
  }

  static cancelBidURL() {
    return apiDomain + "user/provider/project/cancel-bid";
  }

  static cancelSelectedBidURL() {
    return apiDomain + "user/seeker/project/cancel-bid-selection";
  }

  // GET Services from DB
  static getMessages() {
    return apiDomain + "user/inbox/get-threads";
  }
  // GET Services from DB
  static sendNewMessage() {
    return apiDomain + "user/inbox/send-message";
  }
  // GET Services from DB
  static sendThreadMessage() {
    return apiDomain + "user/inbox/reply";
  }
  // GET Services from DB
  static getThreadById() {
    return apiDomain + "user/inbox/get-threads-by-user-id";
  }

  static getProviderAddresses() {
    return apiDomain + "user/provider/addresses";
  }

  static addProviderAddress() {
    return apiDomain + "user/provider/add-address";
  }

  static updateProviderAddress() {
    return apiDomain + "user/provider/update-address";
  }

  static deleteProviderAddress() {
    return apiDomain + "user/provider/delete-address";
  }

  // ----- General Services -----
  static getMaterialsInfo() {
    return apiDomain + "general/material-info";
  }
}

export default URLService;
