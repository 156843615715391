import { motion } from "framer-motion";
import Card from '../../Card';
import IconContainer from '../../IconContainer';
import details_step_ic from '../../../assets/images/icons/details_step_ic.svg';

const NewProjectDetailsStep = ({ data, onChange, onPrev, onNext }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{
      default: { duration: 0.6 },
      ease: 'easeInOut',
    }}
    className={`mx-auto sm:pt-5 lg:pt-0 md:px-8  bg-no-repeat	bg-cover	bg-center   md:pb-10 bg-details_step 
    overflow-y-auto lg:overflow-hidden  min-h-900 relative`}
  >
    <div className="flex justify-center  h-full  md:h-screen absolute bottom-0 md:relative">
      <div className="max-w-7xl	 md:m-auto grid grid-cols-1 items-baseline md:items-center gap-0 md:gap-10 lg:gap-28 lg:grid-cols-2 justify-center">
        <Card>
          <h3 className="text-2xl text-center md:text-left">
            Tell us a bit more about your project
          </h3>
          <div className="flex justify-center md:justify-start ">
            <hr className="w-2/4 h-px bg-black-900" />
          </div>
          <div className={`max-w-full`}>
            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-4 mt-6">
                  <div className="mt-1">
                    <label
                      className="block text-sm font-medium text-black-900 mb-1"
                      htmlFor="title"
                    >
                      Title
                    </label>
                    <input
                      className="text-input"
                      id="title"
                      name="title"
                      value={data.title || ''}
                      onChange={(event) =>
                        onChange({ title: event.target.value })
                      }
                    />
                  </div>
                  <div className="mt-1">
                    <label
                      className="block text-sm font-medium text-black-900 mb-1"
                      htmlFor="about"
                    >
                      Description
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      value={data.description || ''}
                      rows={3}
                      className="shadow-sm focus:ring-green-900 focus:border-green-900 block w-full sm:text-sm border border-gray-300 rounded-md"
                      placeholder="(Optional)"
                      onChange={(event) =>
                        onChange({ description: event.target.value })
                      }
                    />
                  </div>

                  <div className="flex justify-end pt-8">
                    <button
                      className="btn-outline mr-1"
                      type="button"
                      onClick={onPrev}
                    >
                      Back
                    </button>
                    <button
                      disabled={!data.title}
                      className="btn-primary ml-1"
                      type="button"
                      onClick={onNext}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <IconContainer>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              default: { duration: 1.5 },
            }}
            src={details_step_ic}
            alt="Daisy Logo"
            className="max-w-xs w-full lg:filter lg:drop-shadow-xl"
          />
        </IconContainer>
      </div>
    </div>
  </motion.div>
);

export default NewProjectDetailsStep;
