import { useState, useEffect } from "react";
// comps
import MilestonesTable from "./Milestones";
import CheckboxGroup from "components/forms/CheckboxGroup";
// popup
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// hooks
import { useAuth } from "contexts/auth";
// api
import { getServices, editProviderInfo } from "network/api";
import { GetMilestonesTemplate } from 'network/providerAPI'

const MySwal = withReactContent(Swal);

const intialMilestones = [{
  key: 1,
  title: 'Upon Completion',
  percentage: 100,
  // total_amount: 1000,
  description: 'description to be added here',
  order: 1
}]

const ServicesForm = () => {
  const { state, dispatch } = useAuth();

  const [services, setServices] = useState([]); // all avaliable services in the system
  const [selectedServices, setSelected] = useState([]); // provider's supported services
  const [milestones, setMilestones] = useState(intialMilestones) // provider's milestones
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getServices().then((data) => {
      setServices(data);
      setSelected(state.user.services.map((s) => s.title));
    });
  }, [state.user]);

  useEffect(() => {
    const getMilestones = async () => {
      setLoading(true);
      const res = await GetMilestonesTemplate();
      if (res.status) {
        setMilestones(prev => Object.values(groupBy([...prev, ...res.milestones_templates], item => item.title)).map((item) => item.obj));
        setLoading(false);
        // setMilestones(prev => [...prev, ...res.milestones_templates]);
        // setMilestones(res.milestones_templates);
      }
      // if(!res) setMilestones(MOCK_DATA.milestones);
    }
    getMilestones();
  }, [])

  const groupBy = (arr, groupFn) =>
    arr.reduce(
      (grouped, obj) => ({
        ...grouped,
        [groupFn(obj)]: { ...(grouped[groupFn(obj)]), obj },
      }),
      {}
    );

  const openSwalForm = () => {
    MySwal.fire({
      customClass: "mySwal",
      showConfirmButton: false,
      html: (
        <div>
          <CheckboxGroup
            values={selectedServices}
            options={services.map((service) => service.title)}
            onChange={(value) => {
              setSelected(value);
              updateProviderServices(value);
            }}
          />
        </div>
      ),
    });
  };

  const updateProviderServices = (values) => {
    const selected = services
      .filter((item) => values.includes(item.title))
      .map((s) => s.id)
      .join(",");
    const req = {
      services: selected.toString(),
    };
    editProviderInfo(req).then((data) => {
      if (data.status)
        dispatch({
          type: "SET_USER",
          payload: {
            user: {
              ...state.user,
              services: services.filter((item) => values.includes(item.title)),
            },
          },
        });
    });
  };

  return (
    <div>
      <div className="py-4 sm:py-5 text-md text-gray-500 sm:pt-5 font-bold capitalize">
        How would you like to split your payments?
      </div>
      {loading ? (
        <div className="flex items-center justify-center py-10">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-900"></div>
        </div>
      ) :
        <div>
          {selectedServices.map((s) => (
            <div key={s}>
              <span
                className="bg-green-100 text-green-800 text-lg font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"
              >
                {s}
              </span>
              {!loading &&
                <MilestonesTable service_id={state.user.services.filter(sr => sr.title === s)[0].id} milestones={milestones} />
              }
              <hr className="my-10" />
            </div>
          ))}
        </div>
      }
{/* to add later in case they required new services  */}
      {/* <div className="flex items-center justify-center text-left">
        <button
          className="btn-primary mt-10"
          style={{ width: "30%", alignItems: "center" }}
          onClick={() => openSwalForm()}
          disabled
        >
          Add/Remove Service
        </button>
      </div>
      <p className="text-center my-4 text-red-600">
        NOTE: Adding other services is disabled since now only "Roofing" service supported
      </p> */}
    </div>
  );
};

export default ServicesForm;
