import { useState } from 'react';

function FilterBids({ bids, setProjectBids, onFilterChange }) {
    const [setFilterPrice] = useState('all');
    const [filterOption, setFilterOption] = useState('all');

    const filterBids = (status) => {
        if (status === "all") {
            setProjectBids(orderBids(bids));
            return;
        }
        if (status === "lth") {
            let newBids = bids.sort((a, b) => {
                let aTotalPrice = a.details.material_total_cost + a.details.service_fees;
                let bTotalPrice = b.details.material_total_cost + b.details.service_fees;
                return aTotalPrice - bTotalPrice;
            });
            setProjectBids(newBids);
            return;
        }
        if (status === "htl") {
            let newBids = bids.sort((a, b) => {
                let aTotalPrice = a.details.material_total_cost + a.details.service_fees;
                let bTotalPrice = b.details.material_total_cost + b.details.service_fees;

                return bTotalPrice - aTotalPrice;
            });
            setProjectBids((newBids));
            return;
        }
        let newBids = bids.filter(value => value.status === status);
        setProjectBids(newBids);
    }

    const orderBids = (bids) => {
        if (!bids) { return [] }
        let newBids = [];
        const selectedBid = bids.filter((bid) => bid.status.toLowerCase() === "selected");
        if (selectedBid.length === 1) {
            // setSelectedBid(true);
            newBids.push(selectedBid[0]);
        } else {
            let accptedBid = false;
            bids.forEach((value) => {
                if (value.status === 'Accepted') {
                    newBids.push(value);
                    accptedBid = true;
                }
            });
            if (accptedBid) {
                return newBids;
            }
            bids.forEach((value) => {
                if (value.status === 'Shortlisted') {
                    newBids.push(value);
                }
            });
            bids.forEach((value) => {
                if (value.status === 'Pending') {
                    newBids.push(value);
                }
            });
        }

        return newBids;
    }

    return (
        <div className="w-1/4 bg-semiWhite rounded-2xl shadow-xl my-5 ">
            <select
                className="rounded-2xl border-none w-full  focus:ring-0"
                value={filterOption}
                onChange={
                    (event) => {
                        // filterBids(event.target.value);
                        // setIsfilterOn(true);
                        setFilterOption(event.target.value)
                        // setFilterPrice('all');
                        onFilterChange(event.target.value);
                    }}>
                <option value="all">All</option>
                <option value="Pending">Pending</option>
                <option value="Shortlisted">Shortlisted</option>
                <option value="Adjusted by SP">Adjusted By Service Provider</option>
                <option value="Declined by SS">Declined By You</option>
                <option value="Cancelled by SP">Declined By Service Provider</option>
            </select>
        </div>
    );
}

export default FilterBids;

/**
 *  <div className="w-1/2 grid grid-cols-4 bg-semiWhite rounded-2xl shadow-xl my-5 ">
            <div className="relative col-span-2 ">
                <input type="search"
                    id="default-search"
                    className="rounded-2xl block p-3 pr-10 w-full focus:ring-0 text-sm text-gray-900 border-none bg-inherit focus:border-none"
                    placeholder="Search..."
                    required
                />
                <div className="flex absolute inset-y-0 right-2 items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
            </div>
            <select
                className="ml-auto border-none borderLeft focus:ring-0"
                value={filterPrice}
                onChange={
                    (event) => {
                        filterBids(event.target.value);
                        // setIsfilterOn(true);
                        setFilterPrice(event.target.value)
                    }}>
                <option value="all">Initial</option>
                <option value="lth">Lowest to highest</option>
                <option value="htl">highest to Lowest</option>
            </select>
 */