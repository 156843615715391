import axios from "axios";
import http from "./http";
import URLService from "../auth/URLService";
import CookieService from "../auth/CookieService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const checkEmail = async (email, registerType) => {
  try {
    const response = await axios.post("user/register/check-email", {
      email,
      reg_type: registerType,
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

export const verifyEmail = async (email, code) => {
  try {
    const response = await axios.post(URLService.verifyEmail(), {
      email,
      verification_code: code,
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    return { error: e.response.data.message || `${e}` };
  }
};

// User Register his info as a Service Seeker
export const seekerRegister = async (req) => {
  try {
    const response = await axios.post(URLService.seekerRegister(), req, {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// User Register his info as a Service Provider
export const providerRegister = async (req) => {
  try {
    const response = await axios.post(URLService.providerRegister(), req, {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// User Login via Google Provider
export const googleLogin = async (email, googleId) => {
  try {
    const response = await axios.post(URLService.userGoogleLogin(), {
      email,
      google_id: googleId,
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// User Login via Email Input
export const emailLogin = async (email) => {
  try {
    const response = await axios.post(URLService.userEmailLogin(), {
      email,
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// Verify User's Email before Login
export const loginVerifyOtp = async (email, verification_code) => {
  try {
    const response = await axios.post(URLService.loginVerifyOtp(), {
      email,
      verification_code,
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    return { error: e.response.data.message || `${e}` };
  }
};

// New User Registration
export const register = async (userName, registerType) => {
  try {
    const response = await axios.post(URLService.userRegister(), {
      username: userName,
      register_type: registerType,
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

// Verify New User's Phone Number
export const verifyOtp = async (userName, loginType, code) => {
  try {
    const response = await axios.post(URLService.verifyOtp(), {
      username: userName,
      code,
      channel_type: loginType,
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    return { error: e.response.data.msg || `${e}` };
  }
};

// GET User's Info and check if He has a valid Token or not
export const getUserDetails = async () => {
  try {
    const response = await axios.get(URLService.userGetDetails(), {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

export const editSeekerInfo = (first_name, last_name, landline_number) => {
  return http.post(URLService.editSeekerProfile(), {
    first_name: first_name,
    last_name: last_name,
    landline_number,
  });
};

export const editSeekerEmail = (email) => {
  return http.post(URLService.editSeekerEmail(), {
    email,
  });
};

export const editSeekerPhoneNumber = (phoneNumber) => {
  return http.post(URLService.editSeekerPhoneNumber(), {
    phone: phoneNumber,
  });
};

export const editCommunicationMethod = (smsCommunicationMethod) => {
  return http.post(URLService.editCommunicationMethod(), {
    pref_method: smsCommunicationMethod ? "2" : "1",
  });
};

export const editProviderInfo = async (req) => {
  try {
    const response = await axios.post(
      URLService.editProviderProfile(),
      req,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};
// {
//     services: "1,2",
//     company_name: company_name,
//     personal_name: personal_name,
//     landline_number: landline_number,
//   }

export const getProviderAddresses = () => {
  return http.get(URLService.getProviderAddresses());
};

export const addProviderAddress = (data) => {
  return http.post(URLService.addProviderAddress(), data);
};

export const updateProviderAddress = (data) => {
  return http.post(URLService.updateProviderAddress(), data);
};

export const deleteProviderAddress = (address_id) => {
  return http.post(URLService.deleteProviderAddress(), address_id);
};


// export const updateProviderAddress = (address) => {
//   return http.post(URLService.updateProviderAddress(), {
//     address,
//   });
// };

// Provider Upload Documents API ----------
export const getServiceProviderDocsTypes = () => {
  return http.get(URLService.getServiceProviderDocsTypes());
};

export const getCountries = () => {
  return http.get(URLService.getCountries());
};

export const getStatesOfCountry = (country_id) => {
  return http.get(
    URLService.getStatesOfCountry() + `?country_id=${country_id}`
  );
};

// export const uploadDocuments = (files, details) => {
//   const formData = new FormData();
//   files.forEach((file) => {
//     formData.append("documents[]", file, `${file.name}`);
//   });
//   formData.append("details", details);
//   return http.post(URLService.uploadDocuments());
// };
export const uploadDocuments = async (files, details) => {
  // return http.post(URLService.uploadDocuments(), formData);
  try {
    let formData = new FormData();
    formData.append("details", details);
    formData.append("documents[]", files);
    const response = await axios.post(URLService.uploadDocuments(), formData, {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};
// Provider Upload Documents API ----------

// General User APIs ----------

// User Change His Email
export const userChangeEmail = async (email) => {
  try {
    const response = await axios.post(
      URLService.userChangeEmail(),
      { email },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// Send Verification code for Change Email Address
export const userVerifyChangeEmail = async (email, verification_code) => {
  try {
    const response = await axios.post(
      URLService.userVerifyChangeEmail(),
      { email, verification_code },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// User Change His Phone Number API 
export const userChangePhoneNumber = async (phone) => {
  try {
    const response = await axios.post(
      URLService.userChangePhoneNumber(),
      { phone },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// Send Verification code for Change Phone Number
export const userVerifyChangePhoneNumber = async (
  phone,
  verification_code
) => {
  try {
    const response = await axios.post(
      URLService.userVerifyChangePhoneNumber(),
      { phone, verification_code },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};
// General User APIs ----------

export const getServicesWithSurvey = async () => {
  try {
    const services = await getServices();
    console.log('services', services)
    const questions = await Promise.all(
      services.map(async ({ id }) => await getServiceSurvey(id))
    );
    return questions;
  } catch (e) {
    console.error(e);
  }
};

export const getBiddingDays = async () => {
  try {
    const response = await axios.get("general/default-bid-days");
    if (response.data?.status) {
      return response.data.default_bid_days;
    }
  } catch (e) {
    console.error(e);
  }
}



export const getServices = async () => {
  try {
    const response = await axios.get("general/services");
    if (response.data?.status) {
      return response.data.services.filter((service) => service.id === 1);
    }
  } catch (e) {
    console.error(e);
  }
};

export const getServiceSurvey = async (id) => {
  try {
    const response = await axios.get(`general/service-survey`, {
      params: {
        service_id: id,
      },
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data?.status) {
      return response.data.survey;
    }
  } catch (e) {
    console.error(e);
  }
};

export const createProperty = async (data) => {
  try {
    const response = await axios.post("user/seeker/create-property", data, {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data?.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export const createProject = async (data) => {
  try {
    const response = await axios.post("user/seeker/create-project", data, {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data?.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export const getAvailableTime = async (id) => {
  try {
    const response = await axios.get(`appointments/get-available-slots`, {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
      params: {
        service_id: id,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
  }
};

// General: subscribions of interesting users
export const interestRequest = async (email) => {
  try {
    const response = await axios.post(URLService.interestRequest(), { email });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

// Proviers
export const getProviderAvaliableProjects = async () => {
  try {
    const response = await axios.get(
      URLService.getProviderAvaliableProjects(),
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

export const getProviderInterests = async () => {
  try {
    const response = await axios.get(URLService.getProviderInterests(), {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};
// Proviers

//seeker
export const getSeekerProjects = async () => {
  try {
    const response = await axios.get(URLService.getSeekerAvaliableProjects(), {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

export const getSeekerProjectDetails = async (project_id) => {
  try {
    const response = await axios.get(URLService.getSeekerProjectDetails(), {
      params: {
        project_id: project_id,
      },
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
}


export const getSeekerProjectBids = async (project_id) => {
  try {
    const response = await axios.get(URLService.getSeekerProjectBids(), {
      params: {
        project_id: project_id,
      },
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.message || `${e}` };
  }
};

export const shortListBid = async (bid_id) => {
  try {
    const response = await axios.post(
      URLService.shortListBid(),
      { bid_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data?.status) {
      return response.data.status;
    }
  } catch (e) {
    console.error(e);
  }
};

export const acceptBid = async (bid_id) => {
  try {
    const response = await axios.post(
      URLService.acceptBid(),
      { bid_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data?.status) {
      return response.data.status;
    }
  } catch (e) {
    console.error(e);
  }
};

// Seeker select bid for project among all bids available
export const selectBid = async (bid_id) => {
  try {
    const response = await axios.post(
      URLService.selectBid(),
      { bid_id },
      { headers: { Authorization: "Bearer " + CookieService.get("token") } }
    );
    if (response.data?.status) {
      return response.data.status;
    }
  } catch (e) {
    console.error(e);
  }
};

export const declineBid = async (bid_id) => {
  try {
    const response = await axios.post(
      URLService.declineBid(),
      { bid_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data?.status) {
      return response.data.status;
    }
  } catch (e) {
    console.error(e);
  }
};

// seeker cancel selected bid
export const cancelSelectedBid = async (bid_id) => {
  try {
    const response = await axios.post(
      URLService.cancelSelectedBidURL(),
      { bid_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    console.log('CancelSelectedBid response', response)
  } catch (e) {
    console.error(e);
  }
}

export const addInterestToProject = async (project_id) => {
  try {
    const response = await axios.post(
      URLService.addInterestToProjectURL(),
      { project_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data?.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export const deleteInterestToProject = async (project_id) => {
  try {
    const response = await axios.post(
      URLService.deleteInterestFromProjectURL(),
      { project_id },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data?.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export const providerUploadProfilePicture = async (form) => {
  try {
    const response = await axios.post(
      URLService.providerUploadProfilePicture(),
      form,
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

export const seekerUploadProfilePicture = async (form, token) => {
  try {
    const response = await axios.post(
      URLService.seekerUploadProfilePicture(),
      form,
      {
        headers: {
          Authorization: "Bearer " + (token || CookieService.get("token")),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

// GET Provider Profile 
export const getProviderProfile = async (provider_id) => {
  try {
    const response = await axios.get(URLService.GetProviderProfile() + `/${provider_id}`, {
      // params: {
      //   provider_id
      // },
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};


// Provider: update bid validity days
export const providerUpdateBidValidityDays = async (bid_id, bid_valid_days) => {
  try {
    const response = await axios.post(
      URLService.providerUpdateBidValidityDays(),
      { bid_id, bid_valid_days },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
}

// Seeker: update bid validity days
export const seekerUpdateBidValidityDays = async (bid_id, bid_valid_days, project_id) => {
  try {
    const response = await axios.post(
      URLService.seekerUpdateBidValidityDays(),
      { project_id, bid_id, bid_valid_days },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
  // GET Provider Profile 
}

export const getThreads = async (provider_id) => {
  try {
    const response = await axios.get(URLService.getMessages(), {
      headers: {
        Authorization: "Bearer " + CookieService.get("token"),
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

export const replyToThread = async (thread_id, body) => {
  try {
    const response = await axios.post(
      URLService.sendThreadMessage(),
      {
        thread_id: thread_id,
        body: body
      },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

export const getThreadById = async (user_id,project_id) => {
  try {
    const response = await axios.get(
      URLService.getThreadById(),
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
        params: {
          user_id: user_id,
          project_id:project_id
        }
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

export const sendMessage = async (subject, body, recipients) => {
  try {
    const response = await axios.post(
      URLService.sendNewMessage(),
      {
        subject: subject,
        body: body,
        recipients: recipients
      },
      {
        headers: {
          Authorization: "Bearer " + CookieService.get("token"),
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    return { error: e.response.data.msg || `${e}` };
  }
};

// ----- General APIs -----
export const getMaterialsInfo = async () => {
  try {
    const response = await axios.get(URLService.getMaterialsInfo());
    if (response.data?.status) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
}