import { createContext, useContext, useReducer, useEffect, useState } from "react";
import Spinner from "components/Spinner";
import CookieService from "../auth/CookieService";
import _ from 'lodash'
import { getUserDetails } from "../network/api";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const initialState = {
  user: null,
  token: CookieService.get("token"),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      const options = { path: "/" };
      if (action.payload.token) {
        if (action.payload.rememberToken) {
          const expiresAt = 30 * 24 * 60 * 60 * 1000; // 30 days
          const date = new Date();
          date.setTime(date.getTime() + expiresAt);
          options.expires = date;
        }
        CookieService.set("token", action.payload.token, options);
        return {
          ...state,
          user: action.payload.user,
          token: action.payload.token,
        };
      }
    }
      break;

    case "SET_USER": {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case "LOGOUT": {
      CookieService.remove("token");
      return {
        token: null,
        user: null,
      };
    }
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state?.token) {
      setLoading(true)
      getUserDetails().then(
        ({ role, services, user, addresses, documents, error }) => {
          if (!error) {
            if (state?.user?.id) {
              CookieService.set('user_id', state?.user?.id);
            }
            dispatch({
              type: "SET_USER",
              payload: {
                user: {
                  role: role,
                  email: user?.email,
                  first_name: user?.first_name,
                  last_name: user?.last_name,
                  profile_pic: user?.profile_pic,
                  logo_url: user?.logo_url,
                  phoneNumber: user?.phone,
                  landline_number:
                    user?.landline_number || user?.landline_phone,
                  company_name: user?.company_name,
                  id: state?.user?.id,
                  personal_name: user?.personal_name,
                  facebook_url: user?.facebook_url,
                  instagram_url: user?.instagram_url,
                  website_url: user?.website_url,
                  addresses: addresses || [],
                  smsCommunicationMethod:
                    user?.prefered_comm === "Email and Phone",
                  services: services,
                  documents: documents || [],
                  lastDocId: documents?.length > 0 ? _.sortBy(documents, ['id'])[documents.length - 1].id : 0,
                },
              },
            });
          }
        }
      ).finally(() => setLoading(false));
    }
  }, [state?.token, dispatch, state?.user?.id]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{ state, dispatch, ...state }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };