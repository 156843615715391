import { useState, useEffect } from "react";

import Page from "components/Page";
import Container from "components/Container";
import MainNavBar from "components/layout/MainNavBar";
import ProjectDetailsComponent from "components/serviceProvider/dashboard/ProjectDetails";

import { getSeekerProjectDetails } from "network/api";

const ProviderProjectDetails = () => {
  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    let id = window.location.href.split("?")[1].split("=")[1];
    getSeekerProjectDetails(id).then((data) => {
      if (data.status) {
        setProjectDetails(data.project);
      }
    });
  }, []);

  return (
    <Page bg="bg-provider-dashboard-background h-auto w-auto min-h-screen	">
      <MainNavBar />
      <Container containerClasses={"relative"}>
        {projectDetails && (
          // <Card classNames="min-w-full p-5 bg-opacity-75 rounded-3xl mb-6">
          <ProjectDetailsComponent
            data={projectDetails}
            propertyData={projectDetails.property}
          />
          // </Card>
        )}
      </Container>
    </Page>
  );
};

export default ProviderProjectDetails;
