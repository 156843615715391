import { useEffect } from "react";
import { motion } from "framer-motion";
import { fDateTime } from "utils/formatTime";
import RoofEstimator from 'components/mapbox/RoofEstimator'
// assets
import roofingIcon from "assets/images/icons/roofing.svg";
import projectIcon from "assets/images/icons/project.svg";

const Field = ({ label, value }) => (
  <div className="mt-2">
    <dt className="inline text-sm font-bold text-black-900">{label}</dt>
    <dd className="inline lg:block ml-2 text-sm text-gray-900">{value}</dd>
  </div>
);

const Card = ({ children, classNames }) => (
  <div
    className={
      "border-t-2 border-green-900 opacity-100 py-6 pt-12 overflow-y-auto w-full bg-semiWhite " +
      " flex flex-row justify-evenly	" +
      classNames
    }
  >
    {children}
  </div>
);

const CardHeader = ({ icon, label }) => (
  <div className="flex items-center justify-center lg:justify-start">
    <dt className="inline text-sm ">{icon}</dt>
    <dd className="inline ml-2 text-lg font-bold text-black-900 font-serif">
      {label}
    </dd>
  </div>
);

const ProjectDetailsStep = ({ data, propertyData, goNextPage, onSubmit }) => {
  // const [loading, setLoading] = useState(false);
  // const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();

  const getAnswer = (index) => {
    const answer = data.qna[index].answer;
    return answer;
  };


  useEffect(() => {
    localStorage.setItem("bidData", JSON.stringify({ "project_id": data.details.id }))
  })

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto md:px-8 md:pb-10 h-auto w-full`}
    >
      <div className="flex justify-center max-w-7xl m-auto">
        <div className=" lg:m-auto justify-center w-full	">
          <div className={`max-w-full flex flex-col`}>
            <h2 className="text-center pb-2 mt-0">Project Details</h2>
            <div className="flex flex-col justify-center lg:space-x-6  mb-4 lg:max-h-80 space-y-6	lg:space-y-0 ">
              {/* <Card> */}
              <Card>
                <div className="w-1/3">
                  <CardHeader
                    icon={<img src={projectIcon} alt="bla" />}
                    // label=" Project"
                    label={data.details.title}
                  />
                  <hr className="mt-5" />
                  <dl>
                    {/* <Field label="Title:" value={data.details.title} /> */}
                    {/* <hr /> */}
                    {/* <Field label="Label:" value={propertyData.label} /> */}
                    {/* <label className="inline ml-2 mt-5 text-lg font-bold text-black-900 font-serif">
                    Location
                  </label> */}
                    <Field
                      label="Location"
                      value={`${propertyData.address_title}`}
                    // value={`${propertyData.postal_code} - ${propertyData.city} - ${propertyData.country}`}
                    />
                    <Field
                      label="Bid Valid Until:"
                      value={fDateTime(data.details.bid_end_date)}
                    />
                    {/* {data.details.description && (
                      <Field
                        label="Description:"
                        value={data.details.description}
                      />
                    )}
                    {data.details.startDate && (
                      <Field
                        label="Start date:"
                        value={data.details.pref_start_date}
                      />
                    )}
                    {data.details.endDate && (
                      <Field
                        label="Completion date:"
                        value={data.details.pref_end_date}
                      />
                    )} */}
                  </dl>
                </div>
                <div className="min-h-full border-l-2 border-green-900"></div>
                <div className="w-1/3">
                  <CardHeader
                    icon={<img src={roofingIcon} alt="bla" />}
                    label=" Seeker Survey"
                  />
                  <hr className="mt-8" />
                  <dl className="mb-8">
                    <Field label="Service Name:" value={"Roofing"} />
                    {data.qna.map((question, index) => (
                      <Field
                        key={index}
                        label={question.question}
                        value={getAnswer(index)}
                      />
                    ))}
                  </dl>
                </div>
              </Card>
              <RoofEstimator
                buildingData={data.property}
                data={data}
                setRoofsInfo={() => { }}
                hideSearch={true}
              />
            </div>
            <button type="button"
              onClick={() => {
                goNextPage()
                onSubmit({ "project_id": data.details.id })
              }}
              className="btn-primary ml-1 w-1/3 mt-5 self-center">
              Bid for this Project
            </button>
            {/* <div className="block w-full flex items-center justify-center mt-10">
                <button
                  type="button"
                  className="btn btn-primary w-1/2"
                  onClick={() => handleAddInterestToProject(data.details.id)}
                >
                  {loading ? (
                    <LoadingDots />
                  ) : (
                    `Add
                  Biding Project`
                  )}
                </button>
              </div> */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectDetailsStep;
