import Card from '../../Card';
import { motion } from 'framer-motion';
import RadioInput from "../../forms/RadioInput";
import IconContainer from '../../IconContainer';

const NewProjectServicesStep = ({
  data,
  services,
  onChange,
  onPrev,
  onNext,
  bg,
  icon,
}) => {
  return (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{
      default: { duration: 0.6 },
      ease: 'easeInOut',
    }}
    
    className={
      bg +
      ` mx-auto  sm:pt-5 lg:pt-0 md:px-8 bg-no-repeat	bg-cover bg-center md:pb-10 overflow-y-auto lg:overflow-hidden`
    }
  >
    <div className="flex justify-center h-screen">
      <div className="max-w-7xl	 md:m-auto grid grid-cols-1 items-baseline md:items-center gap-0 md:gap-10 lg:gap-28 lg:grid-cols-2 justify-center">
        <Card className="p-9 md:p-7">
          <h3 className="text-2xl text-center md:text-left">
            Which service are you looking for?
          </h3>
          <div className="flex justify-center md:justify-start ">
            <hr className="w-2/4 h-px bg-black-900" />
          </div>
          <div className={`max-w-full`}>
            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-4 mt-6">
                  <RadioInput
                    value={data.service}
                    options={services}
                    onChange={(value) => onChange({ service: value })}
                  />
                  <div className="flex justify-end pt-8">
                    <button
                      className="btn-outline mr-1"
                      type="button"
                      onClick={onPrev}
                    >
                      Back
                    </button>
                    <button
                      disabled={!data.service}
                      className="btn-primary ml-1"
                      type="button"
                      onClick={onNext}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <IconContainer>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              default: { duration: 1.5 },
            }}
            src={icon}
            alt="Daisy Logo"
            className="max-w-xs w-full lg:filter lg:drop-shadow-xl"
          />
        </IconContainer>
      </div>
    </div>
  </motion.div>
)};

export default NewProjectServicesStep;
