import { motion } from "framer-motion";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Card from '../../Card';
import IconContainer from '../../IconContainer';
import { phoneRegExp } from "../../../validations/inputs";
import contact_step from '../../../assets/images/icons/contact_step_ic.svg';

const validationSchema = Yup.object({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required().matches(phoneRegExp),
});

const NewProjectContactStep = ({ data, onChange, onNext, onPrev }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto sm:pt-5 lg:pt-0 md:px-8   bg-no-repeat	bg-cover	bg-center   md:pb-10
                  bg-contact_step   overflow-y-auto lg:overflow-hidden
                  min-h-900 relative
                  `}
    >
      <div className="flex justify-center  h-full md:h-screen absolute bottom-0 md:relative">
        <div className="max-w-7xl	 md:m-auto grid grid-cols-1 items-baseline md:items-center gap-0 md:gap-10 md:gap-10 lg:gap-28 lg:grid-cols-2 justify-center">
          <Card>
            <h3 className="text-2xl text-center md:text-left">
              Who will be the main contact person?
            </h3>
            <div className="flex justify-center md:justify-start ">
              <hr className="w-2/4 h-px bg-black-900" />
            </div>
            <div className={`max-w-full`}>
              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    validationSchema={validationSchema}
                    enableReinitialize
                    initialValues={{
                      first_name: data.first_name || "",
                      last_name: data.last_name || "",
                      email: data.email || "",
                      phone: data.phone || "",
                    }}
                    onSubmit={(values) => {
                      onChange(values);
                      onNext();
                    }}
                  >
                    {({ errors, isValid }) => (
                      <Form className="space-y-4 mt-6">
                        <div className="grid grid-cols-6 gap-2 mt-5">
                          <div className="col-span-6 sm:col-span-3">
                            <Field
                              className={`text-input ${
                                errors.first_name
                                  ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                                  : ""
                              }`}
                              required={true}
                              name="first_name"
                              placeholder="First name"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <Field
                              className={`text-input ${
                                errors.last_name
                                  ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                                  : ""
                              }`}
                              name="last_name"
                              required={true}
                              placeholder="Last name"
                            />
                          </div>
                        </div>
                        <div className="space-y-2 mt-2">
                          <div className="mt-1">
                            <Field
                              className={`text-input ${
                                errors.email
                                  ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                                  : ""
                              }`}
                              type="email"
                              required={true}
                              name="email"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="space-y-2 mt-2">
                          <div className="mt-1">
                            <Field
                              className={`text-input ${
                                errors.phone
                                  ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                                  : ""
                              }`}
                              type="phone"
                              name="phone"
                              placeholder="Phone number"
                              required={true}
                            />
                          </div>
                        </div>

                        <div className="flex justify-end pt-8">
                          <button
                            className="btn-outline mr-1"
                            type="button"
                            onClick={onPrev}
                          >
                            Back
                          </button>
                          <button
                            disabled={!isValid}
                            className="btn-primary ml-1"
                            type="submit"
                          >
                            Review
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Card>

          <IconContainer>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                default: { duration: 1.5 },
              }}
              src={contact_step}
              alt="Daisy Logo"
              className="max-w-none	md:max-w-xs min-w-sm lg:filter lg:drop-shadow-xl"
            />
          </IconContainer>
        </div>
      </div>
    </motion.div>
  );
};

export default NewProjectContactStep;
