import { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useSnackbar } from "notistack";
// components
import SubmitButton from "../../components/SubmitButton";
import GoogleButton from "../../components/GoogleButton";
// hooks
import { useAuth } from "../../contexts/auth";
// api
import { googleLogin, emailLogin } from "../../network/api";
// forms
import { emailValidationSchema } from "../../validations/inputs";
// assets
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import axios from "axios";

const validationSchema = emailValidationSchema;

const LoginForm = ({ onEmailSuccess }) => {
  const history = useNavigate();

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [rememberToken, setRememberToken] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationError] = useState(false);
  const [googleError, setGoogleError] = useState(false);

  const onGoogleLoginSuccess = async (response) => {
    const data = await axios.get(('https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + response.access_token)).then((response) => {
      return response.data
    })
    const { email } = data;
    const { status, token, error } = await googleLogin(email, data.sub);

    setLoading(false);

    if (status) {
      auth.dispatch({
        type: "LOGIN",
        payload: {
          token: token,
          rememberToken
        },
      })
      // history.push({ pathname: location.state?.redirectTo || "/profile" });
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (googleError) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
      });
      setGoogleError(false);
    }
  }, [googleError, enqueueSnackbar]);


  const onEmailLogin = async ({ email }) => {
    setLoading(true);

    const { status, error } = await emailLogin(email);

    setLoading(false);

    if (status) {
      auth.dispatch({
        type: "SET_USER",
        payload: {
          user: {
            email,
          },
        },
      });

      onEmailSuccess();
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        email: "",
      }}
      onSubmit={(values) => {
        onEmailLogin(values);
      }}
    >
      {({ errors, isValid, dirty }) => (
        <Form className="space-y-4 mt-6">
          <div className="space-y-1">
            <div className="mt-1">
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="mb-3 xxl:mb-6">
                  <div>
                    <div className="mt-1 grid">
                      <GoogleButton
                        onSuccess={onGoogleLoginSuccess}
                        onFailure={() => setGoogleError(true)}
                      />
                    </div>
                  </div>

                  <div className="mt-3 xxl:mt-6 relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>

                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-yellow-50 text-gray-500">
                        Or continue with
                      </span>
                    </div>
                  </div>
                </div>

                <Field
                  className={`text-input ${errors.email
                    ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                    : ""
                    }`}
                  type="email"
                  name="email"
                  placeholder="Email"
                />

                {validationError && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center py-1 xxl:py-4">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="checkbox-input"
                checked={rememberToken}
                onChange={(e) => setRememberToken(e.target.checked)}
              />

              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>
          </div>

          <div className="pt-1 xxl:pt-4">
            <SubmitButton
              loading={loading}
              buttonText="Login"
              disabled={!(isValid && dirty)}
            />
          </div>

          <div className="flex items-center justify-center py-1 xxl:py-4">
            <h5 className="">Don&apos;t have an account?</h5>
            &nbsp;
            <NavLink to={`/register/seeker`} className="underline">Sign Up</NavLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
