import { createContext, useContext, useState, useMemo } from "react";

const ProjectContext = createContext();

export const useProjectData = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const [propertyData, setPropertyData] = useState({});
  const [projectData, setProjectData] = useState({ answers: {} });
  const [currentStep, setCurrentStep] = useState(0);
  const [shouldReset, setShouldReset] = useState(0);


  const resetProjectValues = () =>{
    setPropertyData({});
    setProjectData({ answers: {} })
    setCurrentStep(0)
    setShouldReset((value)=>value+1)
  }


  const value = useMemo(
    () => ({
      propertyData,
      setPropertyData,
      projectData,
      setProjectData,
      currentStep,
      setCurrentStep,
      shouldReset,
      resetProjectValues
    }),
    [projectData, propertyData, currentStep]
  );

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};
