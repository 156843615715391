import FAQ from './FAQ';
import Page from 'components/Page';
import Footer from 'components/layout/Footer';
import HomeNavbar from 'components/layout/HomeNavbar';

export default function AboutUs() {
  return (
    <Page classNames="h-auto w-auto min-h-screen">
      <HomeNavbar />
      <FAQ />
      <Footer />
    </Page>
  )
}