// import { motion } from "framer-motion";
import Page from "../../components/Page";
import Container from "../../components/Container";
import HomeNavbar from "../../components/layout/HomeNavbar";

export const CookiePolicy = () => {
  return (
    <Page>
      <HomeNavbar />
      <Container />
    </Page>
  );
};
