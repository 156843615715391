import { useState } from "react";
import classNames from "classnames";
// form utils
import SubmitButton from "./SubmitButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { emailValidationSchema } from "validations/inputs";
// api
import { userChangeEmail } from "network/api";

export default function ChangeEmailForm({ onClose, onSuccess, openAlert }) {
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={emailValidationSchema}
      onSubmit={async (values) => {
        setLoading(true);
        const { status, error } = await userChangeEmail(values.email);
        setLoading(false);
        if (status) {
          openAlert({
            variant: "success",
            message: "OTP sent successfully check your email",
          });
          onSuccess(values.email);
        } else {
          openAlert({
            variant: "error",
            message: error,
          });
        }
      }}
    >
      {({ errors, isValid, dirty }) => {
        return (
          <Form>
            <div className="py-4 sm:py-5">
              <label
                className="block text-md font-medium text-black-900 mb-1 text-left"
                htmlFor="label"
              >
                Email
              </label>
              <Field
                data-testid="email-input"
                type="email"
                className={classNames("text-input", {
                  "border-red-600 focus:ring-red-600 focus:border-red-600":
                    errors.email,
                })}
                placeholder="Email"
                name="email"
              />
              <div className="flex justify-between items-center">
                <div className="flex-auto w-2/5 mr-2">
                  {errors.email && (
                    <div
                      className="mt-2 text-sm text-red-600 text-left"
                      data-testid="email-error"
                    >
                      <ErrorMessage name="email" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="py-4 sm:py-5">
              <div className="flex justify-between items-center">
                <div className="flex-auto w-1/2 mr-2">
                  <SubmitButton
                    disabled={!(isValid && dirty)}
                    loading={loading}
                    buttonText="Send OTP"
                    data-testid="submit-btn"
                  />
                </div>
                <div className="flex-auto w-1/2 mr-2">
                  <button
                    type="reset"
                    className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm mr-2  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-full shadow-lg justify-center py-2 px-4 border
                  "
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
