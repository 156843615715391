/* eslint-disable-next-line jsx-a11y/label-has-associated-control */
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: 'sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#303238',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

function CardSection() {
  return (
      <label className="ml-4" htmlFor="myinput">
        Card details
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </label>
  );
}

export default CardSection;
