import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CheckboxGroup({ options, values, onChange, previewsValue }) {
  const [selected, setSelected] = useState(values);
    if(options && options[0] &&  !options[0].name && options[0].split('@')[1]){
      let result = [];
        options.forEach(element => {
          let name = element.split('@')[0].split('_')[1];
          if(element.split('@')[0].startsWith(previewsValue.toLowerCase())){
            result.push(name)
          }
        });
        if(result.length !== 0){
          options = result;
        }
        else{
          options = options.map((element)=> element.split('@')[0].split('_')[1])
        }
    }
     
  return (
    <div>
      {options.map((option, optionIdx) => (
        <div
          key={optionIdx}
          className={classNames(
            optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
            optionIdx === options.length - 1
              ? "rounded-bl-md rounded-br-md"
              : "",
            selected.includes(option) ? "bg-green-50 border-green-200" : "",
            "bg-white border flex flex-col"
          )}
        >
          <label className="cursor-pointer p-4 md:pl-4 md:pr-6">
            <input
              type="checkbox"
              name={option}
              className="checkbox-input"
              checked={selected.includes(option)}
              onChange={({ target: { name, checked } }) => {
                // const newAnswers = values;
                const newAnswers = [...new Set(selected)];
                if (checked) {
                  newAnswers.push(name);
                } else {
                  const answerIndex = newAnswers.findIndex(
                    (answer) => answer === option
                  );
                  newAnswers.splice(answerIndex, 1);
                }
                onChange(newAnswers);
                setSelected(newAnswers);
              }}
            />
            <span className="ml-3 font-medium">{option}</span>
          </label>
        </div>
      ))}
    </div>
  );
}
