export const buildRequestForNewProperty = (data) => {
  const { addressTitle, lat, lng, placeId, country, postalCode, label, city } = data;
  return {
    address_title: addressTitle,
    lat,
    lng,
    place_id: placeId,
    city: city || "",
    country,
    postal_code: postalCode || "",
    label,
  };
};
// A function to rearange date and time to specific dateTime YYYY:MM:DD HH:MM:SS
export const buildDateForNewProject = (fullDate) => {
  const dateStr =
    fullDate.getFullYear() +
    "-" +
    ("00" + fullDate.getDate()).slice(-2) +
    "-" +
    ("00" + (fullDate.getMonth() + 1)).slice(-2) +
    " " +
    ("00" + fullDate.getHours()).slice(-2) +
    ":" +
    ("00" + fullDate.getMinutes()).slice(-2) +
    ":" +
    ("00" + fullDate.getSeconds()).slice(-2);
  return dateStr;
};

export const buildRequestForNewProject = (propertyId, data) => {
  const {
    title,
    service,
    startDate,
    endDate,
    answers,
    description,
    appointment_datetime,
    first_name,
    last_name,
    bid_days,
    email,
    phone,
    custom_data,
    material_id
  } = data;
  const answersObj = { ...answers };
  Object.keys(answersObj).forEach((key) => {
    if (Array.isArray(answersObj[key])) {
      answersObj[key] = JSON.stringify(answersObj[key]);
    }
  });
  return {
    title,
    description,
    appointment_datetime: appointment_datetime,
    service_id: service.id,
    custom_data: JSON.stringify(custom_data),
    property_id: propertyId,
    pref_start_date: startDate,
    pref_end_date: endDate,
    answers: answersObj,
    // material_id: 1,
    bid_days: bid_days,
    contact_details: {
      first_name: first_name,
      last_name: last_name,
      email,
      phone: `+${phone}`,
    },
    material_id
  };
};
