import LocationIcon from "assets/images/icons/home/ic_choose_location.svg";
import ServiceIcon from "assets/images/icons/home/ic_choose_service.svg";
import ProviderIcon from "assets/images/icons/home/ic_choose_provider.svg";
import { NavLink } from "react-router-dom";

const DATA = [
    { icon: LocationIcon, text: "Locate your property address" },
    { icon: ServiceIcon, text: "Get your roof area estimation" },
    { icon: ProviderIcon, text: "Pick a service provider to start your project" },
];

export default function Features() {
    return (
        <div id="services" className="relative bg-home-provider-section bg-cover flex flex-col justify-center items-center py-10 md:py-24">
            <div className='bg-black-100 bg-opacity-40 h-full w-full absolute top-0' />
            <div className="title mt-5 text-center z-50">
                <h1 className="text-4xl text-bold text-black-900 mb-2 border-b-2 border-black-900">
                    Start Your Project
                </h1>
            </div>
            <div className="relative z-50 mx-auto w-3/4">
                <div className="my-5 text-center flex flex-col lg:flex-row justify-center items-center mt-24 gap-y-24 lg:gap-y-1 gap-x-8">
                    {DATA.map((item, index) => (
                        <div
                            key={index}
                            className="bg-yellow-100 border-green-900 bg-opacity-7 h-48 lg:h-48 border-2 shadow-3xl max-w-lg 
                        rounded-3xl p-8 relative space-y-6 w-full lg:w-1/3 flex justify-center items-center"
                        >
                            <div
                                className="absolute top-0"
                                style={{ left: "50%", top: "-40px" }}
                            >
                                <img
                                    src={item.icon}
                                    alt="Location icon"
                                    width="120"
                                    height="50"
                                    className="rounded-full bg-yellow-100 border-solid border-4 border-green-900 mx-auto relative shadow-3xl"
                                    style={{ left: "-50%" }}
                                />
                            </div>
                            <h3
                                className="text-3xl font-bold text-green-900 pt-4"
                                style={{ fontSize: "1.2rem" }}
                            >
                                {item.text}
                            </h3>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center items-center mt-10 text-center">
                    <NavLink to="/register/seeker" className="text-lg shadow-3xl hover:shadow-none border-2 border-green-900 bg-yellow-200 text-green-900 hover:text-yellow-200 hover:bg-green-800 px-4 py-2 my-4 rounded-md">
                        Register as Service Seeker
                    </NavLink>
                </div>
            </div>
        </div>
    );
};
