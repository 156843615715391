import { useEffect, useState } from 'react';

export default function About() {
    const [videoLoaded, setVideoLoaded] = useState(false);
    // const videoRef = useRef(null);

    useEffect(() => {
        // Scroll event listener to check if video section is in view
        const handleScroll = () => {
            const videoSection = document.getElementById('about');
            if (videoSection && !videoLoaded) {
                const rect = videoSection.getBoundingClientRect();
                if (rect.top < window.innerHeight && rect.bottom >= 0) {
                    setVideoLoaded(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [videoLoaded]);

    return (
        <section className="bg-semiWhite pb-20 md:py-24 relative overflow-x-hidden border-t-2 border-b-2 border-green-900" id="about">
            <div className="container mx-auto px-10 flex flex-wrap justify-center lg:justify-between items-center relative z-10">
                <div className="w-full lg:w-1/2 p-4">
                    <h2 className="text-center sm:text-left text-3xl mb-4 font-bold text-gray-900">About Us</h2>
                    <p className="text-gray-600 mb-8 leading-6 text-lg text-justify">
                        Try Daisy connects homeowners with reliable roofing providers and
                        simplifies the search for trusted roofing contractors. Homeowners can
                        easily estimate the size of roofing jobs and receive multiple bids from
                        different providers without having their information sold to roofing
                        companies. Roofing providers benefit from our platform as we send job
                        opportunities directly to their phones or email free of charge. Our team is
                        dedicated to providing a user-friendly experience for both homeowners
                        and roofing providers, making the process of roof repair hassle-free.
                    </p>
                </div>
                <div className="w-full lg:w-1/2 z-10" style={{ width: '450px' }}>
                    <div className="w-full relative overflow-hidden rounded-lg shadow-lg z-10">
                        {/* {!videoLoaded && <div className="preloader">Loading...</div>} */}
                        {/* Use Element from react-scroll for the video section */}
                        {/* <Element name="about"> */}
                        {/* Render the video element */}
                        <video
                            // ref={videoRef}
                            autoPlay
                            muted
                            loop
                            controls
                            preload
                            // preload='auto'
                            // onLoadedData={() => setVideoLoaded(true)}
                            height={'300'}
                            width={'500'}
                        >
                            <track kind="captions" />
                            <source
                                id="mp4"
                                src="/assets/intro.mp4"
                                type="video/mp4"
                            />
                        </video>
                        {/* </Element> */}
                        {/* <iframe width="100%" height="315" src="https://www.youtube.com/embed/8_c_FA6zF98?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                    </div>
                </div>
            </div>
            <div className="absolute rounded-full bg-yellow-800 w-72 h-72 md:w-96 md:h-96 right-0 bottom-0 md:bottom-10 z-0" style={{ transform: 'translateX(20%)' }}></div>
        </section>
    );
};

