import { twMerge } from "tailwind-merge";

const Card = ({ children, classNames, ...props }) => (
    <div
        className={twMerge('relative opacity-100 p-4 rounded-2xl shadow-lg overflow-y-auto w-full bg-semiWhite hover:bg-light hover:shadow-none hover:cursor-pointer ', classNames)}
        {...props}
    >
        {children}
    </div>
);

export default Card;