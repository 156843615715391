import PropTypes from "prop-types";
import { useAuth } from "../contexts/auth";
import { Navigate } from "react-router-dom";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.string,
  children: PropTypes.node,
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { state } = useAuth();
  const role = state?.user?.role;

  if (role && !accessibleRoles.includes(role)) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}
