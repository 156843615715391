import { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Table, Typography, Tooltip, Popconfirm, Button } from 'antd';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    required,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required,
                            message: `Please fill ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const MaterialsTable = ({ onSave, dataSource, onEdit, rowEdit, onDelete, editMode = true }) => {
    const [form] = Form.useForm();
    // const [count, setCount] = useState(0);
    const [data, setData] = useState(dataSource !== undefined ? dataSource : []);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        setData(dataSource !== undefined ? dataSource : []);
    }, [dataSource]);

    const isEditing = (record) => record.key === editingKey;

    const deleteRecord = (record) => {
        setData(data.filter((item) => item.key !== record.key));
        onDelete(record);
    }

    const edit = (record) => {
        form.setFieldsValue({
            name: ``,
            quantity: 0,
            unit_price: 0,
            description: ``,
            reason: ``,
            ...record,
        });
        setEditingKey(record.key);
        onEdit(record);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey('');
                onSave(newData);
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            required: true,
        },
        {
            title: "Sub Category",
            dataIndex: "subcategory",
            editable: false,
            required: true,
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            editable: rowEdit ? true : false,
            required: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            editable: rowEdit ? true : false,
            required: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            // width: '25%',
            // colSpan: rowEdit ? 4 : null,
            editable: rowEdit ? true : false,
            ellipsis: {
                showTitle: false,
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            editable: rowEdit ? true : false,
            ellipsis: {
                showTitle: false,
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
            // ellipsis: true,
        },
        ...(editMode ? [
            {
                title: 'Action',
                dataIndex: 'operation',
                className: editMode ? "show" : "hide",
                render: (_, record) => {
                    if (rowEdit) {
                        const editable = isEditing(record);
                        return editable ? (
                            <span>
                                <Typography.Link
                                    onClick={() => save(record.key)}
                                    style={{
                                        marginRight: 8,
                                        color: "#638b8d",
                                    }}
                                >
                                    Save
                                </Typography.Link>
                                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                    Cancel
                                </Popconfirm>
                            </span >
                        ) : (
                            <div className='flex flex-row justify-center'>
                                <Button disabled={editingKey !== ''} onClick={() => edit(record)}>Edit</Button>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <Popconfirm title="are you sure?" onConfirm={() => deleteRecord(record)}>
                                    <Button type='danger' disabled={editingKey !== ''}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        )
                    } else {
                        setEditingKey('');
                        return (
                            <div className='flex flex-row justify-center'>
                                <Button disabled={editingKey !== ''} onClick={() => edit(record)}>Edit</Button>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <Popconfirm title="are you sure?" onConfirm={() => deleteRecord(record)}>
                                    <Button type='danger' disabled={editingKey !== ''}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        );
                    }
                },
            },
        ] : [])
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'quantity' || col.dataIndex === 'unit_price' ? 'number' : 'textarea',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                required: col.required,
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
        </Form>
    );
};

export default MaterialsTable;