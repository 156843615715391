import { useState, useEffect } from "react";
import { filter } from "lodash";
import { Table, Space, Button, Popconfirm } from "antd";
import {DeleteDocument} from 'network/providerAPI'

export default function DocumentsTable({ documents, types, deleteDoc, countries }) {
  const [docs, setDocs] = useState(documents);

  const deleteDocument = (key, doc) => {
    DeleteDocument({"document_id": key})
    .then((data) => {
      setDocs(filter(documents, (d) => d.id !== key));
      deleteDoc(filter(documents, (d) => d.id !== key));
    })
    .catch();
  };

  useEffect(() => {
    setDocs(documents.map((doc, index) => ({ ...doc, key: index })));
  }, [documents])

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <Space size="small">{text}</Space>,
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Country",
      dataIndex: "country_name",
      key: "country_name",
      render: (text, record) => <Space size="small">USA</Space>,
    },
    {
      title: "State",
      dataIndex: "issue_state_id",
      key: "issue_state_id",
      render: (text, record) => (
        <Space size="small">
          {countries.filter((c) => c.id === text)[0].name}
        </Space>
      ),
    },
    {
      title: "Document Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Document",
      key: "url",
      dataIndex: "url",
      render: (text, record) => (
        <Space size="small">
          <a href={text} target="_blank" rel="noreferrer">
            File URL
          </a>
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm title="are you sure?" onConfirm={() => deleteDocument(record.id, record)}>
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      className="max-w-xs	md:max-w-full"
      columns={columns}
      dataSource={docs}
      pagination={false}
    />
  );
}
