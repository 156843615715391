import { format, getTime, formatDistanceToNow, parse } from 'date-fns';
// ----------------------------------------------------------------------

export function fDate(date) {
    return format(new Date(date), 'dd MMM yyyy');
}

export function fDateTime(date) {
    if (!date) {
        return '';
    }
    // return format(new Date(date), 'dd MMM yyyy p');
    return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd MMM yyyy p');
}

export function fTimestamp(date) {
    return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}