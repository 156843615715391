import { Tab } from "@headlessui/react";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { findIndex } from 'lodash'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ categories, onTabChange, rootPath }) => {
  const location = useLocation();

  if (rootPath !== '') rootPath = `/${rootPath}`

  const Index = findIndex(Object.values(categories), (cat) => `${rootPath}/${cat[0].path}`.includes(location.pathname))

  const [selectedIndex, setSelectedIndex] = useState(Index === -1 ? 0 : Index)

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={(selectedTabIndex) => {
        setSelectedIndex(selectedTabIndex);
        onTabChange(selectedTabIndex);
      }}
    >
      <Tab.List className="flex justify-center items-center p-1 rounded-xl">
        {Object.keys(categories).map((category, idx) => (
          <Tab
            key={idx}
            className={({ selected }) =>
              classNames(
                "border-2 border-green-900",
                "w-1/2 lg:w-1/4 py-2.5 text-sm leading-5 font-medium text-green-700 ",
                "focus:outline-none focus:ring-1 ring-offset-1 ring-offset-green-700 ring-white ring-opacity-60 truncate ",
                selected
                  ? "bg-green-700 shadow p-1 whiteText"
                  : " text-green-100 hover:bg-white/[0.12] hover:text-green-900",
                idx === 0 ? "rounded-l-lg" : "rounded-l-none",
                idx === Object.keys(categories).length - 1 ? "rounded-r-lg" : "rounded-r-none"
              )
            }
          >
            {category}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="mt-2">
        {Object.values(categories).map((posts, idx) => (
          <Tab.Panel
            key={idx}
            className={classNames(
              "rounded-xl p-3",
              "focus:outline-none ring-white ring-opacity-60",
              "h-5/6	"
            )}
          >
            <Outlet />
            {/* {posts.map((post) => post.component)} */}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;
