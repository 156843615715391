import { useState } from "react";
import OtpInput from "react-otp-input";
// components
import SubmitButton from "./SubmitButton";
// api
import { userVerifyChangeEmail } from "network/api";

export default function EmailVerificationForm({
  onClose,
  onSuccess,
  openAlert,
  Email,
}) {
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState("");
  const onVerifyClicked = async () => {
    setLoading(true);
    const { status, error } = await userVerifyChangeEmail(
      Email,
      otp
    );
    setLoading(false);
    if (status) {
      openAlert({
        variant: "success",
        message: "Email Changed successfully",
      });
      onSuccess(Email);
    } else {
      openAlert({
        variant: "error",
        message: error,
      });
    }
  };

  return (
    <div className="mt-6">
      <p className="block text-sm font-medium text-black-900 mb-1 text-center mt-4">
        Enter the code we sent to {Email}
      </p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onVerifyClicked();
          setLoading(true);
        }}
        className="space-y-2 mt-6"
      >
        <div className="space-y-1">
          <div className="mt-1 flex flex-col">
            <div className="mt-1 relative rounded-md m-auto">
              <OtpInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                separator={<span className="px-1"></span>}
                inputStyle={"text-input text-black-900 min-w-40 py-4 text-xl"}
                shouldAutoFocus
                isInputNum
                autoComplete="one-time-code"
              />
            </div>
          </div>
        </div>
        <div className="py-4 sm:py-5">
          <div className="flex justify-between items-center">
            <div className="flex-auto w-1/2 mr-2">
              <SubmitButton
                disabled={otp.length < 6}
                loading={loading}
                buttonText="Verify"
              />
            </div>
            <div className="flex-auto w-1/2 mr-2">
              <button
                type="reset"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-full shadow-lg justify-center py-2 px-4 border
                  "
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
