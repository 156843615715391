import { useState } from "react";

// components
import SubmitButton from "../SubmitButton";
import AutoComplete from "react-google-autocomplete";
import axios from "axios";
import { debounce } from "lodash";
// hooks
import { useAuth } from "contexts/auth";

// forms
import * as Yup from "yup";
import { Form, Field, useFormik, FormikProvider } from "formik";

// assets
import { defaultProviderImage } from "constants/defaultProfileImage";

const validationSchema = Yup.object({
  label: Yup.string().required(),
  mainAddress: Yup.string().min(2).required(),
  company_name: Yup.string()
    .max(64)
    .min(3)
    .required()
    .when([], {
      is: (val) => !val,
      then: Yup.string().required('Company name is a required field'),
    })
    .when([], {
      is: (val) => val && val.length < 3,
      then: Yup.string().min(3, 'Company name should be more than 3 characters'),
    }),
  personal_name: Yup.string().max(64).min(3).required(),
});

export const ProviderRegistrationForm = ({ onSuccess }) => {
  // const inputFile = useRef(null);

  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({});
  const [isCompanyNameTaken, setIsCompanyNameTaken] = useState(null);

  const checkCompanyName = debounce((companyName) => {
    axios
      .get(`/user/register/check-company-name?companyName=${companyName}`)
      .then((response) => {
        setIsCompanyNameTaken(response.data.isNameTaken);
        if (response.data.isNameTaken) {
          formik.setFieldError('company_name', 'Company name is already taken');
        } else {
          formik.setFieldError('company_name', ''); // Clear the error if there is one
        }
      })
      .catch((error) => {
        // Handle error
      });
  }, 300);

  const formik = useFormik({
    initialValues: {
      label: "",
      mainAddress: "",
      company_name: "",
      personal_name: auth.user?.name,
      profilePicture: auth.user?.logo_url,
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      const { company_name, personal_name } = values;

      setLoading(true);

      const req = {
        email: auth.user?.email,
        company_name: company_name,
        personal_name: personal_name,
        reg_type: auth.user.ext_id ? "email" : "gmail",
        ext_id: auth.user?.ext_id,
        address: { label: values.label, ...address },
      };

      auth.dispatch({
        type: "SET_USER",
        payload: {
          user: {
            ...auth.user,
            ...req,
          },
        },
      });
      onSuccess();
    },
  });
  // const onChangeClick = () => inputFile.current.click();
  const { errors, values, setFieldValue, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-4 mt-6">
        <div className="space-y-1">
          <div className="mt-1">
            <div className="space-y-2 mt-2 flex items-center flex-col relative">
              <span className=" " style={{ width: "20%" }}>
                <span className="relative inline-block w-full">
                  <div className="rounded-full  overflow-hidden bg-gray-100 w-full h-full">
                    <img
                      className="h-20 w-30 text-green-500 object-contain"
                      alt="profilePicture"
                      src={values.profilePicture || defaultProviderImage}
                    />
                  </div>
                  {/* <input
                    type="file"
                    id="file"
                    onChange={(event) => {
                    }}
                    ref={inputFile}
                    style={{ display: "none" }}
                    accept="image/*"
                  /> */}
                  {/* <button
                    onClick={onChangeClick}
                    type="button"
                    className="ml-5 bg-green-900 py-1 px-2 border-8 border-white-900 shadow-sm text-xs sm:text-sm leading-4 font-small text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900 absolute bottom-0 right-0 rounded-full"
                  > */}
                  {/* <PencilIcon
                      className="-ml-1  h-1 w-1 sm:h-5 sm:w-5"
                      aria-hidden="true"
                    /> */}
                  {/* </button> */}
                </span>
              </span>
            </div>
            <div className="grid grid-cols-6 gap-2 mt-5">
              <div className="col-span-6 sm:col-span-3">
              <Field
                className={`text-input ${errors.company_name || isCompanyNameTaken
                  ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                  : ""
                  }`}
                name="company_name"
                onChange={(e) => {
                  formik.handleChange(e); // Handle normal Formik changes
                  checkCompanyName(e.target.value); // Then check company name
                }}
                placeholder="Company name"
              />
              {errors.company_name && <div className="text-red-600">{errors.company_name}</div>}
              </div>

              <div className="col-span-6 sm:col-span-3">
                <Field
                  className={`text-input ${errors.personal_name
                    ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                    : ""
                    }`}
                  name="personal_name"
                  placeholder="Personal name"
                />
              </div>
            </div>

            <div className="space-y-2 mt-2">
              <div className="mt-1">
                <input
                  className="text-input font-bold bg-gray-200 opacity-70 cursor-not-allowed"
                  type="email"
                  name="email"
                  readOnly
                  placeholder="Email"
                  value={auth.user?.email}
                />
              </div>
            </div>
            <div className="space-y-2 mt-4">
              <label
                className="block text-sm font-medium text-black-900 mb-1"
                htmlFor="label"
              >
                Main Address
              </label>
              <AutoComplete
                // apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY}
                apiKey="AIzaSyCYb4ZrW-tE-JlKVoE84r0606U9zS2gcmA"
                className={`text-input ${errors.mainAddress
                  ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                  : ""
                  }`}
                options={{ types: ["address"] }}
                onPlaceSelected={(place) => {
                  if (place.address_components) {
                    setFieldValue("mainAddress", place.formatted_address);
                    const address_obj = place.address_components.reduce(
                      (seed, { long_name, types }) => {
                        types.forEach((t) => {
                          seed[t] = long_name;
                        });
                        return seed;
                      },
                      {}
                    );
                    setAddress({
                      is_main: true,
                      address_title: place.formatted_address,
                      country: address_obj.country,
                      city: address_obj.locality || address_obj.sublocality,
                      postal_code: address_obj.postal_code || "",
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                      place_id: place.place_id,
                      address_details: "",
                    });
                  }
                }}
                name="mainAddress"
                defaultValue={""}
              />
            </div>
            <div className="mt-4">
              <Field
                className={`text-input ${errors.label
                  ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                  : ""
                  }`}
                name="label"
                placeholder="Label: ex. Home"
              />
            </div>
          </div>

          <div className="pt-4">
            <SubmitButton
              disabled={!(isValid && dirty && !isCompanyNameTaken)}
              loading={loading}
              buttonText="Create Account"
            />
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
