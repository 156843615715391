import { useEffect, useState } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import areaCalculator from '@turf/area';
import Geocoder from 'react-mapbox-gl-geocoder';

import './style.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYWhtYWRhdHJhY2giLCJhIjoiY2t0dWVzYjB4MDl3NjJxbW1rMW94YTBrNCJ9.iyDoprf-pgtsNnDKFUDgVw',
  interactive: false,

});

const mapAccess = {
  mapboxApiAccessToken:
    'pk.eyJ1IjoiYWhtYWRhdHJhY2giLCJhIjoiY2t0dWVzYjB4MDl3NjJxbW1rMW94YTBrNCJ9.iyDoprf-pgtsNnDKFUDgVw',
};

const RoofEstimatorNotInteractive = ({ setRoofsInfo, data, buildingData, hideSearch = false }) => {
  const [center, setCenter] = useState([buildingData.lng, buildingData.lat]);
  const [displayMap, setDisplayMap] = useState(true);
  const [roofs, setRoofs] = useState([]);

  const onSelected = (_, item) => {
    setCenter(item.center);
    setDisplayMap(true);
  };

  useEffect(() => {
    if (roofs?.length > 0) {
      setRoofsInfo(roofs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roofs]);

  useEffect(() => {
    if (!hideSearch) {
      let roofInput = document.getElementsByClassName("react-geocoder");
      roofInput[0].children[0].placeholder = "put your address here";
      setRoofsInfo(data.custom_data);
    }
    else {
      let newData = JSON.parse(data.custom_data.data);
      setRoofsInfo(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // method to calculate the polygon area
  const roofAreaCalculator = (polygon) => {
    return Math.round((areaCalculator(polygon) * 100) / 0.09290304 / 100);
  };

  // method handle the creation of the polygon triggered by mapbox
  const onDrawCreate = ({ features }) => {
    const area = roofAreaCalculator(features[0].geometry);
    features[0]["properties"]["roofArea"] = Math.round(area * 1.202);
    features[0]["properties"]["initRoofArea"] = area;
    features[0]["properties"]["selected"] = 1;
    setRoofs((prevState) => {
      const newState = [...prevState, features[0]];
      return newState;
    });
  };

  // method handle the update of the polygon then getting the polygon by id and edit the roofs state in real time
  const onDrawUpdate = ({ features }) => {
    setRoofs((prevState) => {
      const area = roofAreaCalculator(features[0].geometry);
      const index = prevState.findIndex(
        (roofObject) => roofObject["id"] === features[0].id
      );
      let newRoofs = prevState;
      newRoofs[index]["properties"]["roofArea"] = Math.round(area * 1.202);
      newRoofs[index]["properties"]["initRoofArea"] = area;
      return [...newRoofs];
    });
  };

  // method handle the update of the roof angle it is getting the angle object from the clination button
  const onDrawDelete = ({ features }) => {
    setRoofs((prevState) => {
      let newRoofs = prevState;
      newRoofs = newRoofs.filter(
        (roofObject) => roofObject["id"] !== features[0].id
      );
      return [...newRoofs];
    });
  };

  // method handle the update of the roof angle it is getting the angle object from the clination button
  // const updateRoofAngle = (roof, angle, selected) => {
  //   const index = roofs.findIndex((roofObject) => roofObject["id"] == roof.id);
  //   let newRoofs = roofs;
  //   newRoofs[index]["properties"]["roofArea"] = Math.round(
  //     newRoofs[index]["properties"]["initRoofArea"] * angle
  //   );
  //   newRoofs[index]["properties"]["selected"] = selected;
  //   setRoofs([...newRoofs]);
  // };

  const onStyleLoad = (map) => {
    map._interactive = false;
    if (data?.custom_data?.length > 0) {
      var feature = {
        type: "FeatureCollection",
        features: data.custom_data,
      };

      map._controls[2].add(feature);
      setRoofs(map._controls[2].getAll().features);
    }
    if (data?.custom_data?.data?.length > 0) {
      let newData = JSON.parse(data.custom_data.data);
      let newfeature = {
        type: "FeatureCollection",
        features: newData
      };

      map._controls[2].add(newfeature);
      setRoofs(map._controls[2].getAll().features);
    }
  };

  return (
    <div className={`${!hideSearch ? "md:m-0 m-4 pb-10 w-100" : "md:m-0 w-100 "}`}>
      {!hideSearch &&
        <div className="mx-auto text-center md:w-2/5 mt-10">
          <h2 className="mb-10">Estimate Your Roof Area</h2>
          <Geocoder
            {...mapAccess}
            onSelected={onSelected}
            viewport={{}}
            initialInputValue={""}
            className='mapBoxAdressInput'
          />
        </div>
      }

      {displayMap ? (
        <div className={`${!hideSearch ? "md:w-5/5 mx-auto grid justify-items-center" : "w-100"}`}>
          <Map
            className="mt-10"
            // eslint-disable-next-line react/style-prop-object
            style="mapbox://styles/mapbox/satellite-v9"
            containerStyle={{
              width: "100%",
              height: "400px",
            }}
            center={center}
            zoom={[18]}
            preserveDrawingBuffer={true}
            onStyleLoad={onStyleLoad}
          >
            <DrawControl
              controls={{
                polygon: !hideSearch,
                point: false,
                line_string: false,
                trash: !hideSearch,
                combine_features: false,
                uncombine_features: false,
              }}
              position="bottom-left"
              onDrawCreate={onDrawCreate}
              onDrawUpdate={onDrawUpdate}
              onDrawDelete={onDrawDelete}
            />
          </Map>
        </div>
      ) : null}
    </div>
  );
};
export default RoofEstimatorNotInteractive;