import { useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'antd';
// components
import SubmitButton from "../SubmitButton";
// hooks
import { useAuth } from "contexts/auth";
// api
import { seekerRegister, seekerUploadProfilePicture } from "network/api";
// forms
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
// assets
import { PencilIcon } from "@heroicons/react/solid";
import { defaultProfileImage } from "constants/defaultProfileImage";
import { formatBytes } from "utils/formatNumber";
import Compressor from "compressorjs";

const validationSchema = Yup.object({
  first_name: Yup.string().max(32).min(3).required(),
  last_name: Yup.string().max(32).min(3).required(),
});

export const SeekerRegistrationForm = () => {
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgUrl, setImgUrl] = useState();

  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);


  async function uploadImageFromURL(imgSrc) {
    if (typeof imgSrc === 'string' && imgSrc.startsWith("http")) {
      // If it's a URL, fetch the image
      try {
        const response = await fetch(imgSrc);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        return new File([blob], "filename.jpg", { type: "image/jpg" });
      } catch (error) {
        console.error('There was a problem with the request:', error);
        return null; // or handle error as appropriate
      }
    } else {
      // If it's a file object from the local system
      return imgSrc;
    }
  }

  const onContinueClicked = async ({ first_name, last_name }) => {
    setLoading(true);

    const req = {
      email: auth.user?.email,
      first_name: first_name,
      last_name: last_name,
      reg_type: auth.user.ext_id ? "gmail" : "email",
      ext_id: auth.user?.ext_id || "",
    };

    const { status, token, error, user } = await seekerRegister(req);


    if (status) {
      auth.dispatch({
        type: "LOGIN",
        payload: {
          token,
          user: { ...user }
        },
      });
      var file = await uploadImageFromURL(imgSrc || auth.user?.profile_pic);
      var form = new FormData();
      if (file && 'size' in file && formatBytes(file.size) > 2) {
        new Compressor(file, {
          quality: parseFloat((5 / formatBytes(file.size)).toFixed(1)),
          success(result) {
            form.append("image", result, result.name);
            seekerUploadProfilePicture(form);
          },
          error(err) {
          },
        });
      } else {
        if (file) {
          form.append("image", file);
          seekerUploadProfilePicture(form, token);
        }
      }
      if (file) {
        auth.dispatch({
          type: "LOGIN",
          payload: {
            profile_pic: URL.createObjectURL(file)
          },
        });
      }
    } else {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
    setLoading(false);
    window.location.reload();
  };

  const onChangeClick = () => inputFile.current.click();

  const changePic = (event) => {
    console.log("hello");
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file) return;
    setImgSrc(file);
    setImgUrl(URL.createObjectURL(file));
  }
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        first_name: auth.user?.first_name,
        last_name: auth.user?.last_name,
        profilePicture: auth.user?.profile_pic,
      }}
      onSubmit={(values) => {
        onContinueClicked(values);
      }}
    >
      {({ errors, isValid, values }) => (
        <Form className="space-y-4 mt-6">
          <div className="space-y-1">
            <div className="mt-1">
              <div className="space-y-2 mt-2 flex items-center flex-col relative">
                <span className=" " style={{ width: "20%" }}>
                  <span className="relative inline-block w-full">
                    <div className="rounded-full  overflow-hidden bg-gray-100 w-full h-full">
                      <img
                        className="h-20 w-30 text-green-500 object-contain	"
                        alt="profilePicture"
                        src={(imgUrl || values.profilePicture) || defaultProfileImage}
                      />
                    </div>
                    <input
                      type="file"
                      id="file"
                      onChange={(event) => {
                        changePic(event);
                      }}
                      ref={inputFile}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    <Tooltip title="Upload a new photo">
                      <button
                        onClick={onChangeClick}
                        type="button"
                        className="ml-5 bg-green-900 py-1 px-2 border border-white-900 shadow-sm 
                            text-xs sm:text-sm leading-4 font-small text-white hover:bg-green-800 
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900
                            absolute bottom-0 right-0 rounded-full"
                      >
                        <PencilIcon
                          className="-ml-1  h-1 w-1 sm:h-5 sm:w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="grid grid-cols-6 gap-2 mt-5">
                <div className="col-span-6 sm:col-span-3">
                  <Field
                    className={`text-input ${errors.first_name
                      ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                      : ""
                      }`}
                    name="first_name"
                    placeholder="First name"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Field
                    className={`text-input ${errors.last_name
                      ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                      : ""
                      }`}
                    name="last_name"
                    placeholder="Last name"
                  />
                </div>
              </div>
              <div className="space-y-2 mt-4">
                <div className="mt-2">
                  <input
                    className="text-input font-bold bg-gray-200 opacity-70 cursor-not-allowed"
                    type="email"
                    name="email"
                    readOnly
                    placeholder="Email"
                    value={auth.user?.email}
                  />
                </div>
              </div>
            </div>
            <div className="pt-4">
              <SubmitButton
                disabled={!isValid}
                loading={loading}
                buttonText="Create Account"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
