import { NavLink } from "react-router-dom";

function Provider() {
    return (
        <section className='bg-swmiWhite relative py-10 md:py-24 border-t-2 border-b-2 border-green-900 overflow-hidden'>
            <div className="relative">
                <div className='container mx-auto flex justify-center items-center flex-col z-50'>
                    <h2 className='text-black-900 max-w-xl mt-0 text-center font-bold'>
                        Are you a service provider looking to bid on jobs?
                    </h2>
                    <div className="flex justify-center items-center text-center">
                        <NavLink to="/register/provider" className="text-lg text-yellow-200 bg-green-900 hover:text-yellow-200 font-bold shadow-3xl hover:shadow-none px-4 py-2 my-4 rounded-md">
                            Register as Service Provider
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="absolute rounded-full bg-yellow-800 w-44 h-44 md:w-72 md:h-72 right-0 bottom-0 md:bottom-10 z-0" style={{ transform: 'translateX(20%)', bottom: "-30px", zIndex: -1 }}></div>
            <div className="absolute rounded-full bg-yellow-800 w-44 h-44 md:w-72 md:h-72 left-0 top-0 md:top-10 z-0" style={{ transform: 'translateX(-20%)', top: "-30px", zIndex: -1 }}></div>

        </section>
    );
}

export default Provider;