import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// animation
import MotionInView from "components/animations/MotionInView";
// Layout Comp.
import Page from "components/Page";
import Container from "components/Container";
import MainNavBar from "components/layout/MainNavBar";
// Comp.
import { CardHeader } from "components/Card/index";
// Tabs Comp.
import Tabs from "components/Tabs";
// API
import { getSeekerProjectDetails } from "network/api";
// assets
import ReviewIcon from 'assets/images/icons/home/ic_choose_provider.svg'
//
import { SeekerDashboardTabs } from "components/serviceSeeker/dashboard/Tabs";

export const ProjectDetails = () => {
  const navigate = useNavigate();

  const [projectDetails, setProjectDetails] = useState(null);

  let id = window.location.href.split("?")[1].split("=")[1];
  useEffect(() => {
    getSeekerProjectDetails(id).then((data) => {
      if (data.status) {
        setProjectDetails(data.project);
      }
    });
  }, [id]);

  const handleOnTabChange = (selectedIndex) => {
    const { path } = Object.values(SeekerDashboardTabs)[selectedIndex][0];
    navigate({ pathname: path || '/dashboard/projects/details', search: `?project=${id}` })
  };

  return (
    <Page bg="bg-provider-dashboard-background h-auto w-auto min-h-screen	">
      <MainNavBar />
      <Container containerClasses={"relative"}>
        {projectDetails &&
          <MotionInView>
            <div className="flex justify-center min-w-7xl m-auto lg:m-auto w-full h-full bg-semiWhite opacity-90 p-10 md:p-7 flex-col md:rounded-t-lg md:rounded-card shadow-2xl min-w-fit">
              <CardHeader
                icon={
                  <div
                    className="rounded-full bg-semiWhite border-solid border-4 border-green-900 mx-auto relative shadow-3xl"
                    style={{ left: "0" }}
                  >
                    <img
                      src={ReviewIcon}
                      alt="Location icon"
                      width="80"
                      height="30"
                    />
                  </div>}
                label={projectDetails.property.label}
                classNames={" lg:justify-center text-4xl "}
                title={projectDetails.details.title}
                labelClasses={' text-4xl '}
                iconClasses={' text-4xl '}
              />
              <div className="w-full px-2 sm:px-0">
                <Tabs categories={SeekerDashboardTabs} rootPath={`dashboard/projects/details`} onTabChange={(selectedIndex) => handleOnTabChange(selectedIndex)} />
              </div>
            </div>
          </MotionInView>
        }
      </Container>
    </Page >
  );
}

