import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer id="contact" className=" bg-semiWhite relative max-w-screen pt-8 pb-6">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap flex-col md:flex-row items-center md:justify-between justify-center">
                    <div className='flex flex-col md:flex-row justify-between items-center gap-y-2'>
                        <Link to="/terms">
                            Terms &amp; Conditions
                        </Link>
                        <span className='hidden md:block'>&nbsp;|&nbsp;</span>
                        <Link to={"/privacy-policy"}>
                            Privacy Policy
                        </Link>
                        <span className='hidden md:block'>&nbsp;|&nbsp;</span>
                        <Link to="/faqs">
                            FAQ
                        </Link>
                    </div>
                    <div className="px-4 self-center md:self-end mt-4 md:mt-0">
                        <div className="text-sm text-black-900 font-semibold py-1">
                            © 2023 DAISY | All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;