export const data = {
    "status": true,
    "user": {
        "company_name": "tryDaisy",
        "personal_name": "Marwa Hakmi",
        "address": null,
        "experience_years": null,
        "license_type": null,
        "indv_license_num": null,
        "company_license_num": null,
        "mobile_phone": null,
        "landline_phone": null,
        "logo_url": "https://trydaisy-bucket.s3.us-east-2.amazonaws.com/provider/logo/502d21c2-36f0-4456-b7f4-2e73d26cd247/feYO4bKAI6QnsiLdTz24v9JGzV1SoD0SW0mZnYrU.png",
        "website_url": null,
        "facebook_url": null,
        "instagram_url": null,
        "email": "mhakmi@trydaisy.com",
        "phone": "+96171665641",
        "prefered_comm": "Email"
    },
    "services": [
        {
            "id": 1,
            "title": "Roofing"
        },
        {
            "id": 2,
            "title": "Siding"
        }
    ],
    "addresses": [
        {
            "id": "7ae7a70b-db13-48c0-9566-257d977024ca",
            "sp_id": "502d21c2-36f0-4456-b7f4-2e73d26cd247",
            "label": "home",
            "is_main": 0,
            "address_title": "Michigan Ave, Dearborn, MI, USA",
            "lat": 42.3114373,
            "lng": -83.2130203,
            "place_id": "Eh9NaWNoaWdhbiBBdmUsIERlYXJib3JuLCBNSSwgVVNBIi4qLAoUChIJjwL6a8xL",
            "country": "United States",
            "postal_code": "777701",
            "address_details": null,
            "is_active": 1
        },
        {
            "id": "b086b093-0377-4fac-b211-9882425cbd9c",
            "sp_id": "502d21c2-36f0-4456-b7f4-2e73d26cd247",
            "label": "Office",
            "is_main": 1,
            "address_title": "Michigan Ave, Chicago, IL, USA",
            "lat": 41.8165393,
            "lng": -87.6230125,
            "place_id": "Eh5NaWNoaWdhbiBBdmUsIENoaWNhZ28sIElMLCBVU0EiLiosChQKEgnhXhAWpCwO",
            "country": "United States",
            "postal_code": null,
            "address_details": null,
            "is_active": 1
        }
    ],
    "documents": [
        {
            "type": "Individual License",
            "id": 1,
            "title": "Personal License",
            "url": "https://trydaisy-bucket.s3.us-east-2.amazonaws.com/provider/documents/502d21c2-36f0-4456-b7f4-2e73d26cd247/ovRTFaFX0Jz2PjRBIfu82Q2oDHGg8aXmsRGJ9ayQ.pdf",
            "number": "",
            "issue_date": "2022-08-01 00:00:00",
            "issue_state_id": 20
        },
        {
            "type": "Company License",
            "id": 2,
            "title": "Experience",
            "url": "https://trydaisy-bucket.s3.us-east-2.amazonaws.com/provider/documents/502d21c2-36f0-4456-b7f4-2e73d26cd247/zafMAyDIdZMSnVI0Xqku48SWQHnfStWy9IFIg7Dk.pdf",
            "number": "",
            "issue_date": "2013-02-20 00:00:00",
            "issue_state_id": 7
        }
    ],
    "role": "service_provider",
    "states": [
        {
            "id": 1,
            "name": "Alabama",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 2,
            "name": "Alaska",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 3,
            "name": "Arizona",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 4,
            "name": "Arkansas",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 5,
            "name": "California",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 6,
            "name": "Colorado",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 7,
            "name": "Connecticut",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 8,
            "name": "Delaware",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 9,
            "name": "Florida",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 10,
            "name": "Georgia",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 11,
            "name": "Hawaii",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 12,
            "name": "Idaho",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 13,
            "name": "Illinois",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 14,
            "name": "Indiana",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 15,
            "name": "Iowa",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 16,
            "name": "Kansas",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 17,
            "name": "Kentucky",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 18,
            "name": "Louisiana",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 19,
            "name": "Maine",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 20,
            "name": "Maryland",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 21,
            "name": "Massachusetts",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 22,
            "name": "Michigan",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 23,
            "name": "Minnesota",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 24,
            "name": "Mississippi",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 25,
            "name": "Missouri",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 26,
            "name": "Montana",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 27,
            "name": "Nebraska",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 28,
            "name": "Nevada",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 29,
            "name": "New Hampshire",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 30,
            "name": "New Jersey",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 31,
            "name": "New Mexico",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 32,
            "name": "New York",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 33,
            "name": "North Carolina",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 34,
            "name": "North Dakota",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 35,
            "name": "Ohio",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 36,
            "name": "Oklahoma",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 37,
            "name": "Oregon",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 38,
            "name": "Pennsylvania",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 39,
            "name": "Rhode Island",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 40,
            "name": "South Carolina",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 41,
            "name": "South Dakota",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 42,
            "name": "Tennessee",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 43,
            "name": "Texas",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 44,
            "name": "Utah",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 45,
            "name": "Vermont",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 46,
            "name": "Virginia",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 47,
            "name": "Washington",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 48,
            "name": "West Virginia",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 49,
            "name": "Wisconsin",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 50,
            "name": "Wyoming",
            "country_id": 1,
            "created_at": null,
            "updated_at": null
        }
    ]
}