import Footer from "components/layout/Footer";
import Page from "../../components/Page";
import HomeNavbar from "../../components/layout/HomeNavbar";

export const TermsConditions = () => {
  return (
    <Page classNames={'h-auto'}>
      <HomeNavbar />
      <div className="max-w-screen mx-auto p-10">
        <h1 className="text-4xl font-bold mb-6 text-center text-green-900">Terms and Conditions</h1>
        <p>
          Welcome to our website platform that connects homeowners with service providers! We provide this
          platform to help homeowners find service providers for home repairs, maintenance, and other
          services. By using our website, you agree to these terms and conditions, so please read them
          carefully.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Service Provider Listing</h2>
        <p>
          Our website provides a directory of service providers who have registered with us to advertise their
          services. We do not endorse any specific service provider or guarantee the quality of their services. It
          is your responsibility to conduct your own due diligence before hiring a service provider.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">User Conduct</h2>
        <p>
          You agree to use our website for lawful purposes only and to comply with all applicable laws and
          regulations. You agree not to use our website to post any content that is defamatory, obscene, or
          illegal. You also agree not to use our website to harass, threaten, or intimidate other users.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Intellectual Property</h2>
        <p>
          Our website contains proprietary information and content, including trademarks, logos, and
          software. You may not copy, modify, distribute, or reproduce any of our content without our prior
          written consent.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Disclaimer of Warranties</h2>
        <p>
          We make no warranty or representation that our website will be uninterrupted, error-free, or virus-free.
          We do not guarantee the accuracy, completeness, or timeliness of any information provided on our
          website.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Limitation of Liability</h2>
        <p>
          We will not be liable for any damages, whether direct or indirect, arising from the use of our website
          or any service provider listed on our website. In no event will our liability exceed the amount paid by
          you, if any, for using our website.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Indemnification</h2>
        <p>
          You agree to indemnify and hold us harmless from any claims, damages, or liabilities arising from
          your use of our website, or from any violation of these terms and conditions.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Termination</h2>
        <p>
          We may terminate your access to our website at any time, without notice, if we determine that you
          have violated these terms and conditions.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Governing Law</h2>
        <p>
          These terms and conditions are governed by the laws of the state or country in which we are located,
          without giving effect to any conflict of laws provisions.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Modification of Terms and Conditions</h2>
        <p>
          We reserve the right to modify these terms and conditions at any time, without notice. Your
          continued use of our website after any such modifications constitutes your acceptance of the
          modified terms and conditions.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Entire Agreement</h2>
        <p>
          These terms and conditions constitute the entire agreement between you and us with respect to
          your use of our website.
        </p>
        <h2 className="text-lg font-bold mt-8 mb-4">Contact Us</h2>
        <p>
          If you have any questions about these terms and conditions, please contact us at <a href={`mailto:info@trydaisy.com`} className="text-sm text-black-900 font-semibold underline hover:text-blue-600">
            info@trydaisy.com
          </a>.
        </p>
      </div>
      <Footer />
    </Page>
  );
};
