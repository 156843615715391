import { useEffect, useState } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from 'uuid';
import { motion } from "framer-motion";
import MaterialsTable from "./MaterialsTable";
import Spinner from "components/Spinner";
import ListBox from "components/forms/ListBox";
// popup
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// forms
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
// hooks
import { useBidData } from "contexts/bid";
// api
import { getMaterialsInfo } from "network/api";

const MySwal = withReactContent(Swal);

const validationSchema = Yup.object({
  // name: Yup.string()
  //   .max(64, "Material name must be more than 64 characters")
  //   .required("Material name is required!"),
  name: Yup.string().required("Material name is required!"),
  subcategory: Yup.string().required("Material subcategory is required!"),
  unit_price: Yup.number()
    .moreThan(0, "Enter valid Unit Price")
    .required("Unit Price is required!"),
  quantity: Yup.number()
    .moreThan(0, "Enter valid quantity")
    .required("Quantity is required!"),
});

const Card = ({ children, classNames }) => (
  <div
    className={
      "border-t-2 border-green-900 opacity-100 py-6 pt-12 overflow-y-auto w-full flex flex-col justify-center items-center " +
      classNames
    }
  >
    {children}
  </div>
);

const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <textarea
        className={classNames("text-input", {
          "border-red-600 focus:ring-red-600 focus:border-red-600": "",
        })}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MaterialInfoStep = ({ goNextPage, goPrevPage, onSubmit }) => {
  const { bidData } = useBidData();

  const [materials, setMaterials] = useState(bidData.material_break_down ? bidData.material_break_down : []);
  const [material_info, setMaterialInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  // let uniqueIds = []
  let uniqueIds2 = []

  useEffect(() => {
    setLoading(true);
    getMaterialsInfo().then((res) => {
      if (res) {
        setMaterialInfo(res.materials.map((item) => {
          return {
            id: item.id,
            name: item.name,
            subCategories: res.material_subcategories.filter((subItem) => subItem.material_id === item.id)
          }
        }))
      }
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  const openSwal = async (data) => {
    await MySwal.fire({
      customClass: "mySwal2",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <MaterialForm materialInfo={material_info} updateMaterials={(obj) => updateMaterials(obj)} intialValues={data ? { ...data } : null} />
        </div>
      ),
    });
  };

  const updateMaterials = (newData) => {
    const i = materials.findIndex((obj => obj.key === newData[0].key));
    if (i !== -1) {
      let temp = [...materials]
      temp[i] = newData[0]
      setMaterials(temp)
    } else {
      setMaterials(materials.concat(newData))
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto w-full`}
    >
      <div className="flex justify-center max-w-7xl m-auto">
        <div className=" lg:m-auto justify-center w-full">
          <div className="flex flex-col">
            <h2 className="text-center pb-2 mt-0">Materials</h2>
            <div className="flex justify-center lg:space-x-6 mb-4 lg:max-h-80 flex-row space-y-6 lg:space-y-0">
              {loading ? <Spinner /> :
                <Card>
                  <MaterialsTable
                    onEdit={(record) => openSwal(record)}
                    onDelete={(record) => setMaterials(materials.filter((item) => item.key !== record.key))}
                    onSave={(list) => setMaterials(list)}
                    dataSource={materials}
                  />
                  <button
                    onClick={() => openSwal()}
                    className="btn-primary ml-1 w-1/4 mt-5 custom-btn self-center"
                  >
                    Add Material{" "}
                    <br />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </Card>
              }
            </div>
            <div className="flex flex-row w-full lg:w-1/2 justify-center self-center mt-10">
              <button
                type="button"
                onClick={goPrevPage}
                className="btn-outline mr-1"
              >
                Back
              </button>
              <button className="btn-primary ml-1 self-center" disabled={materials === undefined || materials.length === 0} onClick={() => {
                const material_cost = materials.reduce((prev, cur) => {
                  return prev + cur.quantity * cur.unit_price;
                }, 0);
                onSubmit({
                  "material_break_down": materials.filter(element => {
                    const isDuplicate = uniqueIds2.includes(element.name);

                    if (!isDuplicate) {
                      uniqueIds2.push(element.name);

                      return true;
                    }

                    return false;
                  }).map((item) => {
                    return {
                      "material_subcategory_id": material_info.find((material) => material.name === item.name).subCategories.find((subItem) => subItem.name === item.subcategory).id,
                      "name": item.name,
                      "subcategory": item.subcategory,
                      "description": item.description,
                      "reason": item.reason,
                      "unit_price": item.unit_price,
                      "quantity": item.quantity
                    }
                  }),
                  "material_total_cost": material_cost
                });
                goNextPage();
              }}>
                Save &amp; Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MaterialInfoStep;


const MaterialForm = ({ materialInfo, updateMaterials, intialValues }) => {
  const initValues = intialValues !== null ? intialValues : {
    material_cost: 0,
    name: materialInfo[0].name,
    subcategory: materialInfo[0].subCategories[0].name,
    description: "",
    reason: "",
    unit_price: 0,
    quantity: 0,
    key: uuidv4(),
  }

  const [material_name, setMaterialName] = useState(initValues.name)
  const [material_subcategory, setMaterialSubCategory] = useState(initValues.subcategory)

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        resetForm({ values: "" });
        updateMaterials([{
          key: values.key,
          name: values.name,
          subcategory: values.subcategory,
          material_subcategory_id: materialInfo.find((item) => item.name === values.name).subCategories.find((item) => item.name === values.subcategory).id,
          description: values.description,
          reason: values.reason,
          unit_price: values.unit_price,
          quantity: values.quantity
        }])
        MySwal.close();
      }}
    >
      {({ errors, isValid, dirty, setFieldValue }) => {
        return (
          <div className="w-full">
            <Form className="flex flex-col justify-center items-center content-center">
              <div className={" flex flex-col w-full"}>
                <div className="py-4 pt-2">
                  <label
                    className="block text-md font-medium text-black-900 mb-1 text-center"
                    htmlFor="label"
                  >
                    Material to Be Used
                  </label>
                </div>
                <div className="py-2 sm:py-3">
                  <label
                    className="block text-md font-medium text-black-900 mb-1 text-left required-field"
                    htmlFor="label"
                  >
                    Material Name
                  </label>
                  <ListBox
                    name="name"
                    options={materialInfo}
                    value={material_name}
                    onChange={(value) => {
                      setMaterialName(value)
                      setFieldValue("name", value)
                      setMaterialSubCategory(materialInfo.find((item) => item.name === value).subCategories[0].name)
                      setFieldValue("subcategory", materialInfo.find((item) => item.name === value).subCategories[0].name)
                    }}
                  />
                  <div className="flex justify-between items-center">
                    <div className="flex-auto w-2/5 mr-2">
                      {errors.name && (
                        <div className="mt-2 text-sm text-red-600 text-left">
                          <ErrorMessage name="name" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="py-2 sm:py-3">
                  <label
                    className="block text-md font-medium text-black-900 mb-1 text-left required-field"
                    htmlFor="label"
                  >
                    Material Subcategory
                  </label>
                  <ListBox
                    name="subcategory"
                    options={materialInfo.find((item) => item.name === material_name).subCategories}
                    value={material_subcategory}
                    onChange={(value) => {
                      setMaterialSubCategory(value)
                      setFieldValue("subcategory", value)
                    }}
                  />
                  <div className="flex justify-between items-center">
                    <div className="flex-auto w-2/5 mr-2">
                      {errors.subcategory && (
                        <div className="mt-2 text-sm text-red-600 text-left">
                          <ErrorMessage name="subcategory" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="py-2 sm:py-3">
                  <label
                    className="block text-md font-medium text-black-900 mb-1 text-left"
                    htmlFor="label"
                  >
                    Description
                  </label>
                  <MyTextArea
                    label="Description"
                    name="description"
                    rows="6"
                    placeholder="Enter Description of the Material here..."
                  />
                </div>
                <div className="py-2 sm:py-3">
                  <label
                    className="block text-md font-medium text-black-900 mb-1 text-left"
                    htmlFor="label"
                  >
                    Reason
                  </label>
                  <MyTextArea
                    label="Reason"
                    name="reason"
                    rows="4"
                    placeholder="Enter the reason here if there please."
                  />
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-stretch gap-4 w-full">
                  <div className="py-2 sm:py-3">
                    <label
                      className="block text-md font-medium text-black-900 mb-1 text-left required-field"
                      htmlFor="label"
                    >
                      Unit Price
                    </label>
                    <Field
                      type="number"
                      min="1"
                      className={classNames("text-input", {
                        "border-red-600 focus:ring-red-600 focus:border-red-600":
                          errors.unit_price,
                      })}
                      name="unit_price"
                      onKeyPress={(event) =>
                        event.key === "Enter" && MySwal.clickConfirm()
                      }
                      placeholder="Enter Unit Price"
                    />
                    <div className="flex justify-between items-center">
                      <div className="flex-auto w-2/5 mr-2">
                        {errors.unit_price && (
                          <div className="mt-2 text-sm text-red-600 text-left">
                            <ErrorMessage name="unit_price" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="py-2 sm:py-3">
                    <label
                      className="block text-md font-medium text-black-900 mb-1 text-left required-field"
                      htmlFor="label"
                    >
                      Quantity
                    </label>
                    <Field
                      type="number"
                      min="1"
                      className={classNames("text-input", {
                        "border-red-600 focus:ring-red-600 focus:border-red-600":
                          errors.quantity,
                      })}
                      name="quantity"
                      onKeyPress={(event) =>
                        event.key === "Enter" && MySwal.clickConfirm()
                      }
                      placeholder="Enter Quantity"
                    />
                    <div className="flex justify-between items-center">
                      <div className="flex-auto w-2/5 mr-2">
                        {errors.quantity && (
                          <div className="mt-2 text-sm text-red-600 text-left">
                            <ErrorMessage name="quantity" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full gap-2 justify-center self-center mt-10">
                <button
                  type="reset"
                  className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 rounded-md text-sm dark:bg-red-600 dark:hover:bg-red-700
                dark:focus:ring-red-900 shadow-lg w-full border redBG"
                  onClick={(_e) => {
                    MySwal.close();
                  }}
                >
                  Cancel
                </button>
                <button type="submit" disabled={!(isValid && dirty)} className="btn-primary ml-1 self-center">
                  Save
                </button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  )
}
