import { useRef, useState } from "react";
import Compressor from "compressorjs";
import { Tooltip } from "antd";
import { formatBytes } from "utils/formatNumber";
import { PencilIcon } from "@heroicons/react/solid";

import {
  providerUploadProfilePicture,
  seekerUploadProfilePicture,
} from "network/api";

import { useAuth } from "contexts/auth";

const ProfilePicture = ({ role }) => {
  const inputFile = useRef(null);
  const { state, dispatch } = useAuth();

  const pic =
    role === "service_provider"
      ? state.user?.logo_url
      : state.user?.profile_pic;

  const [imgSrc, setImgSrc] = useState(pic);

  const onChangeClick = () => {
    inputFile.current.click();
  };

  const changePic = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file) return;
    var form = new FormData();
    if (formatBytes('size' in file && file.size) > 2) {
      new Compressor(file, {
        quality: parseFloat((2 / formatBytes(file.size)).toFixed(1)),
        success(result) {
          form.append("image", result, result.name);
          if (role === "service_provider") providerUploadProfilePicture(form);
          else {
            seekerUploadProfilePicture(form);
          }
        },
        error(err) {
        },
      });
    } else {
      form.append("image", file);
      if (role === "service_provider") providerUploadProfilePicture(form);
      else {
        seekerUploadProfilePicture(form);
      }
    }
    setImgSrc(URL.createObjectURL(file));
    if (role === "service_provider")
      dispatch({
        type: "SET_USER",
        payload: {
          user: {
            ...state.user,
            logo_url: URL.createObjectURL(file),
          },
        },
      });
    else
      dispatch({
        type: "SET_USER",
        payload: {
          user: {
            ...state.user,
            profile_pic: URL.createObjectURL(file),
          },
        },
      });
    // setFieldValue("profilePicture", URL.createObjectURL(file));
  };

  const DefaultImage = () => {
    return (
      <svg
        className="text-green-500 rounded-full overflow-hidden bg-gray-100"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    );
  };

  return (
    <div className="relative w-3/4 h-full z-50 ">
      {imgSrc ? (
        <div
          style={{
            paddingBottom: "100%",
            height: 0,
            width: "100%",
            position: "relative",
          }}
        >
          <img
            // className="h-20 w-30 text-green-500 object-contain	"
            className="text-green-500 absolute rounded-full overflow-hidden bg-gray-100  h-full w-full object-cover	"
            alt="profilePicture"
            src={imgSrc}
            width="380"
            height="380"
          />
        </div>
      ) : (
        <DefaultImage />
      )}

      <input
        type="file"
        id="file"
        onChange={(event) => {
          changePic(event);
        }}
        ref={inputFile}
        style={{ display: "none" }}
        accept="image/*"
      />
      <Tooltip title="Change Profile Picture">
        <button
          onClick={onChangeClick}
          type="button"
          className="ml-5 bg-green-900 py-2 px-3 border-8 border-white-900 
            shadow-sm text-xs sm:text-sm leading-4 font-small text-white hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900 absolute bottom-0 rounded-full"
          style={{ right: "12%" }}
        >
          <PencilIcon
            className="-ml-1  h-5 w-5 sm:h-10 sm:w-10"
            aria-hidden="true"
          />
        </button>
      </Tooltip>
    </div>
  );
};

export default ProfilePicture;
