import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Form, Input, Table, InputNumber } from 'antd';
// api
import { UpdateMilestonesTemplate, DeleteMilestonesTemplate } from 'network/providerAPI'

const EditableContext = React.createContext(null);

const initialMilestones = [{
    key: 1,
    title: 'Upon Completion',
    percentage: 100,
    description: `Please provide a description in this section. 
    Note that this milestone is set as default and cannot be removed. 
    However, you are able to modify the percentage and description as needed.`,
    order: 1
}]

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    inputType,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = (_e) => {
        if (record.title === 'Upon Completion' && dataIndex !== 'description' && dataIndex !== 'percentage') return;
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            let values = await form.validateFields();
            if (values.percentage) values.percentage = parseInt(values.percentage);
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
        }
    };

    let childNode = children;

    const inputNode = inputType === 'number'
        ? <InputNumber ref={inputRef} onPressEnter={save} onBlur={save}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
        />
        : <Input ref={inputRef} onPressEnter={save} onBlur={save} />

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {/* <Input ref={inputRef} onPressEnter={save} onBlur={save} /> */}
                {inputNode}
            </Form.Item>
        ) : (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const MilestonesTable = ({ service_id, milestones }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(2);


    useEffect(() => {
        setDataSource(milestones.map((item, index) => ({ ...item, key: index })));
        setCount(milestones.length + 1);
    }, [milestones]);

    const defaultColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            width: '25%',
            editable: true,
        },
        {
            title: 'Percentage',
            dataIndex: 'percentage',
            width: '5%',
            editable: true,
            // set type to number in editable cell to prevent string concatenation and prevent user from entering non-numeric values
            inputType: 'number',
            render: (_, record) =>
            (<span>
                {record.percentage}%
            </span>)

        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '50%',
            editable: true,

        },
        // {
        //     title: 'Action',
        //     dataIndex: 'Action',
        //     render: (_, record) =>
        //         dataSource.length >= 1 && record.title !== 'Upon Completion' ? (
        //             <Popconfirm title="are you sure?" onConfirm={() => handleDelete(record.key)}>
        //                 <Button type='danger'>Delete</Button>
        //             </Popconfirm>
        //         ) : null,
        // },
    ];

    const handleAdd = () => {
        const newData = {
            service_id,
            key: count,
            title: 'title',
            percentage: 0,
            // total_amount: 1000,
            description: 'description'
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    // const handleDelete = (key) => {
    //     const newData = dataSource.filter((item) => item.key !== key);
    //     apiCall(newData)
    // };

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
    };

    const apiCall = async (data) => {
        const milestones = data.map((item) => ({ ...item, order: item.key + 1 }));
        const res = await UpdateMilestonesTemplate({ service_id, milestones });
        if (res?.status) {
            enqueueSnackbar("Your Template Updated!", { variant: "success" })
            setDataSource(data)
            // onSuccess(values.email);
        } else {
            enqueueSnackbar("Updating your template failed!", { variant: "error" })
        }
    }

    // reset template functionality
    const deleteTemplate = async () => {
        const res = await DeleteMilestonesTemplate({ service_id });
        if (res?.status) {
            enqueueSnackbar("Your Template Deleted!", { variant: "success" })
            setDataSource(initialMilestones)
        } else {
            enqueueSnackbar("Deleting your template failed!", { variant: "error" })
        }
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                // set type to number in editable cell to prevent string concatenation and prevent user from entering non-numeric values
                inputType: col.dataIndex === 'percentage' ? 'number' : 'text',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <div>
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{ hideOnSinglePage: true }}
            />
            <div className='mt-6 w-full grid grid-cols-1 sm:grid-cols-4 justify-center items-center gap-4'>
                <Button
                    onClick={handleAdd}
                    type="primary"
                    disabled={dataSource.reduce(function (prev, cur) {
                        return prev + cur.percentage;
                    }, 0) >= 100}
                    style={{
                        borderRadius: 8
                    }}
                >
                    Add a row
                </Button>
                <button
                    className='btn-primary'
                    onClick={() => apiCall(dataSource)}
                    disabled={dataSource.reduce(function (prev, cur) {
                        return prev + cur.percentage;
                    }, 0) < 100 || dataSource.reduce(function (prev, cur) {
                        return prev + cur.percentage;
                    }, 0) > 100}
                >
                    Save Template
                </button>
                {/* reset button to reset milestone template */}
                <button
                    className='btn-primary bg-red-600 border-red-700 hover:bg-red-700'
                    onClick={() => deleteTemplate()}
                >
                    Reset Template
                </button>
            </div>
        </div>
    );
};

export default MilestonesTable;