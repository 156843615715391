import React from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import CardSection from './cardSection';
import './style.css';


class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    await stripe.createToken(card);
    await stripe.confirmCardSetup('seti_1M6fhVEfxHS33ZzGGJmVJ5Tj_secret_MqMQDuojRrx8MaFSDvFu35QJlM7puDm', {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });
  };

  render() {
    return (
      <div>
        <div className="product-info">
          <h3 className="product-title">ROOF Change Project Fees</h3>
          <h4 className="product-price">$2500</h4>
        </div>
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <button disabled={!this.props.stripe} className="btn-primary">
            Buy Now
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
