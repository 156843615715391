import { twMerge } from 'tailwind-merge';

function IconButton({ Icon, classNames, disabled, ...props }) {
    return (
        <button
            {...props}
            className={twMerge("bg-inherit hover:bg-green-900 border-2 border-green-900 text-green-900 hover:text-white  inline-flex items-center font-bold p-1 rounded-full", classNames)}
            disabled={disabled}
        >
            {Icon}
        </button>
    );
}

export default IconButton;