import { motion } from "framer-motion";
import AutoComplete from "react-google-autocomplete";
import location_step from '../../../assets/images/icons/location_step_ic.svg';
import Card from '../../Card';
import IconContainer from '../../IconContainer';

const NewProjectLocationStep = ({ data, onChange, onNext }) => {
  const onPlaceSelected = (place) => {
    if (place.address_components) {
      const address = place.address_components.reduce(
        (seed, { long_name, types }) => {
          types.forEach((t) => {
            seed[t] = long_name;
          });
          return seed;
        },
        {}
      );
      console.log('address:', address)
      onChange({
        addressTitle: place.formatted_address,
        country: address.country,
        city: address.locality || address.sublocality || address.administrative_area_level_1 || address.administrative_area_level_2 || address.administrative_area_level_3 || undefined,
        postalCode: address.postal_code,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        placeId: place.place_id,
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.1 },
        ease: 'easeInOut',
      }}
      className={`mx-auto sm:pt-5 lg:pt-0 md:px-8   bg-no-repeat	bg-cover	bg-center   md:pb-10
                  bg-location_step overflow-y-auto lg:overflow-hidden min-h-900 relative`}
    >
      <div className="flex justify-center h-full md:h-screen  absolute bottom-0 md:relative">
        <div className="max-w-7xl	 md:m-auto grid grid-cols-1 items-baseline md:items-center gap-0 md:gap-10 lg:gap-28 lg:grid-cols-2 justify-center">
          <Card>
            <h3 className="text-2xl text-center md:text-left">
              Where is your project located
            </h3>
            <div className="flex justify-center md:justify-start ">
              <hr className="w-2/4 h-px bg-black-900" />
            </div>
            <div className={`max-w-full`}>
              <div className="mt-8">
                <div className="mt-6">
                  <div className="mt-6">
                    <div className="mt-8">
                      <p className="block text-sm font-medium text-black-900 mb-1">
                        Location
                      </p>
                      <AutoComplete
                        apiKey="AIzaSyCYb4ZrW-tE-JlKVoE84r0606U9zS2gcmA"
                        className="text-input"
                        options={{ types: ['address'] }}
                        onPlaceSelected={onPlaceSelected}
                        name="address"
                        defaultValue={data.addressTitle}
                      />
                    </div>
                    <div className="mt-4">
                      <label
                        className="block text-sm font-medium text-black-900 mb-1"
                        htmlFor="label"
                      >
                        Label
                      </label>
                      <input
                        className="text-input"
                        id="label"
                        name="label"
                        value={data.label || ''}
                        placeholder="Home"
                        onChange={(event) =>
                          onChange({ label: event.target.value })
                        }
                      />
                    </div>
                    <button
                      disabled={!(data.addressTitle && (data.label && data.label?.length > 2))}
                      className="mt-8 btn-primary"
                      type="button"
                      onClick={onNext}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <IconContainer>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                default: { duration: 1.5 },
              }}
              src={location_step}
              alt="Daisy Logo"
              className="max-w-none	md:max-w-xs min-w-sm lg:filter lg:drop-shadow-xl"
            />
          </IconContainer>
        </div>
      </div>
    </motion.div>
  );
};

export default NewProjectLocationStep;
