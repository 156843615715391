import { useState, useEffect } from "react";
import { twMerge } from "tailwind-merge";
// alert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// Comps.
import { Tooltip, Popconfirm } from "antd";
import FilterBids from "./FilterBids";
import BidDetails from "./BidDetails";
import Spinner from "components/Spinner";
import { Card } from "components/Card/index";
import { IconButton } from "components/Button/index";
import MotionInView from "components/animations/MotionInView";
// API
import { declineBid, shortListBid, selectBid, getSeekerProjectBids } from "network/api";
// Utils
import { days } from "utils/calculate-days";
// Assets
import { FiStar } from 'react-icons/fi'
import { GoCheck } from 'react-icons/go'
import { CgClose } from 'react-icons/cg'
import { MdUpdate } from 'react-icons/md'

const mySwal = withReactContent(Swal);

const ProjectBids = () => {
    const [bids, setBids] = useState([]);
    const [projectBids, setProjectBids] = useState([]);
    const [selectedBid, setSelectedBid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [collapssed, setCollapssed] = useState('');

    useEffect(() => {
        setLoading(true);
        let id = window.location.href.split("?")[1].split("=")[1];
        getSeekerProjectBids(id).then((data) => {
            if (data.status) {
                console.log('data.bids', data.bids)
                let orderBidsArray = orderBids(data.bids);
                console.log('orderBidsArray', orderBidsArray)
                setProjectBids(orderBidsArray);
                setBids(data.bids)
            }
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const orderBids = (bids) => {
        if (!bids) { return [] }
        let newBids = [];
        const selectedBid = bids.filter((bid) => bid.details.status_id === 3 || bid.details.status_id === 6 || bid.details.status_id === 5 || bid.details.status_id === 9);
        if (selectedBid.length === 1) {
            setSelectedBid(true);
            newBids.push(selectedBid[0]);
        } else {
            bids.forEach((value) => {
                newBids.push(value);
            });
        }
        return newBids;
    }

    const updateBids = () => {
        setCollapssed('')
        setSelectedBid(false);
        setLoading(true);
        let id = window.location.href.split("?")[1].split("=")[1];
        getSeekerProjectBids(id).then((data) => {
            if (data.status) {
                setProjectBids(orderBids(data.bids));
                setBids(data.bids)
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const editBid = (e, id, bidId) => {
        e.preventDefault();
        switch (id) {
            case 0:
                mySwal.fire({
                    icon: 'question',
                    title: 'Are you sure you want to reject it?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Reject Bid',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    background: "#fffced",
                    html: (
                        <p className="text-sm text-red-600 font-bold">
                            Rejecting a bid cannot be undone. This provider will not be able to submit a new bid for your project.
                        </p>
                    ),
                }).then(async (result) => {
                    setTimeout(() => {
                        mySwal.close()
                    }, 1000)
                    if (result.isConfirmed) {
                        console.log('Reject loading started')
                        mySwal.showLoading();
                        const response = await declineBid(bidId);
                        updateBids();
                        if (response) {
                            mySwal.close();
                            mySwal.fire({
                                icon: 'success',
                                title: 'Bid Rejected!',
                                text: 'Bid has been rejected successfully!',
                                background: "#fffced",
                            })
                        }
                    }
                })
                break;
            case 1:
                Swal.fire({
                    icon: 'question',
                    title: 'Do you want to shortlist this Bid?',
                    showCancelButton: true,
                    confirmButtonText: 'Shortlist Bid',
                    denyButtonText: `Don't shortlist`,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    background: "#fffced",
                }).then(async (result) => {
                    setTimeout(() => {
                        Swal.close()
                    }, 1000)
                    if (result.isConfirmed) {
                        Swal.showLoading();
                        console.log('SWAL loading', Swal.isLoading())
                        const response = await shortListBid(bidId);
                        updateBids();
                        if (response) {
                            Swal.close();
                            Swal.fire({
                                icon: 'success',
                                title: 'Bid Shortlisted!',
                                background: "#fffced",
                            })
                        }
                    }
                })
                break;
            case 2:
                mySwal.fire({
                    icon: 'question',
                    title: 'Do you want to approve this Bid?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Select Bid',
                    denyButtonText: `Don't approve`,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    background: "#fffced",
                    html: (
                        <p className="text-sm text-green-800 font-bold">
                            If you select this bid, it means you want the service provider who offered this bid to take this project. Other bids will not be rejected. Proceed?
                        </p>
                    )
                }).then(async (result) => {
                    setTimeout(() => {
                        mySwal.close()
                    }, 1000)
                    mySwal.showLoading();
                    if (result.isConfirmed) {
                        const response = await selectBid(bidId);
                        updateBids();
                        if (response) {
                            handleShowMore(bidId);
                            mySwal.close();
                            mySwal.fire({
                                icon: 'success',
                                title: 'Bid Selected!',
                                background: "#fffced",
                            })
                        }
                    }
                })
                break;
            default:
                break;
        }
    }

    const handleShowMore = (id) => {
        setCollapssed((oldValue) => {
            if (oldValue === id) {
                return "";
            }
            return id;
        });
    }

    const handleFilterChange = (option) => {
        if (option === 'all') return setProjectBids(bids);
        setProjectBids(bids.filter(value => value.status === option));
    }

    return (
        loading ? <Spinner /> :
            <MotionInView>
                {selectedBid ? (
                    <BidDetails
                        bidData={projectBids[0]}
                        updateBids={() => updateBids(projectBids[0].details.id)}
                        showHighlightedMsg={(projectBids[0].details.status_id === 3) ? true : false}
                    />
                ) : (
                    <div className="flex justify-center items-center max-w-7xl m-auto w-full h-full bg-semiWhite opacity-90 p-10 md:p-1 flex-col">
                        {!collapssed && (
                            // display the filter component always except when the user is viewing the bid details.
                            // show No Bids Yet, Stay Tuned! if there is no bids.
                            // show No Bids available with this status if the user filtered the bids and there is no bids with this status.
                            <div className="w-full flex  flex-col justify-center items-center">
                                <FilterBids bids={projectBids} onFilterChange={handleFilterChange} />
                                {projectBids.length === 0 && (
                                    <div className="flex justify-center items-center w-full h-full">
                                        <div className="text-2xl text-gray-700 font-bold">
                                            No Bids Yet, Stay Tuned!
                                        </div>
                                    </div>)}
                            </div>
                        )}
                        <div
                            className={twMerge(
                                "min-w-full gap-5 m-auto pb-5 grid justify-center items-center",
                                projectBids.length > 1 ? "lg:grid-cols-2" : "lg:grid-cols-1",
                                collapssed && "lg:grid-cols-1"
                            )}
                        >
                            {!collapssed && projectBids.map((bidInfo, idx) => (
                                <div key={idx} className={`${projectBids.length === 1 ? 'row-span-2' : ""} w-full h-full`}>
                                    {collapssed !== bidInfo.details.id && (
                                        <Card
                                            key={bidInfo.details.id}
                                            classNames={twMerge(
                                                projectBids.length === 1 ? 'max-w-3/4 lg:w-1/2 mx-auto' : "",
                                                `mt-5 self-center border-yellow-200 border-2 justify-between flex flex-col`,
                                                (bidInfo.details.status_id === 4 || bidInfo.details.status_id === 7) && "border-red-500 opacity-50",
                                            )}
                                        >

                                            <div className="flex justify-between items-center my-4">
                                                <span className="flex justify-center items-center gap-1 px-2 py-1 text-xs text-white bg-green-700 rounded-md shadow-md">
                                                    <MdUpdate className="h-6 w-6" />
                                                    {days(new Date(bidInfo.details.bid_valid_until.replace(/-/g, '/'))) === 'Bid Expired' ? 'Bid Expired' : `${days(new Date(bidInfo.details.bid_valid_until.replace(/-/g, '/')))} Remaining`}
                                                </span>
                                                <div className="flex gap-2 justify-center items-center">
                                                    <span className="flex justify-center items-center gap-1 px-2 py-1 text-sm text-white bg-green-700 rounded-md shadow-md font-semibold">
                                                        {`${bidInfo.details.material_total_cost + bidInfo.details.service_fees}$`}
                                                    </span>
                                                    {(bidInfo.details.status_id !== 4 && bidInfo.details.status_id !== 7) ? (
                                                        <>
                                                            {bidInfo.details.status_id !== 2 ? (
                                                                <Tooltip placement="topLeft" title={"Shortlist Bid"}>
                                                                    <IconButton
                                                                        Icon={<FiStar className="h-6 w-6" />}
                                                                        onClick={(e) => editBid(e, 1, bidInfo.details.id)}
                                                                        disabled={bidInfo.status === "Shortlisted" || days(new Date(bidInfo.details.bid_valid_until.replace(/-/g, '/')), new Date()) === "Bid Expired"}
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <span className="flex justify-center items-center gap-1 px-2 py-1 text-sm text-white bg-yellow-700 rounded-md shadow-md font-semibold">
                                                                    Shortlisted
                                                                </span>
                                                            )}
                                                            <Tooltip placement="topLeft" title={"Reject Bid"}>
                                                                <IconButton
                                                                    Icon={<CgClose className="h-6 w-6" />}
                                                                    onClick={(e) => editBid(e, 0, bidInfo.details.id)}
                                                                    disabled={bidInfo.status === "Declined" || days(new Date(bidInfo.details.bid_valid_until.replace(/-/g, '/')), new Date()) === "Bid Expired"}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip placement="topLeft" title={"Select Bid"}>
                                                                <IconButton
                                                                    Icon={<GoCheck className="h-6 w-6" />}
                                                                    onClick={(e) => editBid(e, 2, bidInfo.details.id)}
                                                                    disabled={bidInfo.status === "Accepted" || days(new Date(bidInfo.details.bid_valid_until.replace(/-/g, '/')), new Date()) === "Bid Expired"}
                                                                />
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        <span className="flex justify-center items-center gap-1 px-2 py-1 text-sm text-white bg-red-500 rounded-md shadow-md font-semibold">
                                                            Declined
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-4 text-sm text-gray-900 font-bold leading-6 text-justify line-clamp-4 h-24 ">
                                                {bidInfo.details.service_fees_description}
                                            </div>
                                            <div className="w-100 mt-4 h-8 content-center text-center">
                                                {(bidInfo.details.status_id !== 4 && bidInfo.details.status_id !== 7) && (
                                                    <button
                                                        className="text-sm btn-link w-1/3 text-black h-5 underline"
                                                        onClick={() => handleShowMore(bidInfo.details.id)}
                                                    >
                                                        Show More
                                                    </button>
                                                )}
                                            </div>
                                        </Card>
                                    )}
                                    {/* {collapssed === bidInfo.details.id &&
                                        <div className="flex flex-col min-w-full">
                                            <BidDetails
                                                bidData={bidInfo}
                                                updateBids={() => updateBids(bidInfo.details.id)}
                                                showHighlightedMsg={false}
                                            />
                                            <div className="w-100 mt-4 content-center text-center">
                                                <button
                                                    className="text-sm btn-link w-1/3 text-black h-5"
                                                    onClick={() => handleShowMore(bidInfo.details.id)}
                                                >
                                                    Back to Bids
                                                </button>
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            ))}
                            {/* display bidDetails component here by replacing bids cards and display bidDetails */}
                            {collapssed && (
                                <div className="flex flex-col min-w-full">
                                    <BidDetails
                                        bidData={projectBids.filter((bid) => bid.details.id === collapssed)[0]}
                                        updateBids={() => updateBids(projectBids.filter((bid) => bid.details.id === collapssed)[0].details.id)}
                                        showHighlightedMsg={false}
                                    />
                                    <div className="w-100 mt-4 content-center text-center">
                                        <button
                                            className="text-sm btn-link w-1/3 text-black h-5"
                                            onClick={() => handleShowMore(collapssed)}
                                        >
                                            Back to Bids
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
                }
            </MotionInView >
    );
};

export default ProjectBids;
