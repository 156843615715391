import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import './style.css';


const stripePromise = loadStripe(
  'pk_test_51HkXaIEfxHS33ZzGXOrd4M8OsdMubTUL3QhKu6QrkYaGMic7Db7KJJUsF9GQdkkOQZjeZWqeCfRCKTNIUdIEQN8L00O2fMMnmh'
);
    
const Payment = () => {
  return (
    <div className="App">
      <div className="product">
        <img
          src="https://cdn.technologyadvice.com/wp-content/uploads/2017/08/Fotolia_98303431_Subscription_Monthly_M-699x408.jpg"
          alt="laptop"
          style={{ width: '100%', height: 'auto' }}
        />
        <div>
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    </div>
  );
};


export default Payment;
