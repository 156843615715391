import { useState, useEffect } from "react";

// components
import LoadingDots from "../animations/LoadingDots";
import CheckboxGroup from "components/forms/CheckboxGroup";

// hooks
import { useAuth } from "contexts/auth";

// api
import { getServices } from "network/api";

export const ProviderServicesRegistrationForm = ({ onSubmit }) => {
  const auth = useAuth();

  const [loading] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelected] = useState([]);

  useEffect(() => {
    getServices().then((data) => {
      setServices(data);
    });
  }, []);

  const handleSubmit = () => {
    const selected = services
      .filter((f) => selectedServices.includes(f.title))
      .map((s) => s.id)
      .join(",");
    auth.user["services"] = selected;
    auth.dispatch({
      type: "SET_USER",
      payload: {
        user: {
          ...auth.user,
        },
      },
    });
    onSubmit();
  };

  return (
    <div className="space-y-4 mt-6">
      <h1 className="text-bold text-xl">Select Services:</h1>
      <div className="space-y-1">
        <div className="mt-1">
          <CheckboxGroup
            values={selectedServices}
            options={services.map((service) => service.title)}
            onChange={(value) => setSelected(value)}
          />
        </div>

        <div className="pt-4">
          <button
            onClick={() => handleSubmit()}
            disabled={selectedServices.length <= 0}
            className="btn-primary"
          >
            {loading ? <LoadingDots /> : `Create Account`}
          </button>
        </div>
      </div>
    </div>
  );
};
