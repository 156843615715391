import { useEffect } from 'react';
import RadioInput from "../../forms/RadioInput";
import Card from '../../Card';
import { motion, useAnimation } from 'framer-motion';
import CheckboxGroup from "../../forms/CheckboxGroup";
import IconContainer from '../../IconContainer';

const NewProjectServiceQuestionsStep = ({
  data,
  currentQuestion,
  service,
  onPrev,
  onChange,
  onNext,
  bg,
  icon,
}) => {
  const animationControls = useAnimation();
  if (!service && !service.questions && !service.questions[currentQuestion - 1]) {
    return;
  }

  let question = service.questions[currentQuestion - 1];

  if (!question) {
    onPrev();
  }

  const questionId = `q${currentQuestion}`;
  const answer = data.answers[questionId];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    animationControls.set({ opacity: 0 });
    animationControls.start({
      opacity: 1,
      transition: {
        default: { duration: 0.6 },
        ease: 'easeInOut',
      },
    });
  }, [animationControls, question]);

  const renderField = () => {
    switch (question.type) {
      case 'number':
        return (
          <input
            value={answer || ''}
            className="text-input"
            type="number"
            required
            min="1"
            placeholder="1"
            onChange={(event) =>
              onChange({
                answers: {
                  ...data.answers,
                  [questionId]: event.target.valueAsNumber,
                },
              })
            }
          />
        );
      case 'radio':
        return (
          <RadioInput
            value={answer || ''}
            options={question.options}
            previewsValue={data.answers.q3}
            setMaterialId={(value) => onChange({
              material_id: value,
            })}
            onChange={(value) =>
              onChange({
                answers: {
                  ...data.answers,
                  [questionId]: value,
                },
              })
            }
          />
        );
      case 'checkbox':
        return (
          <CheckboxGroup
            values={answer || []}
            options={question.options}
            onChange={(value) =>
              onChange({
                answers: {
                  ...data.answers,
                  [questionId]: value,
                },
              })
            }
            previewsValue={data.answers.q3}
          />
        );
      default:
        return <span>Field not supported.</span>;
    }
  };
  return (
    <motion.div
      animate={animationControls}
      exit={{ opacity: 0 }}
      className={
        bg +
        ` mx-auto sm:pt-5 lg:pt-0 md:px-8 bg-no-repeat	bg-cover bg-center md:pb-10 overflow-y-auto lg:overflow-hidden
         min-h-900 relative
        `
      }
    >
      <div className="flex justify-center  h-full md:h-screen absolute bottom-0 md:relative">
        <div className="max-w-7xl	 md:m-auto grid grid-cols-1 items-baseline md:items-center gap-0 md:gap-10 lg:gap-28 lg:grid-cols-2 justify-center">
          <Card>
            <h3 className="text-2xl text-center md:text-left">
              {question?.content ? question?.content : "Press back to to rechose service"}
            </h3>
            <div className="flex justify-center md:justify-start ">
              <hr className="w-2/4 h-px bg-black-900" />
            </div>
            <div className={`max-w-full`}>
              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-4 mt-6">
                    {question && renderField()}
                    <div className="flex justify-end pt-8">
                      <button
                        className="btn-outline mr-1"
                        type="button"
                        onClick={onPrev}
                      >
                        Back
                      </button>
                      <button
                        disabled={!answer || answer.length === 0}
                        className="btn-primary ml-1"
                        type="button"
                        onClick={onNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <IconContainer>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                default: { duration: 1.5 },
              }}
              src={icon}
              alt="Daisy Logo"
              className="max-w-xs w-full lg:filter lg:drop-shadow-xl"
            />
          </IconContainer>
        </div>
      </div>
    </motion.div>
  );
};

export default NewProjectServiceQuestionsStep;
