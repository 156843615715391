/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";

import { useAuth } from "contexts/auth";

import { PencilIcon, SaveIcon } from "@heroicons/react/solid";

// popup
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import EmailForm from "components/ChangeEmailForm";
import PhoneNumberForm from "components/ChangePhoneNumberForm";
import EmailVerificationForm from "components/EmailVerificationForm";
import PhoneVerificationForm from "components/PhoneVerificationForm";

import { useSnackbar } from "notistack";

const MySwal = withReactContent(Swal);

const PersonalInfoForm = ({
  initialValues,
  onEditName,
  onEditCommunicationMethod,
}) => {
  const { state, dispatch } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const openSnackbar = (data) => {
    enqueueSnackbar(data.message, {
      variant: data.variant,
    });
  };

  const [sms, setSms] = useState(initialValues?.smsCommunicationMethod);
  const [smsChange, setSmsChange] = useState(false);

  const setCommunicationSms = () => {
    setSmsChange((oldSms) => !oldSms);
    setSms((sms) => !sms);
  };

  const updateComminicationMethod = () => {
    if (smsChange) {
      onEditCommunicationMethod(sms);
    }
    setSmsChange(false);
  };

  const values = state.user;

  const editSeekerEmail = async () => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <EmailForm
            onSuccess={(email) => openVerifySwal(email)}
            onClose={() => MySwal.close()}
            openAlert={(data) => openSnackbar(data)}
          />
        </div>
      ),
    });
  };

  const openVerifySwal = async (email) => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <EmailVerificationForm
            Email={email}
            onSuccess={(value) => updateProfile("email", value)}
            onClose={() => MySwal.close()}
            openAlert={(data) => openSnackbar(data)}
          />
        </div>
      ),
    });
  };

  const editSeekerPhoneNumber = async () => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <PhoneNumberForm
            onSuccess={(phone) => openPhoneVerifySwal(phone)}
            onClose={() => MySwal.close()}
            openAlert={(data) => openSnackbar(data)}
          />
        </div>
      ),
    });
  };

  const openPhoneVerifySwal = async (phone) => {
    await MySwal.fire({
      customClass: "mySwal",
      allowOutsideClick: false,
      showConfirmButton: false,
      html: (
        <div>
          <PhoneVerificationForm
            Phone={phone}
            onSuccess={(value) => updateProfile("phoneNumber", value)}
            onClose={() => MySwal.close()}
            openAlert={(data) => openSnackbar(data)}
          />
        </div>
      ),
    });
  };

  const updateProfile = (key, value) => {
    MySwal.close();
    dispatch({
      type: "SET_USER",
      payload: {
        user: {
          ...state.user,
          [key]: value,
        },
      },
    });
  };

  return (
    <div className="flex items-start flex-col relative">
      <div className="space-y-4 w-full">
        <div
          className="grid grid-cols-12 lg:grid-cols-6 gap-2 py-2 cursor-pointer w-full lg:w-3/4 pr-10 firstNamePenSection"
          onClick={() => onEditName(state.user?.first_name, values?.last_name)}
        >
          <div className="col-span-6 sm:col-span-3 mr-4">
            <label
              className="block text-md font-medium text-black-900 mb-1 text-left"
              htmlFor="label"
            >
              First Name
            </label>
            <div className="flex">
              <div className="flex-grow">{state.user?.first_name}</div>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              className="block text-md font-medium text-black-900 mb-1 text-left"
              htmlFor="label"
            >
              Last Name
            </label>
            <div className="flex">
              <div className="flex-grow">{values?.last_name}</div>
              <button
                name="edit-name-button"
                className="firstNamePen"
                onClick={() => {
                }}
              >
                <PencilIcon className="h-5 w-5" color="#638B8D" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-start ">
          <hr className="w-full lg:w-3/4 h-px bg-gray-50" />
        </div>
        <div
          data-testid="div-test"
          name="div-test"
          className="cursor-pointer w-full lg:w-3/4 pr-10 EmailPenSection"
          onClick={() => {
            editSeekerEmail();
          }}
        >
          <div className="py-2 ">
            <label
              className="block text-md font-medium text-black-900 mb-1 text-left"
              htmlFor="label"
            >
              Email Address
            </label>
            <div className="flex">
              <div className="flex-grow">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#779A9C"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                  <span className="ml-4">{values?.email}</span>
                </div>
              </div>
              <button
                name="edit-name-button pr-48"
                className="emailPen"
                data-testid="btn-test"
              >
                <PencilIcon className="h-5 w-5" color="#638B8D" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-start ">
          <hr className="w-full lg:w-3/4 h-px bg-gray-50" />
        </div>
        <div
          className="cursor-pointer w-full lg:w-3/4 pr-10 numPenSection"
          onClick={() => editSeekerPhoneNumber()}
        >
          <div className="py-2 ">
            <label
              className="block text-md font-medium text-black-900 mb-1 text-left"
              htmlFor="label"
            >
              Phone Number
            </label>
            <div className="flex">
              <div className="flex-grow">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="#779A9C"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  <span className="ml-4">
                    {values?.phoneNumber
                      ? values.phoneNumber
                      : "provide a phone number"}
                  </span>
                </div>
              </div>
              <button name="edit-name-button w-96	" className="numPen">
                <PencilIcon className="h-5 w-5" color="#638B8D" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-start ">
          <hr className="w-full lg:w-3/4 h-px bg-gray-50" />
        </div>
        <div className="py-2">
          <label
            className="block text-md font-medium text-black-900 mb-1 text-left"
            htmlFor="label"
          >
            Communication Method
          </label>
          <div className="flex">
            <div className="">
              <dl className="divide-y divide-gray-200">
                <div className="py-2  sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <input
                      id="email-checkbox"
                      name="email-checkbox"
                      type="checkbox"
                      className="checkbox-input"
                      checked
                      onChange={() => null}
                      disabled={true}
                    />
                  </dd>
                </div>
              </dl>
            </div>
            {values?.phoneNumber && (
              <div className="">
                <dl className="divide-y divide-gray-200">
                  <div className="py-2  sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">SMS</dt>

                    <dd className="flex justify-between items-center text-sm text-gray-900  sm:col-span-2">
                      <input
                        id="sms-checkbox"
                        name="sms-checkbox"
                        type="checkbox"
                        className="checkbox-input cursor-pointer"
                        checked={sms}
                        onChange={(e) => {
                          setCommunicationSms();
                        }}
                      />
                      <SaveIcon
                        disabled={true}
                        color={smsChange ? "#336666" : "#638B8D"}
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => {
                          updateComminicationMethod();
                        }}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoForm;
