import * as yup from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginValidationSchema = yup.object({
  email: yup.string().when("isEmail", {
    is: "1",
    then: yup
      .string()
      .email("Please enter valid email")
      .required("Email cannot be empty"),
    otherwise: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Please enter valid phone number"),
  }),
});

export const phoneValidationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .min(12, "Please enter valid phone number"),
  // .matches(phoneRegExp, "Please enter valid phone number"),
});

export const emailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .max(64, "Email shouldn't exceeds 64 chars")
    .required("Email cannot be empty"),
});
