import React from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { Formik, Form, Field, FieldArray } from "formik";
import { useSnackbar } from "notistack";
import { useAuth } from "contexts/auth";
import { getProviderAddresses, addProviderAddress, updateProviderAddress, deleteProviderAddress } from 'network/api'
import { v4 as uuidv4 } from 'uuid';
import {
  PlusCircleIcon,
  MinusCircleIcon,
  PencilAltIcon,
  SaveIcon,
  XCircleIcon
} from "@heroicons/react/solid"
import googleAddressApikey from "constants/googleAddressApikey";
import { twMerge } from "tailwind-merge";

const AddressesList = ({ addresses }) => {
  return (
    <ul className="list-disc mt-4">
      {addresses.map((address, index) => (
        <li key={index}
          className=" text-green-900 font-semibold my-2"
        >{address.address_title}</li>
      ))}
    </ul>
  )
};

const ProfileAddresses = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useAuth();

  const [editMode, setEditMode] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getProviderAddresses().then(({ addresses }) => {
      setInitialValues({
        addresses: addresses.map((address) => {
          return {
            ...address,
            indexed_id: uuidv4(),
          }
        }),
        mainAddressIndex: 0,
        addressesEditable: false,
      })
    })
    // setInitialValues({
    //   addresses: state?.user?.addresses.map((address) => {
    //     return {
    //       ...address,
    //       indexed_id: uuidv4(),
    //     }
    //   }),
    //   mainAddressIndex: 0,
    //   addressesEditable: false,
    // })
  }, [state?.user?.addresses])

  const openSnackbar = (data) => {
    enqueueSnackbar(data.message, {
      variant: data.variant,
    });
  };

  const handleAddProviderAddress = async (address) => {
    await addProviderAddress({ address: { ...address } })
      .then(() => {
        dispatch({
          type: "SET_USER",
          payload: {
            user: {
              ...state.user,
              addresses: [...state.user.addresses, { id: uuidv4(), ...address }],
            },
          },

        });
      })
      // .then(({ message }) => openSnackbar({ message, variant: "success" }))
      .catch((error) => openSnackbar({ message: error, variant: "error" }));
  };

  const handleUpdateProviderAddress = async (address) => {
    await updateProviderAddress({ address_id: address.id, address: { ...address } })
      .then(() => {
        dispatch({
          type: "SET_USER",
          payload: {
            user: {
              ...state.user,
              addresses: state.user.addresses.map((a) => {
                if (a.id === address.id) {
                  return address;
                }
                return a;
              }),
            },
          },
        });
      })
      // .then(({ message }) => openSnackbar({ message, variant: "success" }))
      .catch((error) => openSnackbar({ message: error, variant: "error" }));
  };

  const handleDeleteProviderAddress = (address) => {
    deleteProviderAddress({ address_id: address.id })
      // .then(({ message }) => openSnackbar({ message, variant: "success" }))
      .then(() => {
        dispatch({
          type: "SET_USER",
          payload: {
            user: {
              ...state.user,
              addresses: state.user.addresses.filter((a) => a.id !== address.id),
            },
          },
        });
      })
      .catch((error) => openSnackbar({ message: error, variant: "error" }));
  };

  const handleSubmit = async (values) => {
    const addresses = values.addresses;
    setLoading(true);
    for (let i = 0; i < addresses.length; i++) {
      const address = addresses[i];
      if (address.id) {
        await handleUpdateProviderAddress(address);
      } else {
        await handleAddProviderAddress(address);
      }
    }
    setLoading(false);
  };


  const onRemove = (address) => {
    if (address.id) {
      handleDeleteProviderAddress(address);
    }
  };

  return (
    <div className="flex flex-col">
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values).then(() => {
            setSubmitting(false);
            setEditMode(false);
            setSubmit(false);
            resetForm();
          })
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <div className="flex flex-col mt-4">
              <div className="flex flex-row justify-between">
                <h1 className="text-2xl font-semibold">Addresses</h1>
                <div className="flex flex-row">
                  <button
                    type="button"
                    className="flex flex-row items-center justify-center px-2 py-1 mr-2 text-sm font-medium text-white bg-yellow-800 rounded-md"
                    onClick={() => {
                      setSubmit(false);
                      setEditMode(!editMode);
                      setFieldValue("addressesEditable", !values.addressesEditable);
                      setFieldValue("addresses", state.user.addresses);
                    }}
                  >
                    {editMode ? (
                      <XCircleIcon className="w-5 h-5 mr-1" />
                    ) : (
                      <PencilAltIcon className="w-5 h-5 mr-1" />
                    )}
                    {editMode ? "Cancel" : "Edit"}
                  </button>
                  {loading ? (
                    <button type="button" className="flex flex-row items-center justify-center px-2 py-1 mr-2 text-sm font-medium text-white bg-green-500 rounded-md">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2" />
                      Saving
                    </button>
                  ) :
                    <button
                      type={submit ? "submit" : "button"}
                      className={twMerge("flex flex-row items-center justify-center px-2 py-1 text-sm font-medium text-white bg-green-800 rounded-md",
                        isSubmitting || !editMode && "opacity-50 cursor-not-allowed")}
                      disabled={isSubmitting || !editMode}
                      onClick={() => setSubmit(true)}
                    >
                      <SaveIcon className="w-5 h-5 mr-1" />
                      Save
                    </button>
                  }
                </div>
              </div>
              <hr className="my-2" />
              {editMode ? (
                <div className="flex flex-col mt-4">
                  <FieldArray name="addresses">
                    {({ push, remove }) => (
                      <>
                        {values.addresses.map((dd, index) => (
                          <div key={dd.indexed_id} className="flex flex-row justify-between my-4">
                            <div className="flex flex-col w-3/4">
                              <Field
                                name={`addresses.${index}.address_title`}
                                render={({ field }) => (
                                  <ReactGoogleAutocomplete
                                    {...field}
                                    defaultValue={dd.address_title}
                                    apiKey={googleAddressApikey}
                                    options={{
                                      types: ["address"],
                                    }}
                                    className="w-full px-2 py-1 mt-1 text-sm border rounded-md"
                                    onPlaceSelected={(place) => {
                                      const address_obj =
                                        place.address_components.reduce(
                                          (
                                            seed,
                                            { long_name, types }
                                          ) => {
                                            types.forEach((t) => {
                                              seed[t] = long_name;
                                            });
                                            return seed;
                                          },
                                          {}
                                        );
                                      const address = {
                                        is_main: values.mainAddressIndex === index,
                                        address_title: place.formatted_address,
                                        country: address_obj.country,
                                        city: address_obj.locality || address_obj.sublocality,
                                        postal_code: address_obj.postal_code || 0,
                                        label: "home",
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng(),
                                        place_id: place.place_id,
                                        address_details: "",
                                      }
                                      setFieldValue(`addresses.[${index}]`, { ...dd, ...address });
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="flex flex-row">
                              <button
                                type="button"
                                className="flex flex-row items-center justify-center  px-2 py-1 mr-2 text-sm font-medium text-white bg-red-500 rounded-md"
                                onClick={() => {
                                  remove(index);
                                  onRemove(dd);
                                }}
                                disabled={!values.addressesEditable}
                              >
                                <MinusCircleIcon className="w-5 h-5 mr-1" />
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="flex flex-row">
                          <button
                            type="button"
                            className="flex flex-row items-center justify-center px-2 py-1 mr-2 text-sm font-medium text-white bg-blue-500 rounded-md"
                            onClick={() => {
                              push({
                                indexed_id: uuidv4(),
                              });
                            }}
                            disabled={!values.addressesEditable}
                          >
                            <PlusCircleIcon className="w-5 h-5 mr-1" />
                            Add
                          </button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              ) : (
                <AddressesList addresses={state.user.addresses} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileAddresses;