import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

export default function Page({ children, classNames, bg }) {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={twMerge(
        "bg-cover bg-repeat bg-center w-screen relative h-screen",
        bg,
        classNames
      )}
    >
      {children}
    </motion.main>
  );
}
