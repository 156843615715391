import ProjectBids from "components/serviceSeeker/dashboard/ProjectBids";
import ProjectDetailsComponent from "components/serviceSeeker/dashboard/ProjectDetails";

export const SeekerDashboardTabs = {
    "Project Details": [
        {
            path: "",
            component: (
                <ProjectDetailsComponent
                    key={'projectDetails'}
                />
            ),
        },
    ],
    "Bids": [
        {
            path: "bids",
            component: (
                <ProjectBids
                    key={'bids'}
                />
            ),
        },
    ]
}