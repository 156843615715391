import moment from "moment";
import Swal from 'sweetalert2'
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useEffect, useState } from 'react';
import { DatePicker, Tooltip } from "antd";
import { BsSave2Fill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Form, Formik, Field as Fieldd } from "formik";
import withReactContent from "sweetalert2-react-content";
import Spinner from "components/Spinner";
import SubmitButton from "components/SubmitButton";
import { fDateTime } from "utils/formatTime";
import { CalendarIcon } from "@heroicons/react/outline";
import roofingIcon from 'assets/images/icons/roofing.svg';
import projectIcon from 'assets/images/icons/project.svg';
import { useBidData } from "contexts/bid";
import { seekerUpdateBidValidityDays, getThreadById, sendMessage, cancelSelectedBid, acceptBid } from 'network/api'

const mySwal = withReactContent(Swal);

const MIN_DURATION = 0;

const Card = ({ children, classNames }) => (
    <div
        className={
            "border-t-2 border-b-2 border-2 rounded border-green-900 opacity-100 p-4  overflow-y-auto w-full lg:w-full bg-semiWhite " +
            classNames
        }
    >
        {children}
    </div>
);

const CardHeader = ({ icon, label }) => (
    <div className="flex items-center justify-center lg:justify-start mb-5">
        <dt className="inline text-sm ">{icon}</dt>
        <dd className="inline ml-2 text-lg font-bold text-black-900 font-serif">
            {label}
        </dd>
    </div>
);

const Field = ({ label, value }) => (
    value ?
        <div className="mt-2">
            <dt className="inline text-sm font-bold text-black-900">{label}</dt>
            <dd className="inline  ml-2 text-sm text-gray-900">{value}</dd>
        </div>
        : null
);

const Review = ({ setThreadCreated, threadCreated, bidData1, updateBids, showHighlightedMsg }) => {
    const history = useNavigate();
    const { material_info } = useBidData();
    const { enqueueSnackbar } = useSnackbar();

    const [bidData, setBidData] = useState(bidData1)
    const [loadingExtendBid, setLoadingExtendBid] = useState(false);
    const [bidDate, setBidDate] = useState(bidData.details.bid_valid_until)

    useEffect(() => {
        console.log('bidData', bidData)
        if (bidData.material.length > 0) {
            setBidData({
                ...bidData,
                material: bidData.material.map((m) => {
                    const material = material_info.find((material) => material.subCategories.find((sub) => sub.id === m.material_subcategory_id))
                    m.name = material.name;
                    m.subcategory = material.subCategories.find((sub) => sub.id === m.material_subcategory_id).name;
                    return m;
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [material_info])

    /**
     * const extendBidValidDays = async (bid_id, bid_valid_days) => {
            const added_days = getNoDays(new Date(bid_valid_days)) - bidData.bid_valid_days
            const response = await providerUpdateBidValidityDays(bid_id, added_days)
            if (response.status) {
                setBidData((prev) => ({ ...prev, bid_valid_days: getNoDays(new Date(bid_valid_days)), bid_valid_until: bid_valid_days }))
                enqueueSnackbar('Bid Date Extended', { variant: 'success' })
            }
            else
                enqueueSnackbar('Extending Bid date failed!', { variant: 'error' })
            setEnableEditExtend(false)
        };
     */

    const apiCall = async (dateString) => {
        setLoadingExtendBid(true);
        const added_days = days(new Date(dateString), new Date(bidData.details.bid_valid_until))
        const res = await seekerUpdateBidValidityDays(bidData.details.id, added_days, bidData.details.project_id);
        if (res.status) {
            enqueueSnackbar(res.msg, { variant: "success" });
            await updateBids();
        } else {
            enqueueSnackbar(res.error, { variant: "error" });
        }
        setLoadingExtendBid(false);
        // const bidDays = days(new Date(dateString), new Date())
        // const res = await seekerUpdateBidValidityDays(bidData.details.id, bidDays, bidData.details.project_id);
        // if (res.status) {
        //     enqueueSnackbar(res.msg, { variant: "success" });
        //     await updateBids();
        // } else {
        //     enqueueSnackbar(res.error, { variant: "error" });
        // }
        // setLoadingExtendBid(false);
    }

    const disabledDatesForStartDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    const disabledDatesForEndDate = (current) => {
        if (bidData.details.bid_valid_until) {
            return (
                current &&
                current
                    .startOf('day')
                    .isBefore(moment(bidData.details.bid_valid_until).add(MIN_DURATION, 'days'))
            );
        }
        return disabledDatesForStartDate(current);
    };

    const days = (date_1, date_2) => {
        // let difference = date_1.getTime() - date_2.getTime();
        // let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        // return TotalDays;
        date_2 = date_2 || new Date();

        // Parse the date string into a Date object
        if (typeof date_1 === 'string') {
            date_1 = new Date(date_1.replace(/-/g, '/'));
        }

        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

        if (TotalDays <= 0) return 'Bid Expired';
        else return TotalDays;
        // if (TotalDays < 0) return 'Bid Expired'
        // else return `${TotalDays} Days`
    }

    const handleRejectBid = async () => {
        mySwal.fire({
            icon: 'warning',
            title: 'Are you sure you want to reject this Bid?',
            showCancelButton: true,
            confirmButtonText: 'Yes, Reject Bid',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            background: "#fffced",
            html: (
                <p className="text-sm text-red-600 font-bold">
                    Rejecting a bid cannot be undone. This provider will not be able to submit a new bid for your project.
                </p>
            ),
        }).then(async (result) => {
            if (result.isConfirmed) {
                localStorage.setItem('selectedBid', "null")
                Swal.showLoading();
                const response = await cancelSelectedBid(bidData.details.id);
                await updateBids();
                if (response) {
                    Swal.close();
                    Swal.fire('Bid Rejected!', '', 'success')
                }
            }
        });
    };

    return (
        <div className="flex flex-column justify-center lg:space-x-6 mb-4 lg:max-h-80 flex-col lg:flex-col space-y-6 lg:space-y-6 overflow-y-auto min-w-full" >
            <Card classNames={" overflow-y-auto  min-w-full px-10 max-h-screen "}>
                <div >
                    <h2 className='text-3xl text-center'>Bid Details</h2>
                    <div className="w-1/2 mt-2 mb-8 mx-auto">
                        {threadCreated ?
                            <button
                                className="btn-primary mt-2 mb-2 w-45"
                                type="button"
                                onClick={() => history('/inbox')}
                            >
                                Inbox
                            </button>
                            :
                            <button
                                className="btn-primary ml-1 w-45"
                                type="button"
                                onClick={() => {
                                    mySwal.fire({
                                        customClass: "mySwal",
                                        showConfirmButton: false,
                                        html: (
                                            <div>
                                                <Formik
                                                    initialValues={{
                                                        message: "",
                                                    }}
                                                    onSubmit={async (values) => {
                                                        const { message } = values;
                                                        mySwal.close();
                                                        mySwal.showLoading()
                                                        sendMessage("subject", message, bidData.service_provider.user_id).then(() => {
                                                            setThreadCreated(true);
                                                            mySwal.close();
                                                        })
                                                    }}
                                                >
                                                    {({ errors, isValid, dirty }) => {
                                                        return (
                                                            <Form>
                                                                <div className="py-4 sm:py-5">
                                                                    <label
                                                                        className="block text-md font-medium text-black-900 mb-1 text-left"
                                                                        htmlFor="label"
                                                                    >
                                                                        Message
                                                                    </label>
                                                                    <Fieldd
                                                                        type="text"
                                                                        placeholder="Message"
                                                                        className={classNames("text-input", {
                                                                            "border-red-600 focus:ring-red-600  focus:border-red-600":
                                                                                errors.message,
                                                                        })}
                                                                        name="message"
                                                                    />
                                                                </div>
                                                                <div className="py-4 sm:py-5">
                                                                    <div className="flex justify-between items-center">
                                                                        <div className="flex-auto w-1/2 mr-2">
                                                                            <button
                                                                                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-full shadow-lg justify-center py-2 px-4 border"
                                                                                onClick={(e) => {
                                                                                    mySwal.close();
                                                                                }}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                        <div className="flex-auto w-1/2 mr-2">
                                                                            <SubmitButton
                                                                                disabled={!(isValid && dirty)}
                                                                                // loading={loading}
                                                                                buttonText="Send"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        );
                                                    }}
                                                </Formik>
                                            </div>
                                        )
                                    })
                                }}
                            >
                                Message Provider
                            </button>
                        }
                    </div>

                    {showHighlightedMsg && <div className='flex gap-2 items-center font-semibold bg-red-200 self-center rounded-md p-2 mx-auto' style={{ width: "fit-content" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <p>
                            The project start is awaiting a confirmation from the service provider.
                        </p>
                    </div>}
                    {/* state if provider confirmed the bid */}
                    {bidData.details.status_id === 6 && <div className='flex gap-2 items-center font-semibold bg-green-200 self-center rounded-md p-2 mx-auto' style={{ width: "fit-content" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                        <p>
                            The project start is confirmed by the service provider.
                        </p>
                    </div>}
                    {/* state for seeker now project in progress */}
                    {bidData.details.status_id === 9 && <div className='flex gap-2 items-center font-semibold bg-green-200 self-center rounded-md p-2 mx-auto' style={{ width: "fit-content" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                        <p>
                            The project is in progress.
                        </p>
                    </div>}

                    {/* create confirmation box if provider adjusted a bid with status 5 */}
                    {bidData.details.status_id === 5 && (
                        // generate a confirmation box with accept and reject buttons 
                        <div className="flex flex-col bg-yellow-200 mb-8 rounded-md">
                            <div className='flex gap-2 items-center font-semibold  self-center rounded-md p-2 mx-auto' style={{ width: "fit-content" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                                <p>
                                    The project is adjusted by the service provider.
                                </p>
                            </div>
                            <div className='flex gap-2 items-center font-semibold  self-center rounded-md p-2 mx-auto' style={{ width: "fit-content" }}>
                                <p>
                                    Do you want to accept the adjusted bid?
                                </p>
                                <div className='flex gap-2 items-center font-semibold  self-center rounded-md p-2 mx-auto' style={{ width: "fit-content" }}>
                                    <button
                                        className='btn-primary'
                                        onClick={() => {
                                            mySwal.fire({
                                                icon: 'question',
                                                title: 'Are you sure you want to accept this Bid?',
                                                showCancelButton: true,
                                                confirmButtonText: 'Yes, Accept Bid',
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                background: "#fffced",
                                                html: (
                                                    <p className="text-sm text-red-600 font-bold">
                                                        If seeker accept a bid, all unexpired bids are hidden
                                                    </p>
                                                ),
                                            }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                    Swal.showLoading();
                                                    const response = await acceptBid(bidData.details.id);
                                                    await updateBids();
                                                    if (response) {
                                                        Swal.close();
                                                        Swal.fire('Bid Accepted!', '', 'success')
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        Accept
                                    </button>
                                    <button
                                        className='btn-primary w-auto bg-red-600 hover:bg-red-900 border-none'
                                        onClick={() => handleRejectBid()}
                                    >
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <CardHeader
                        icon={<img src={projectIcon} height="50" alt="bla" />}
                        label=" Materials"
                    />
                    <div className="flex items-center gap-10">
                        {bidData?.material.length > 0 && bidData?.material.map((item, idx) => (
                            <dl key={idx} className="h-full pr-8 pt-4 border-r-2 border-green-900 lastChild">
                                <Field label="Name:" value={item.subcategory} />
                                <Field label="Unit Price:" value={`${item.unit_price}$`} />
                                <Field label="Quantity:" value={item.quantity} />
                                {
                                    item.description && (
                                        <Field label="Description:" value={item.description} />
                                    )
                                }
                                {
                                    item.reason && (
                                        <Field label="Reason:" value={item.reason} />
                                    )
                                }
                            </dl>
                        ))}
                    </div>
                </div>
                <div className="mt-10">
                    <Field
                        label="Material Cost:"
                        value={`${bidData.details.material_total_cost}$`}
                    />
                </div>
                <hr className="mt-5 mb-5" />
                <CardHeader
                    icon={<img src={roofingIcon} width="50" height="50" alt="bla" />}
                    label=" Service"
                />
                <dl className="mb-8">
                    <Field label="Service Fees:" value={`${bidData.details.service_fees}$`} />
                    <Field
                        value={bidData.details.service_fees_description}
                        label={"Description:"}
                    />
                </dl>
                <hr className="mt-5 mb-5" />
                <CardHeader
                    icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#497578" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>}
                    label=" Project Time Frame"
                />
                <dl className="mb-8">
                    <Field label="Bid Valid Until:" value={fDateTime(bidData.details.bid_valid_until)} />
                    <Field label="Start Date:" value={fDateTime(bidData.details.start_date)} />
                    <Field
                        value={fDateTime(bidData.details.completion_date)}
                        label={"Completion Date"}
                    />
                    <Field
                        value={bidData.details.dates_description}
                        label={"Description:"}
                    />
                </dl>
                <hr className='my-5' />
                {(bidData.details.status_id === 1 || bidData.details.status_id === 2) &&
                    <div className="mb-4">
                        <p className="block text-md font-medium text-black-900 mb-1">
                            Extend Bid Validity
                        </p>
                        <div className='flex flex-row-reverse items-center gap-2 w-1/2'>
                            <Tooltip placement="topLeft" title={"Save"}>
                                <button onClick={() => apiCall(bidDate)} disabled={
                                    bidDate === bidData.details.bid_valid_until
                                }>
                                    {loadingExtendBid
                                        ? (<div className="flex justify-center items-center">
                                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-green-900"></div>
                                        </div>)
                                        : <BsSave2Fill className="h-full w-8 text-green-900 hover:cursor-pointer" />
                                    }
                                </button>
                            </Tooltip>
                            <DatePicker
                                // value={bidData.details.bid_valid_until ? moment(bidData.details.bid_valid_until) : ''}
                                value={moment(bidDate)}
                                disabledDate={disabledDatesForEndDate}
                                onChange={(date, dateString) => {
                                    setBidDate(dateString)
                                    // apiCall(dateString)
                                }}
                                clearIcon={null}
                                suffixIcon={
                                    <CalendarIcon
                                        className="block h-6 w-6 text-green-800"
                                        aria-hidden="true"
                                    />
                                }
                            />
                        </div>
                    </div>
                }
                {bidData.details.status_id === 3 &&
                    <div className='my-8 flex justify-center items-center gap-4 w-full'>
                        <button
                            className='btn-primary w-auto bg-red-600 hover:bg-red-900 border-none'
                            onClick={() => handleRejectBid()}
                        >
                            Reject Bid
                        </button>
                    </div>
                }
            </Card>
        </div>
    );
};

const BidDetails = ({ bidData, updateBids, showHighlightedMsg }) => {

    const [threadCreated, setThreadCreated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getThreadById(bidData.service_provider.user_id,bidData.details.project_id).then(res => {
            if (res.threads.length > 0) {
                setThreadCreated(true);
            }
            else {
                setThreadCreated(false);
            }
            setLoading(false);
        })
    }, [bidData.service_provider.user_id])

    return (
        <div>
            {!loading ?
                <Review setThreadCreated={setThreadCreated} threadCreated={threadCreated} bidData1={bidData} updateBids={updateBids} showHighlightedMsg={showHighlightedMsg} />
                :
                <Spinner />
            }
        </div>
    );
};

export default BidDetails;