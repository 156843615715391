import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Link as ScrollLink, animateScroll } from "react-scroll";

import { Disclosure } from "@headlessui/react";

import { AiOutlineMenu } from "react-icons/ai";
import { XIcon } from "@heroicons/react/outline";

import { useAuth } from "contexts/auth";

import Logo from "assets/images/logos/logo.svg";
import Avatar from "assets/images/backgrounds/avatar.png";

const navigation = [
  // {
  //   exact: true,
  //   name: "Login",
  //   activeClassName: "nav-link-active",
  //   className: "nav-link",
  //   to: "/login",
  // },
  {
    exact: true,
    name: "Create an Account",
    activeClassName: "nav-link-active",
    className: "nav-link",
    to: "/register/seeker",
  },
  {
    exact: true,
    name: "Apply as Service Provider",
    activeClassName: "nav-link-active",
    className: "nav-link",
    to: "/register/provider",
  },
];

const sections = [
  {
    name: "Home",
    to: "hero",
    activeClassName: "nav-link-active",
    className: "nav-link",
  },
  {
    name: "About Us",
    to: "about",
    activeClassName: "nav-link-active",
    className: "nav-link",
  },
  {
    name: "Services",
    to: "services",
    activeClassName: "nav-link-active",
    className: "nav-link",
  },
];

const mobile_navigation = [...sections, ...navigation];

const HomeNavbar = () => {
  const location = useLocation();
  const { state } = useAuth();

  const navigate = useNavigate();

  const handleClick = (to) => {
    if (
      (location.pathname === "/" || location.pathname === "/home") &&
      document.querySelector(to)
    ) {
      animateScroll.scrollTo(document.querySelector(to).offsetTop - 70);
    } else {
      if (location.pathname === "/home") {
        navigate("/home", { state: { scrollTo: "#" + to } });
        return;
      }
      navigate("/", { state: { scrollTo: to } });
    }
  };

  // hide sections if pathname is not '/'
  const [isHome, setIsHome] = useState(
    location.pathname === "/" || location.pathname === "/home"
  );

  useEffect(() => {
    setIsHome(location.pathname === "/" || location.pathname === "/home");
  }, [location.pathname]);

  const mappingRoutes = {
    "/login": "Login",
    "/register/seeker": "Create an Account",
    "/register/provider": "Apply as Service Provider",
  };

  return (
    <Disclosure
      as="nav"
      className="border-b shadow-xl	bg-semiWhite opacity-100 sticky w-full top-0 left-0 z-1000"
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex-shrink-0 flex items-center">
                <NavLink to="/home">
                  <img className=" h-8 w-auto" src={Logo} alt="Workflow" />
                </NavLink>
                <div className="flex">
                  <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                    {sections.map((section, idx) => {
                      if (idx > 0 && !isHome) return null;
                      return (
                        <ScrollLink
                          key={section.name}
                          onClick={() => handleClick(section.to)}
                          to={section.to}
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          activeClass="border-b-2 border-green-900"
                          className="text-gray-500 hover:text-gray-900"
                        >
                          {section.name}
                        </ScrollLink>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="lg:hidden flex items-center">
                <div className="font-bold">
                  {mappingRoutes[location.pathname]}
                </div>
              </div>
              <div className="-ml-2 mr-2 flex items-center lg:hidden">
                {/* Mobile menu button */}
                {location.pathname !== "/login" && (
                  <NavLink
                    to="/login"
                    className="bg-green-900 text-white hover:text-white hover:bg-green-800 px-4 py-2 rounded-md"
                  >
                    Login
                  </NavLink>
                )}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <AiOutlineMenu className="h-6 w-6" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                {state?.token && (
                  <div className="w-8">
                    <NavLink to={"/"}>
                      <img src={Avatar} alt="" className="w-full rounded-2xl" />
                    </NavLink>
                  </div>
                )}
                {!state?.token &&
                  navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      className={({ isActive }) =>
                        isActive
                          ? item.activeClassName + " font-bold"
                          : item.className
                      }
                      to={item.to}
                    >
                      {item.name}
                    </NavLink>
                  ))}
                {!state?.token && (
                  <NavLink
                    to="/login"
                    className="bg-green-900 text-white hover:text-white hover:bg-green-800 px-4 py-2 rounded-md"
                  >
                    Login
                  </NavLink>
                )}
              </div>
            </div>
          </div>
          <Disclosure.Panel className="rounded-b-lg shadow-custom absolute right-0 w-full origin-top-right bg-semiWhite z-1000">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center flex flex-col items-center">
              {!state?.token &&
                mobile_navigation.map((item, idx) => {
                  if (idx < 3) {
                    if (idx > 0 && !isHome) return null;
                    else
                      return (
                        <ScrollLink
                          key={item.name}
                          onClick={() => handleClick(item.to)}
                          to={item.to}
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          activeClass="border-b-2 border-green-900"
                          className="text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </ScrollLink>
                      );
                  }
                  return (
                    <NavLink
                      key={item.name}
                      className={({ isActive }) =>
                        isActive ? "hidden" : item.className
                      }
                      to={item.to}
                    >
                      {item.name}
                    </NavLink>
                  );
                })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default HomeNavbar;
