
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import { INITIAL_EVENTS, formatEvents } from "../../../utils/events-utils";
import {buildDateForNewProject} from "../../../utils/RequestBuilder"
import { getAvailableTime } from "../../../network/api";
const NewProjectAvailableTime = ({ data, onChange, onNext, onPrev }) => {
  const [date, setDate] = useState("");
  const [events, setEvents] = useState(INITIAL_EVENTS);
  let serviceId = data.service.id;

  const pickDate = (eventInfo) => {
    const date = (buildDateForNewProject(eventInfo.event.start));
    setDate(date);
    onChange({ appointment_datetime: date });
  };
  useEffect(() => {
    getAvailableTime(serviceId).then((res) => {
      setEvents(formatEvents(res.slots));
    });
  }, [serviceId]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: 'easeInOut',
      }}
      className={`mx-auto md:px-8  md:pb-10`}
    >
      {/* <div className="flex justify-center h-screen"> */}
      <div className="mt-6">
        <div className="mt-8">
          <h2 className="block text-black-900 mb-1">
            {/* text-sm font-medium */}
            Your Appointment Time is:{' '}
            <h3>
              {data.appointment_datetime ? data.appointment_datetime : date}
            </h3>
          </h2>
        </div>
        <FullCalendar
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'timeGridWeek,timeGridDay',
          }}
          eventClick={pickDate}
          weekends={true}
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="timeGridWeek"
          events={events}
          height="65vh"
        />
        <div className="flex justify-center items-center pt-8 max-w-lg  m-auto">
          <button className="btn-outline mr-1" type="button" onClick={onPrev}>
            Back
          </button>
          <button
            disabled={!data.appointment_datetime}
            className="btn-primary ml-1"
            type="button"
            onClick={onNext}
          >
            Next
          </button>
        </div>
      </div>
      {/* </div> */}
    </motion.div>
  );
};


export default NewProjectAvailableTime;