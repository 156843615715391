import classNames from "classnames";
import { motion } from "framer-motion";
// forms
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
// hooks
import { useBidData } from "contexts/bid";

const validationSchema = Yup.object({
  service_fees: Yup.number()
    .moreThan(0, "Enter valid fees")
    .required("Fees is required!"),
  description: Yup.string().required("Please provide a description.")
});

const Card = ({ children, classNames }) => (
  <div
    className={
      "border-t-2 border-green-900 opacity-100 py-6 pt-12 overflow-y-auto w-full bg-semiWhite " +
      classNames
    }
  >
    {children}
  </div>
);

const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <textarea
        className={classNames("text-input", {
          "border-red-600 focus:ring-red-600 focus:border-red-600": meta.error,
        })}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="mt-2 text-sm text-red-600 text-left">
          <ErrorMessage name="description" />
        </div>
      ) : null}
    </>
  );
};

const ServiceFees = ({ goNextPage, goPrevPage, onSubmit }) => {
  const {
    bidData
  } = useBidData();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        default: { duration: 0.6 },
        ease: "easeInOut",
      }}
      className={`mx-auto w-3/4`}
    >
      <div className="flex justify-center max-w-7xl m-auto">
        <div className="lg:m-auto justify-center w-full max-w-full">
          <h2 className="text-center pb-2 mt-0">Bid Information</h2>
          <div className="flex justify-center lg:space-x-6  mb-4 lg:max-h-80 flex-col lg:flex-row space-y-6 lg:space-y-0 ">
            <Formik
              initialValues={{
                service_fees: bidData.service_fees || 0,
                description: "" || bidData?.service_fees_description,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmit({
                  "service_fees": values.service_fees,
                  "service_fees_description": values.description
                })
                goNextPage()
              }}
            >
              {({ values, errors, setFieldValue, isValid, dirty }) => {
                return (
                  <Form className="flex flex-col justify-center items-center content-center w-full">
                    <div className="py-4 pt-2">
                      <label
                        className="block text-md font-medium text-black-900 mb-1 text-center"
                        htmlFor="label"
                      >
                        Service Information
                      </label>
                    </div>
                    <Card>
                      <div className="py-2 sm:py-3 w-full">
                        <label
                          className="block text-md font-medium text-black-900 mb-1 text-left required-field"
                          htmlFor="label"
                        >
                          Total Service Fees
                        </label>
                        <Field
                          type="number"
                          min="0"
                          className={classNames("text-input", {
                            "border-red-600 focus:ring-red-600 focus:border-red-600":
                              errors.service_fees,
                          })}
                          name="service_fees"
                          placeholder="Enter Service Fees"
                        />
                        <div className="flex justify-between items-center">
                          <div className="flex-auto w-2/5 mr-2">
                            {errors.service_fees && (
                              <div className="mt-2 text-sm text-red-600 text-left">
                                <ErrorMessage name="service_fees" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="py-2 sm:py-3 w-full">
                        <label
                          className="block text-md font-medium text-black-900 mb-1 text-left required-field"
                          htmlFor="label"
                        >
                          Description
                        </label>
                        <MyTextArea
                          label="Description"
                          name="description"
                          rows="6"
                          placeholder="Enter the description here..."
                        />
                      </div>
                    </Card>
                    <div className="flex flex-row w-1/2 justify-center self-center mt-10">
                      <button
                        type="button"
                        onClick={goPrevPage}
                        className="btn-outline mr-1"
                      >
                        Back
                      </button>
                      <button type="submit" disabled={!(isValid)} className="btn-primary ml-1 self-center">
                        Next
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ServiceFees;
